import LoadingSpinner from 'components/LoadingSpinner';
import React, { FC, ReactNode, useState } from 'react';
import { Modal, Button, InputGroup, Form } from 'react-bootstrap';
import { salvaListaRicerca } from 'services/lista_ricerche_service';
import { salvaRicerca } from 'services/ricercaUnicaService';

type Props = {
    show: boolean;
    title: ReactNode;
    body: ReactNode;
    buttonPositive: ReactNode;
    buttonNegative: ReactNode;
    disableButton?: boolean;
    onPositive?: () => void;
    onNegative: () => void;
};




const ConfirmationSalvaRicerca: FC<Props> = ({
    show,
    title,
    body,
    buttonPositive,
    buttonNegative,
    onPositive,
    onNegative,
    disableButton,
}) => {
    const [nomeLista, setNomeLista] = useState(undefined);
    const [salvataggio, setSalvataggio] = useState(false);
    const [errore, setErrore] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleSalva = (body) => {
       
        let preventivo = {
            "preventiva": true
        }

        let ricerca = {
            "url_ricerca" : body.url_ricerca,
            "nome_lista": nomeLista,
            "preventivo": preventivo
        }
        setIsLoading(true)
        salvaListaRicerca(ricerca).then((response)=> {
            if(response.data.status === 500){
                setErrore(true)
                setIsLoading(false)
            }else if(response.data.status === 200){
                
                let datiricerca = {
                    "numero_anagrafiche": body.numero_anagrafiche,
                    "call_id" : response.data.id_lista,
                    "nome_lista": response.data.nome_lista,
                    "url_ricerca": response.data.url_ricerca,
                    "dati_ricera": body.dati_ricera
        
                }
                
                salvaRicerca(datiricerca).then((response)=> {
                    if(response.data.status === 500){
                        setErrore(true)
                        setIsLoading(false)
                    }else if(response.data.status === 200){
                        setSalvataggio(true)
                        setIsLoading(false)
                    }    
            })
        }else{
            setIsLoading(false)
        }

            
    })


    }

    if(body != null){
    return (
        <>
        {isLoading ? <LoadingSpinner /> : isLoading}
        {!salvataggio && !errore &&
            <Modal size="lg" show={show} onHide={() => onNegative()} dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Inserisci un nome o un codice per identificare questa ricerca nella sezione <strong>"Le mie Ricerche"</strong></p>
                    <InputGroup className='padding-bottom20'>
                        <Form.Control
                            placeholder="Nome Ricerca"
                            aria-label="Search"
                            aria-describedby="basic-search"
                            onChange={(e) => setNomeLista(e.target.value)}
                            className=""
                        />
                    </InputGroup>

                    {/* <InputGroup>
                        <Button disabled={nomeLista == null || nomeLista.length <= 3} variant="success" className="component-maxwidth" id="button-search" onClick={() => handleSalva(body)}>
                            Salva
                        </Button>
                    </InputGroup> */}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                        {buttonNegative}
                    </Button>
                    <Button disabled={nomeLista == null || nomeLista.length <= 3} variant="success" className="" id="button-search" onClick={() => handleSalva(body)}>
                            Salva Ricerca
                    </Button>
                    {/* <Button onClick={() => onPositive()} variant="primary" disabled={disableButton}>
                        {buttonPositive}
                    </Button> */}
                </Modal.Footer>
            </Modal>
        }
        {salvataggio && !errore &&
            <Modal size="lg" show={show} onHide={() => onNegative()} dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <p className='icon-confirm'><i className="fa fa-check-circle"></i></p>
                        </div>
                        <div className='col-sm-12'>
                            <p className='text-alert-center font-size-1-5'>Ricerca salvata con successo</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        }
        {!salvataggio && errore &&
            <Modal size="lg" show={show} onHide={() => onNegative()} dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                            <div className='col-sm-12'>
                                <p className='icon-alert'><i className="fa fa-exclamation-circle"></i></p>
                            </div>
                            <div className='col-sm-12'>
                                <p className='text-alert-center-bold font-size-1-5'>Abbiamo rilevato un errore</p>
                            </div>
                        </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        }
        </>
    );
    }else{
        return (
            <Modal size="lg" show={show} onHide={() => onNegative()} dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                            <div className='col-sm-12'>
                                <p className='icon-alert'><i className="fa fa-exclamation-circle"></i></p>
                            </div>
                            <div className='col-sm-12'>
                                <p className='text-alert-center-bold font-size-1-5'>Abbiamo rilevato un errore n</p>
                            </div>
                        </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                        {buttonNegative}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

};

export default ConfirmationSalvaRicerca;

