import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Card, InputGroup, Button, Row, DropdownButton, Dropdown, Tabs, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { activateMenu, setAreaGeograficaList, setAreaGeograficaMessage, setCodici_AtecoList, setCodici_AtecoMessage, setElenco_Imprese_EstesoMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getTrovaImpresa, getTrovaImpresaPec } from "services/ricerca_impresa_service";
import Layout from "template";
import ConfirmationModal from "template/ConfirmationModal";
import DetailModal from "template/DetailModal";
import { DashboardCard } from "./DasboardCard";
import LoadingSpinner from "../LoadingSpinner";
import Form from 'react-bootstrap/Form';
import { isTemplateExpression } from "typescript";
import ConfirmationModalNoAcquisto from "template/ConfirmationModalNoAcquisto";
import ModalErrorGenerico from "template/ModalErrorGenerico";
import { sassFalse } from "sass";
import DetailImpresaPuntualeModal from "template/DetailImpresaPuntualeModal";
import ConfirmationVisura from "template/ConfirmationVisura";
import Select from 'react-select';
import ConfirmationDatiObbligatoriElenco from "template/ConfirmationDatiObbligatoriElenco";
import { getRicercaElencoImpresaPreventivo, getRichiestaElencoImpresa } from "services/richiesta_elenco_impresa_esteso";
import ConfirmationPreventivoElenco from "template/ConfirmationPreventivoElenco";
import ConfirmationDifferita from "template/ConfirmationDifferita";
import { getCodici_Ateco } from "services/codici_atecoService";
import { getComuni_Catastali } from "services/comuni_catastaliService";
import { getWs_Provincieinfocamere } from "services/ws_provincieinfocamereService";

import { Link, useLocation } from 'react-router-dom';
import { controlloDisponibilita, getDatiAreaGeografica, getDatiNaturaGiuridica, getDatiateco, getDettaglioAzienda, getPosizioniSuccessive, getRicercaEstesa, ricercaDiretta, salvaRicerca } from "services/ricercaUnicaService";
import { ReactTags, TagSuggestion  } from "react-tag-autocomplete";
import ConfirmationBilancio from "template/ConfirmationBilancio";
import { getUltimoBilancio } from "services/richiesta_bilancio";
import ModalConfirmGenerico from "template/ModalConfirmGenerico";
import { setRicercaUnicaDataList } from "redux/slices/ricerca_unica";
import ConfirmationSalvaRicerca from "template/ConfirmationSalvaRicerca";
import ConfirmationControlloDisponibilita from "template/ConfirmationControlloDisponibilita";
import { getVisuraCamerale, getVisuraCameraleInfo } from "services/richiesta_visura";



type Props = {
    // name: string;
    // path: string;
    numero_imprese: Number
    getData: (page, pageSize, searchKey, datiricerca) => void
    //getDataProvincie:(page, pageSize) => void
    getDatiVisura: (cciaa, nrea, getDataBilancio, idprodotto, tipovisura) => void
    getDataComuni:(page, pageSize) => void
    //getDataCodiceAteco: (page, pageSize) => void

};
export const RicercaUnica: React.FC<Props> = ({getData, numero_imprese, getDatiVisura,getDataComuni}) => {
    const { parametro } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [showDetail, setShowDetail] = useState(false);
    const [showNoAcquisto, setNoAcquisto] = useState(false);
    const [showErrorGenerico, setErroreGenerico] = useState(false);
    const [search, setSearch] = useState('');
    const [rowData, setRowData] = useState(undefined);
    const [rowDataVisura, setRowDataVisura] = useState(undefined);
    
    const rDataProvincia = useSelector((state: RootState) => state.ws_provincieinfocamere);
    const rDataComune = useSelector((state: RootState) => state.comuni_catastali);

    const ricercaUnica = useSelector((state: RootState)=> state.ricerca_unica)
    //const rDataAteco = useSelector((state: RootState) => state.codici_ateco);

    const [rDataAteco, setrDataAteco] = useState(undefined);

    //let responseData = new Array();
    const rData = useSelector((state: RootState) => state.trova_impresa);
    const [isLoading, setIsLoading] = useState(false);
    const [provincia, setProvincia] = useState("");

    const [disableRadioDen, setdisableRadioDen] = useState(false);
    const [disableRadioPiva, setdisableRadioPiva] = useState(true);
    const [disableRadioCf, setdisableRadioCf] = useState(true);
    const [checkRadioDen, setcheckRadioDen] = useState(true);
    const [checkRadioPiva, setcheckRadioPiva] = useState(false);
    const [checkRadioCf, setcheckRadioCf] = useState(false);
    const [showRichiestaVisura, setRichiestaVisura] = useState(false);

    const [showTipoRicerca, setTipoRicerca] = useState("");
    const [showDatiRicerca, setDatiRicerca] = useState(undefined);

    const [showSalvaRicerca, setShowSalvaRicerca] = useState(false);
    const [datiRicercaSalva, setDatiRicercaSalva] = useState(undefined);
    const [datiRicercaLoad, setDatiRicercaLoad] = useState(
        ['Area: COSENZA (comune)', 
            'Forma Giuridica: Società Di Capitale', 
            "Natura Giuridica: SOCIETA' A RESPONSABILITA' LIMITATA",
            "Codice Ateco: A.12.12",
            "Codice Ateco: A.12.12",
            "Codice Ateco: A.12.12",
            "Codice Ateco: A.12.12"
        ]
    )

    const { state } = useLocation();
    const { row } = state || {};
    const { tipo } = state || {};

    const ID_VISURA = 7
    const ID_BILANCIO = 6

    type ListBoxRenderer = (
        options: any,
        suggestions: any,
        { query, selectedIndex, getItemProps }: { query: string; selectedIndex: number; getItemProps: (index: number) => { key: string; onClick: () => void; onMouseEnter: () => void; } },
      ) => React.ReactNode;

    interface CustomTagSuggestion {
        id: number;
        name: string
        label: string;
        value: string;
        description: string;
        code: string
      }
      
      interface TagSuggestion {
        id: number;
        label: string;
        name: string;
        value: string;
        description: string;
        code: string
      }
      
      const adaptCustomToTagSuggestion = (customSuggestion: CustomTagSuggestion): TagSuggestion => {
        return {
          id: customSuggestion.id,
          label:customSuggestion.name,
          name: customSuggestion.name,
          value: customSuggestion.value,
          description: customSuggestion.description,
          code: customSuggestion.code
        };
      };

    type OptionTypeDropDown = {
        value: string;
        label: string;
        tipo?: string;
    };

    type OptionTypeDatiRicerca = {
        statoImpresa: any;
        addetti: any;
        capitaleSoc: any;
        classeForma: any;
        provincia: any;
        comune: any;
        dtIscrizioneMin: any;
        dtIscrizioneMax: any;
        dtCancellazioneMin: any;
        dtCancellazioneMax: any;
        codiceAttivita: any;
    };


    type OptionTypeRowDataPreventivo = {
        esito: string;
        numero_posizioni_preventivo: string;
        numero_posizioni_disponibili: string;
        numero_posizioni_residue: string;
        messaggio: string;


    };
    
    const provinciaDefault: OptionTypeDropDown = {
        value: "",
        label: ""
    }
    const multiValueProvincie =  null
    const provicieLista: OptionTypeDropDown [] = []
    const [selectedProvincia, setSelectedProvincia] = useState(multiValueProvincie);
    const [selectedProvincie, setProvincie] = useState(provicieLista);
    const [showDetailPuntuale, setShowDetailPuntuale] = useState(false);

    const selectProvincia = () => {
        getWs_Provincieinfocamere(1, 1000, '').then((response)=> {
            if (response && response.records) {
                const provincie : OptionTypeDropDown[] = []
                response.records?.map((item, i) => {
                    const provincia: OptionTypeDropDown = {
                        value: "",
                        label: ""
                    }
                    provincia.label = item.DESCRIZIONE
                    provincia.value = item.SIGLAPROVINCIA
                    provincie.push(provincia)
                })
        
                setProvincie(provincie)
            }
        })
    }

    const rowDataPreventivoInfo: OptionTypeRowDataPreventivo = {
        esito: "",
        numero_posizioni_preventivo: "",
        numero_posizioni_disponibili: "",
        numero_posizioni_residue: "",
        messaggio: ""
    }
    const [showDatiRichiestaPreventivo, setshowDatiRichiestaPreventivo] = useState(false);
    const [rowDataPreventivo, setRowDataPreventivo] = useState(undefined);
    const [rowDataResponsePreventivo, setrowDataResponsePreventivo] = useState(undefined)
    const [listaImpreseRicercaUnica, setListaImpresaRicercaUnica] = useState(undefined)
    const [posizioniSelezionate, setPosizioniSelezionate] = useState(undefined)
    const [nextPage, setNextPage] = useState(undefined)
    const [prevPage, setPrevPage] = useState(undefined)
    const [primaPagina, setPrimaPagina] = useState(false)
    const [urlRicerca, setUrlRicerca] = useState("")
    const [datiRicercaListaSalva, setDatiRicercaListaSalva] = useState(undefined)

    const getDatiRicercaImpreseEsteso = (datiricerca) => {
        const rowDataPreventivo: OptionTypeRowDataPreventivo = {
            esito: "",
            numero_posizioni_preventivo: "",
            numero_posizioni_disponibili: "",
            numero_posizioni_residue: "",
            messaggio: ""
        }
        setIsLoading(true);
        getRicercaEstesa(datiricerca).then((response) => {
          if (response != null && response.data.message === "OK") {
            //dispatch(setElenco_Imprese_Esteso_EstrazioneList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
            //dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Visura Camerale Richiesta"));
           

            if(response.data.status === 300){
                rowDataPreventivo.esito = "300";
                rowDataPreventivo.messaggio = response.data.response;
            }else if(response.data.status === 200){

                const posizioni_disponibili = response.data.anagrafiche;//da prendere da abbonamento
                const posizioni_preventivo = response.data.response.totalElements;
                const posizioni_residue = Number(posizioni_disponibili) - 1;//- Number(posizioni_preventivo);
                let nessuna_posizione = false;
                if(Number(posizioni_preventivo) === 0) nessuna_posizione = true;

                if(Number(posizioni_preventivo) === 0 && nessuna_posizione){
                    rowDataPreventivo.esito = "201";
                    rowDataPreventivo.messaggio = "Non hai sufficienti crediti per poter visualizzare questo elenco";
                    rowDataPreventivo.numero_posizioni_preventivo = '0';
                    rowDataPreventivo.numero_posizioni_disponibili = '0';

                // }else if(posizioni_residue < 0 && !nessuna_posizione){
                //     rowDataPreventivo.esito = "301";
                //     rowDataPreventivo.messaggio = "Non hai sufficienti crediti per poter visualizzare questo elenco";
                //     rowDataPreventivo.numero_posizioni_preventivo = posizioni_preventivo;
                //     rowDataPreventivo.numero_posizioni_disponibili = posizioni_disponibili;

                }else{
                    rowDataPreventivo.esito = "200";
                    rowDataPreventivo.messaggio = "La ricerca ha prodotto n: "+ posizioni_preventivo;
                    rowDataPreventivo.numero_posizioni_preventivo = posizioni_preventivo;
                    rowDataPreventivo.numero_posizioni_disponibili = posizioni_disponibili;
                    rowDataPreventivo.numero_posizioni_residue = posizioni_residue.toString();
                    setrowDataResponsePreventivo(response.data.response)
                    setListaImpresaRicercaUnica(response.data.response.aziende)
                    setPosizioniSelezionate(posizioni_preventivo)
                    setNextPage(response.data.response.nextpage)
                    dispatch(setRicercaUnicaDataList({ pageNo: 0, pageSize: 100, list: response.data.response.aziende, totalCount: 100, searchKey: "", nextPage: nextPage, prevPage: prevPage, posizioniRicerca: posizioniSelezionate }));
                    setUrlRicerca(response.data.ricerca)

                }
            }

            setIsLoading(false);
            setRowDataPreventivo(rowDataPreventivo);
            //setshowDatiRichiestaPreventivo(true)
            
          } else {
            //dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Visura non richiedibile"));
            if(response.data.status === 500){
                rowDataPreventivo.esito = "500";
                rowDataPreventivo.messaggio = "Abbiamo rilevato un errore generico! Riprovare!";
            }else if(response.data.status === 501){
                rowDataPreventivo.esito = "500";
                rowDataPreventivo.messaggio = "Non hai sufficiente credito per effettuare la ricerca!"
            }
            setErrore(true)
            setMessaggioErrore(rowDataPreventivo.messaggio)
            setIsLoading(false);
            setRowDataPreventivo(rowDataPreventivo);
            //setshowDatiRichiestaPreventivo(true)
          }
        })
    }


    const [idDettaglioAzienda, setIdDettaglioAzienda] = useState(undefined);
    const DETTAGLIO_IMPRESA = 10
    //const [showConfermaRichiestaElenco, setshowConfermaRichiestaElenco] = useState(false);
    const [responseDisponibilita, setResponseDisponibilita] = useState(undefined);
    const [showDisponibilita, setShowDisponibilita] = useState(false);

    const [showDisponibilitaVisura, setShowDisponibilitaVisura] = useState(false);
    const [showDisponibilitaBilancio, setShowDisponibilitaBilancio] = useState(false);

    const [datiSocietaRichiestaCamerale, setDatiSocietaRichiestaCamerale] = useState(undefined)
    const [datiSocietaRichiestaBilancio, setDatiSocietaRichiestaBilancio] = useState(undefined)

    const richiestaDettaglioAzienda = async (id_soggetto) => {
        if(id_soggetto != null){
            
            try {
                //setIsLoading(true);
                
                // Effettua la chiamata all'API per ottenere le opzioni basate sulla query
                var datiricerca = {
                    "id_azienda": id_soggetto
                }
                //const response = await getDettaglioAzienda(datiricerca);//fetch(`https://api.example.com/suggestions?query=${query}`);
                //const data = await response.data.response;
                // Imposta le opzioni nel tuo stato
                //setDatiDettaglioAzienda(data)

                setIdDettaglioAzienda(id_soggetto)

                let dati_ricerca = {
                    "report": DETTAGLIO_IMPRESA 
                }
        
                controlloDisponibilita(dati_ricerca).then((response) =>{
                    console.log(response)
                    if(response != null && response.data.status === 200){
                        let body = {
                            "anagrafiche": response.data.anagrafiche,
                            "response": response.data.response,
                            "prodotto": response.data.prodotto
        
                        }
                        if(response.data.anagrafiche > 0){
                            confermaRichiestaDettaglioAzienda(id_soggetto)
                        }else{
                            setResponseDisponibilita(body)
                            setShowDisponibilita(true)
                            setIsLoading(false)

                        }
                        
        
                    }
        
                })
                

                //navigate('/ricerca_unica/dettaglio/'+id_soggetto, {replace: false });
        
                } catch (error) {
                console.error('Errore durante il recupero delle opzioni:', error);
                } finally {
                setIsLoading(false);
                }
        }
    }

    
    const confermaRichiestaDettaglioAzienda = (id_soggetto) =>{

        const itemData = {
            id: posizioniSelezionate,
            name: "Item 1",
            description: "This is the description for Item 1",
          };

        navigate('/ricerca_unica/dettaglio/'+id_soggetto, {replace: false, state: itemData });

    }


    const [showConfermaRichiestaElenco, setshowConfermaRichiestaElenco] = useState(false);
    const [rowDataRispostaElenco, setrowDataRispostaElenco] = useState(undefined);
    const [rowResponseDataRispostaElenco, setrowResponseDataRispostaElenco] = useState(undefined)

    const handleServerRichestaEleneco = () => {
        if(rowDataResponsePreventivo != null){
            getRichiestaElencoImpresa(rowDataResponsePreventivo).then((response) => {
                if (response != null && response.data.message === "OK") {
                    setshowDatiRichiestaPreventivo(false)
                    setrowResponseDataRispostaElenco(response.data)
                    setshowConfermaRichiestaElenco(true)
                    
                    
                }else{
                    setshowDatiRichiestaPreventivo(false)
                    setrowResponseDataRispostaElenco(response.data)
                    setshowConfermaRichiestaElenco(true)
                    
                }
            });
        }
    }

    const handleServerRedirectDifferita = () => {

        navigate('/ws_ticket', { replace: true });
    }

      

    const multiValueStatoAttivita =  null
    const statoAttivitaLista: OptionTypeDropDown [] = []
    const [selectedStatoAttivita, setStatoAttivita] = useState(multiValueStatoAttivita);
    const [selectedStatiAttivita, setStatiAttivita] = useState(statoAttivitaLista);

    const selectDatiStatoAttivita = () =>{
        let stati : OptionTypeDropDown[] = []

        const statiDisponibili = [
            // {
            //     value: "T",
            //     label: "Tutte"
            // },
            {
                value: "INA",
                label: "Escluse Intattive"

            },
            {
                value: "APR",
                label: "Escluse Aziende in Procedura"

            },
        ]

        stati = statiDisponibili;

        setStatiAttivita(stati);

    }

    const multiValueFormaGiuridica =  null
    const formaGiuridicaLista: OptionTypeDropDown [] = []
    const [selectedFormaGiuridica, setFormaGiuridica] = useState(multiValueFormaGiuridica);
    const [selectedFormeGiuridiche, setFormeGiuridiche] = useState(formaGiuridicaLista);

    const selectFormaGiuridicaImpresa = () =>{
        let formaGiuridica : OptionTypeDropDown[] = []

        const formeGiuridiche = [
            {
                value: "",
                label: "Tutte le Forme Giuridiche"
            },
            {
                value: "CAP",
                label: "Società Di Capitale"

            },
            {
                value: "PER",
                label: "Società di Persone"

            },
            {
                value: "DIN",
                label: "Ditte Individuali"

            },
            {
                value: "ALT",
                label: "Altre Forme"

            }
        ]

        formaGiuridica = formeGiuridiche;

        setFormeGiuridiche(formaGiuridica);

    }

    const multiValueComuni =  null
    const comuniLista: OptionTypeDropDown [] = []
    const [selectedComune, setSelectedComune] = useState(multiValueComuni);
    const [selectedComuni, setSelectedComuni] = useState(comuniLista);

    const selectComuni = () => {
        getComuni_Catastali(1, 9000, '').then((response)=> {
            if (response && response.records) {
                const comuni : OptionTypeDropDown[] = []
                response.records?.map((item, i) => {
                    const comune: OptionTypeDropDown = {
                        value: "",
                        label: ""
                    }
                    comune.label = item.nomecomune
                    comune.value = item.codicecomune
                    comuni.push(comune)
                })
        
                setSelectedComuni(comuni)
            }
        })
    }

    const multiValueCodiceAteco =  null
    const codiciAtacoLista: OptionTypeDropDown [] = []
    const [selectedCodiceAteco, setSelectedCodiceAteco] = useState(multiValueCodiceAteco);
    const [selectedCodiciAteco, setSelectedCodiciAteco] = useState(codiciAtacoLista);

    const selectCodiciAteco = () => {
        getCodici_Ateco(1, 1000, '').then((response)=> {
            if (response && response.records) {
              const codiciAteco : OptionTypeDropDown[] = []
              response.records?.map((item, i) => {
                  const ateco: OptionTypeDropDown = {
                      value: "",
                      label: ""
                  }
                  ateco.label = item.descrizione
                  ateco.value = item.codice.toString()
                  codiciAteco.push(ateco)
              })
              setSelectedCodiciAteco(codiciAteco)
            }
        })
    }

    useEffect(() => {
        console.log("----")
        console.log(ricercaUnica)
        console.log(parametro)
        console.log("-------------------------")
        console.log(state)
        


        if(parametro != null && parametro !=""){
            console.log(parametro)
            var datiEstrazione = parametro.split("PE")
            console.log(datiEstrazione[0])

            let dati_ricerca = {
                id_ricerca: datiEstrazione[0]
            }

            const rowDataPreventivo: OptionTypeRowDataPreventivo = {
                esito: "",
                numero_posizioni_preventivo: "",
                numero_posizioni_disponibili: "",
                numero_posizioni_residue: "",
                messaggio: ""
            }
            setIsLoading(true);

            ricercaDiretta(dati_ricerca).then((response) =>{
                if (response != null && response.data.message === "OK") {
                    //dispatch(setElenco_Imprese_Esteso_EstrazioneList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
                    //dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Visura Camerale Richiesta"));
                   
        
                    if(response.data.status === 300){
                        rowDataPreventivo.esito = "300";
                        rowDataPreventivo.messaggio = response.data.response;
                    }else if(response.data.status === 200){
        
                        const posizioni_disponibili = response.data.anagrafiche;//da prendere da abbonamento
                        const posizioni_preventivo = response.data.response.totalElements;
                        const posizioni_residue = Number(posizioni_disponibili) - Number(posizioni_preventivo);
                        let nessuna_posizione = false;
                        if(Number(posizioni_preventivo) === 0) nessuna_posizione = true;
        
                        if(Number(posizioni_preventivo) === 0 && nessuna_posizione){
                            rowDataPreventivo.esito = "201";
                            rowDataPreventivo.messaggio = "Non hai sufficienti crediti per poter visualizzare questo elenco";
                            rowDataPreventivo.numero_posizioni_preventivo = '0';
                            rowDataPreventivo.numero_posizioni_disponibili = '0';
        
                        // }else if(posizioni_residue < 0 && !nessuna_posizione){
                        //     rowDataPreventivo.esito = "301";
                        //     rowDataPreventivo.messaggio = "Non hai sufficienti crediti per poter visualizzare questo elenco";
                        //     rowDataPreventivo.numero_posizioni_preventivo = posizioni_preventivo;
                        //     rowDataPreventivo.numero_posizioni_disponibili = posizioni_disponibili;
        
                        }else{
                            rowDataPreventivo.esito = "200";
                            rowDataPreventivo.messaggio = "La ricerca ha prodotto n: "+ posizioni_preventivo;
                            rowDataPreventivo.numero_posizioni_preventivo = posizioni_preventivo;
                            rowDataPreventivo.numero_posizioni_disponibili = posizioni_disponibili;
                            rowDataPreventivo.numero_posizioni_residue = posizioni_residue.toString();
                            setrowDataResponsePreventivo(response.data.response)
                            setListaImpresaRicercaUnica(response.data.response.aziende)
                            setPosizioniSelezionate(posizioni_preventivo)
                            setNextPage(response.data.response.nextpage)
                            dispatch(setRicercaUnicaDataList({ pageNo: 0, pageSize: 100, list: response.data.response.aziende, totalCount: 100, searchKey: "", nextPage: nextPage, prevPage: prevPage, posizioniRicerca: posizioniSelezionate }));
                            setUrlRicerca(response.data.ricerca)
        
                        }
                    }
        
                    setIsLoading(false);
                    setRowDataPreventivo(rowDataPreventivo);
                    //setshowDatiRichiestaPreventivo(true)
                    
                  } else {
                    //dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Visura non richiedibile"));
                    if(response.data.status === 500){
                        rowDataPreventivo.esito = "500";
                        rowDataPreventivo.messaggio = "Abbiamo rilevato un errore generico! Riprovare!";
                    }
                    setIsLoading(false);
                    setRowDataPreventivo(rowDataPreventivo);
                    setshowDatiRichiestaPreventivo(true)
                  }


            })


        }
        if(ricercaUnica != null && ricercaUnica.list != null ){
            setListaImpresaRicercaUnica(ricercaUnica.list)
            setPosizioniSelezionate(ricercaUnica.posizioniRicerca)
            setNextPage(ricercaUnica.nextPage)
            setPrevPage(ricercaUnica.prevPage)
        }

        selectProvincia();
        fetchTipoSede();
        selectDatiStatoAttivita()
        selectFormaGiuridicaImpresa();
        selectComuni();
        selectCodiciAteco();
        fetchNaturaGiuridica("")   
        fetchAtecoP()     
      }, []);

      useEffect(() => {
        setPosizioniSelezionate(0)
        console.log(state)
        console.log(ricercaUnica)
        if(state != null && ricercaUnica.list != null && ricercaUnica.list.length > 0)setPosizioniSelezionate(state.id)

      }, [])  
    const handleCardClick = (path) => {
        dispatch(activateMenu(path))
        navigate(path, { replace: true });
    }

    const handleSearch = () => {
        setSearch(search);
        console.log(search)
        console.log(checkRadioDen)
        console.log(checkRadioPiva)
        console.log(checkRadioCf)

        let tiporicerca = 0;
        if(checkRadioDen) tiporicerca = 1;
        if(checkRadioCf) tiporicerca = 2;
        if(checkRadioPiva) tiporicerca = 3;
        // //if (search.length > 0) {
        //     getTrovaImpresa(datiricerca, 0, 0).then((response)=> {
        //         console.log(response.data)
        //         responseData = response.data.response.impresa
        //         setResponseData(responseData)
        //     })
        // //}
        var datiricerca = {
            "denominazione": encodeURIComponent(search).replace("%20","+"),
            "provincia": provincia,
            "partitaiva": search,
            "codicefiscale": search,
            "tiporicerca": tiporicerca
        }

        getData(1, 20, search, datiricerca)


    }

    const [ricercaImpresaEstesa, setRicercaImpresaEstesa] = useState(false);

    const handleSearchEstesa = () => {
        
        


        if(selectedProvincia!=null /*|| selectedProvincia.length != 0*/){
            console.log("provincie non settate");
            setRicercaImpresaEstesa(true);
            
        }else{
            var datiricerca = {
                "selectedArea": selectedArea,
                "selectedSingleCop": selectedSingleCop,
                "selectedFormaGiuridica": selectedFormaGiuridica,
                "selectedSingleNatGiu": selectedSingleNatGiu,
                "selectedSingleAtecoP": selectedSingleAtecoP,
                "selectedSingleAteco": selectedSingleAteco,
                "selectedStatoAttivita": selectedStatoAttivita,
                "selectedSingleTipoSede": selectedSingleTipoSede,
                "selectedSingleRicaviDa": selectedSingleRicaviDa,
                "selectedSingleRicaviA": selectedSingleRicaviA,
                "selectedSingleAddettiDa": selectedSingleAddettiDa,
                "selectedSingleAddettiA": selectedSingleAddettiA,
                "selectedSingleSulMercatoDa": selectedSingleSulMercatoDa,
                "selectedSingleSulMercatoAl": selectedSingleSulMercatoAl,
                "selectedRicercaLibera": selectedRicercaLibera
              }
            
            if(
                (selectedArea != null ||
                selectedSingleCop != null ||
                selectedFormaGiuridica != null ||
                selectedSingleNatGiu != null ||
                selectedSingleAtecoP != null ||
                selectedSingleAteco != null ||
                selectedStatoAttivita != null ||
                selectedSingleTipoSede != null ||
                selectedSingleRicaviDa != null ||
                selectedSingleRicaviA != null ||
                selectedSingleAddettiDa != null ||
                selectedSingleAddettiA != null ||
                selectedSingleSulMercatoDa != null ||
                selectedSingleSulMercatoAl != null
                ) &&
                (
                    (selectedRicercaLibera != null && selectedRicercaLibera != "")
                )
            ){
                setMessaggioErrore("Non è possibile effettuare una ricerca con valorizzato il campo Ricerca Unica ed almeno 1 delle Ricerche Estese")

                setSelectedArea(null)
                setSelectedSingleCap(null)
                setFormaGiuridica(null)
                setSelectedSingleNatGiu(null)
                setSelectedSingleAtecoP(null)
                setSelectedSingleAteco(null)
                setStatoAttivita(null)
                setSelectedSingleTipoSede(null)
                setSelectedSingleRicaviDa(null)
                setSelectedSingleRicaviA(null)
                setSelectedSingleAddettiDa(null)
                setSelectedSingleAddettiA(null)
                setSelectedSingleSulMercatoDa(null)
                setSelectedSingleSulMercatoAl(null)
                setSelectedRicercaLibera("")
                

                
                setErrore(true)
            }else{

                //console.log(datiricerca)
                setDatiRicercaSalva(datiricerca)
                let datiRicercaLoadtmp = generateRicercaString(datiricerca)
                setDatiRicercaLoad(datiRicercaLoadtmp)
                getDatiRicercaImpreseEsteso(datiricerca);
                
            }


            
        }


    }

    const generateRicercaString = (ricerca: any) => {
        const ricercaArray: string[] = [];
        console.log(ricerca)

        
    
        if (ricerca.selectedRicercaLibera) {
            ricercaArray.push(`Ricerca Unica: ${ricerca.selectedRicercaLibera}`);
        }
    
        if (ricerca.selectedArea) {
            const area = `Area: ${ricerca.selectedArea.map(a => a.label).join(" ")}`;
            ricercaArray.push(area);
        }
    
        if (ricerca.selectedSingleCop) {
            const cap = `Cap: ${ricerca.selectedSingleCop.map(a => a.label).join(" ")}`;
            ricercaArray.push(cap);
        }
    
        if (ricerca.selectedFormaGiuridica) {
            const formaGiuridica = `Forma Giuridica: ${ricerca.selectedFormaGiuridica.map(a => a.label).join(" ")}`;
            ricercaArray.push(formaGiuridica);
        }
    
        if (ricerca.selectedSingleNatGiu) {
            const naturaGiuridica = `Natura Giuridica: ${ricerca.selectedSingleNatGiu.map(a => a.label).join(" ")}`;
            ricercaArray.push(naturaGiuridica);
        }
    
        if (ricerca.selectedSingleAtecoP) {
            const atecoPrincipale = `Ateco Principale: ${ricerca.selectedSingleAtecoP.map(a => a.label).join(" ")}`;
            ricercaArray.push(atecoPrincipale);
        }
    
        if (ricerca.selectedSingleAteco) {
            const ateco = `Ateco: ${ricerca.selectedSingleAteco.map(a => a.label).join(" ")}`;
            ricercaArray.push(ateco);
        }
    
        if (ricerca.selectedStatoAttivita) {
            const statoAttivita = `Stato Attività: ${ricerca.selectedStatoAttivita.map(a => a.label).join(" ")}`;
            ricercaArray.push(statoAttivita);
        }
    
        if (ricerca.selectedSingleTipoSede) {
            const tipoSede = `Tipo Sede: ${ricerca.selectedSingleTipoSede.map(a => a.label).join(" ")}`;
            ricercaArray.push(tipoSede);
        }
    
        if (ricerca.selectedSingleRicaviDa) {
            const ricaviDa = `Ricavo Da: ${ricerca.selectedSingleRicaviDa.map(a => a.label).join(" ")}`;
            ricercaArray.push(ricaviDa);
        }
    
        if (ricerca.selectedSingleRicaviA) {
            const ricaviA = `Ricavo A: ${ricerca.selectedSingleRicaviA.map(a => a.label).join(" ")}`;
            ricercaArray.push(ricaviA);
        }
    
        if (ricerca.selectedSingleAddettiDa) {
            const addettiDa = `Addetti Da: ${ricerca.selectedSingleAddettiDa.map(a => a.label).join(" ")}`;
            ricercaArray.push(addettiDa);
        }
    
        if (ricerca.selectedSingleAddettiA) {
            const addettiA = `Addetti A: ${ricerca.selectedSingleAddettiA.map(a => a.label).join(" ")}`;
            ricercaArray.push(addettiA);
        }
    
        if (ricerca.selectedSingleSulMercatoDa) {
            const mercatoDa = `Su Mercato Da: ${ricerca.selectedSingleSulMercatoDa.map(a => a.label).join(" ")}`;
            ricercaArray.push(mercatoDa);
        }
    
        if (ricerca.selectedSingleSulMercatoAl) {
            const mercatoA = `Su Mercato Al: ${ricerca.selectedSingleSulMercatoAl.map(a => a.label).join(" ")}`;
            ricercaArray.push(mercatoA);
        }
        console.log(ricercaArray)

        if(ricercaArray.length === 0) ricercaArray.push(`Ricerca effettuata su tutto il registro`);
    
        return ricercaArray;
    };

    const handleDenominazioneValue = async (search) => {
        setSearch(search);
        //console.log(search)
    }

    const handlePartitaIvaValue = async (partitaiva) =>{
        setSearch(partitaiva);

    }
    const handleCodiceFiscaleValue = async (codicefiscale) =>{
        setSearch(codicefiscale);

    }


    const handleRowDetailClick = (row) => {
        setIsLoading(true);
        var datiricerca = {
            "chiveimpresa": row["chiave-impresa"],
            "idprodotto": 2
        }
        getTrovaImpresaPec(datiricerca).then((response)=> {
            //setRowData(row);
            
            if (response && response.data.response != null) {

              if(response.data.status === 203){
                setNoAcquisto(true);
                setIsLoading(false);
              }else if(response.data.status > 250){
                setErroreGenerico(true);
                setIsLoading(false);
              }else if(response.data.response.imprese !=null){
                numero_imprese = response.data.NumeroPosizioniTotali
                setRowData(response.data.response.impresa[0]);
                setShowDetail(true);
                setIsLoading(false);
              }else{
                setIsLoading(false);
              } 
              
            } else {
            //   dispatch(setTrovaImpresaMessage("Nessun dato presente in questa sezione"));
            setIsLoading(false);
            }
          })
        
    }

    

    const handleServerDettaglioPuntualeImpresa = async (row) => {
        setRowData(row);
        setShowDetailPuntuale(true)

    }

    const handleServerDelete = async () => {}

    const handleRadioRicercaDenominazione = async () => {

        setdisableRadioDen(false)
        setdisableRadioPiva(true)
        setdisableRadioCf(true)

        setcheckRadioDen(true)
        setcheckRadioCf(false)
        setcheckRadioPiva(false)


    }
    const handleRadioRicercaPiva = async () => {

        setdisableRadioDen(true)
        setdisableRadioPiva(false)
        setdisableRadioCf(true)

        setcheckRadioDen(false)
        setcheckRadioCf(false)
        setcheckRadioPiva(true)


    }

    const handleRadioRicercaCf = async () => {

        setdisableRadioDen(true)
        setdisableRadioPiva(true)
        setdisableRadioCf(false)
        
        setcheckRadioDen(false)
        setcheckRadioCf(true)
        setcheckRadioPiva(false)

    }

    const [listaAreaGeografoca, setListaAreaGeografoca] = useState([]);
    const getDataAreaGeografica= (area_geografica) => {
        //setIsLoading(true);
        if(area_geografica.input.value != null){
            var datiricerca = {
                "area_geografica": area_geografica.input.value
            }
        

        getDatiAreaGeografica(datiricerca).then((response)=> {
          if (response && response.data) {
            dispatch(setAreaGeograficaList({ pageNo: 0, pageSize: 100, list: response.data, totalCount: response.data.length(), searchKey: ''}));
            setListaAreaGeografoca(response.data)
            //setIsLoading(false);
            return response.data
          } else {
            dispatch(setAreaGeograficaMessage("Nessun dato presente in questa sezione"));
            setIsLoading(false);
            return []
          }
        })
        }
        
            
      }

      


    const visualizzaUnitaLocali = async (unitaLocali) =>{
        unitaLocali.forEach((el) =>{
            return  <div className="col-sm-12">Denominazione: {el.StatoAttivitaR }</div>
        })
    }
    const handleRowConfermaRichiestaVisuraClick = (row) => {
        //setRowData(row);
        setRowDataVisura(row)
        setRichiestaVisura(true);
    }

    const handleRowConfermaRichiestaVisuraClickLista = (row, idvisura) => {
        setIsLoading(true)
        let dati_ricerca = {
            "report": idvisura 
        }

        controlloDisponibilita(dati_ricerca).then((response) =>{
            console.log(response)
            if(response != null && response.data.status === 200){
                let body = {
                    "anagrafiche": response.data.anagrafiche,
                    "response": response.data.response,
                    "prodotto": response.data.prodotto

                }
                if(response.data.anagrafiche > 0){
                    //handleServerVisuraLista(row)
                    setDatiSocietaRichiestaCamerale(row)
                    setResponseDisponibilita(body)
                    setShowDisponibilitaVisura(true)
                    setIsLoading(false)
                }else{
                    setResponseDisponibilita(body)
                    setShowDisponibilitaVisura(true)
                    setIsLoading(false)

                }
                

            }

        })
        
        
        //setRowData(row);
        
        //setRowDataVisura(row)
        //setRichiestaVisura(true);
    }

    const handleServerVisuraLista = async (row) => {
        console.log(row)
        if (row) {
            let cciaa = row.cciaa_nrea.substring(0,2)
            let nrea = row.cciaa_nrea.substring(2)
            let codicefiscale = row.codice_fiscale;
            nrea = nrea.replace(/^0+/, '');
            console.log(cciaa)
            console.log(nrea)
            setRichiestaVisura(false);
            handleRowVisuraLista(cciaa,nrea, codicefiscale, row.ragione_sociale, 'A');      
        } else {
            //dispatch(setElenco_Imprese_EstesoMessage("Some error occured!"));
            setShowDisponibilitaVisura(false)
            //dispatch(setElenco_Imprese_EstesoMessage("Some error occured!"));
            setMessaggioErrore("Errore nella richiesta del bilancio. Riprovare o contattare il servizio cliente.")
            setErrore(true)

        }
    }

    const handleServerVisura = async () => {
        if (rowDataVisura) {
            setRichiestaVisura(false);
            handleRowVisura(rowDataVisura.cciaa[0],rowDataVisura.nrea[0], rowDataVisura.denominazione[0], rowDataVisura.StatoAttivita[0]);      
        } else {
            //dispatch(setElenco_Imprese_EstesoMessage("Some error occured!"));
        }
    }

    const handleRowVisura = (cciaa, nrea, nomeanagrafica, stato_attivita) => {
        const idprodotto = 7;
        if(stato_attivita.toUpperCase() !== 'A'.toUpperCase()){ 
            getDatiVisura(cciaa, nrea, nomeanagrafica, idprodotto, "VISSTO");
        }else{
            getDatiVisura(cciaa, nrea, nomeanagrafica, idprodotto, "VISORDIN");
        }
        
    }

    const handleRowVisuraLista = (cciaa, nrea, codice_fiscale, nomeanagrafica, stato_attivita) => {
        const idprodotto = 7;
        if(stato_attivita.toUpperCase() !== 'A'.toUpperCase()){ 
            getDatiVisuraLista(cciaa, nrea, codice_fiscale, nomeanagrafica, idprodotto, "VISSTO");
        }else{
            getDatiVisuraLista(cciaa, nrea, codice_fiscale, nomeanagrafica, idprodotto, "VISORDIN");
        }
        
    }

    const getDatiVisuraLista = (cciaa, nrea, codice_fiscale, nomeanagrafica, idprodotto, tipovisura) => {
        setIsLoading(true);
        var datiricerca = {
          "cciaa": cciaa,
          "nrea": nrea,
          "codicefiscale": codice_fiscale,
          "nomeanagrafica": nomeanagrafica,
          "idprodotto": idprodotto,
          "tipovisura": tipovisura
        }
        getVisuraCameraleInfo(datiricerca).then((response) => {
            if (response != null && response.data.message === "OK") {
                //dispatch(setElenco_Imprese_Esteso_EstrazioneList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
                //dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Bilancio Richiesto"));
                setShowMessaggio("Il Visura Camerale richiesta con successo! Il documento sarà presto disponibile nella sezione Archivio tra qualche minuto Grazie");
                setConfermaRichiesta(true);
                setIsLoading(false);
                setShowDisponibilitaVisura(false)
              } else {
                //dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Bilancio non richiedibile"));
                setMessaggioErrore("La richiesta della Visura non è stata effettuata con successo. Riprovare e se il problema persiste contattare il servizio assistenza. Grazie")
                setErrore(true)
                setIsLoading(false);
              }
            })
      }

    const [showRichiestaBilancio, setRichiestaBilancio] = useState(false);
    const handleRowConfermaRichiestaBilancioClick = (row) => {
        setRowData(row);
        setRichiestaBilancio(true);
    }

    const handleRowConfermaRichiestaBilancioClickLista = (row, idbilancio) => {
        setIsLoading(true)
        let dati_ricerca = {
            "report": idbilancio 
        }

        controlloDisponibilita(dati_ricerca).then((response) =>{
            console.log(response)
            if(response != null && response.data.status === 200){
                let body = {
                    "anagrafiche": response.data.anagrafiche,
                    "response": response.data.response,
                    "prodotto": response.data.prodotto

                }
                if(response.data.anagrafiche > 0){
                    //handleServerVisuraLista(row)
                    setDatiSocietaRichiestaBilancio(row)
                    setResponseDisponibilita(body)
                    setShowDisponibilitaBilancio(true)
                    setIsLoading(false)
                }else{
                    setResponseDisponibilita(body)
                    setShowDisponibilitaBilancio(true)
                    setIsLoading(false)

                }
                

            }

        })
        
        
        //setRowData(row);
        
        //setRowDataVisura(row)
        //setRichiestaVisura(true);
    }

    const handleRowEnableBilancio = (row, id_bilancio) => {

        if(row.fatturato === '' || row.fatturato == null ){
            return (<span>
            <i className="fa fa-times-circle margin-r-5" aria-hidden="true"></i> 
            <strong className="margin-r-5">Nessun Bilancio</strong>
            </span>)
        }else{
            if(row.fatturato !== 'Registrata Attiva'){
                return(<span onClick={() => handleRowConfermaRichiestaBilancioClickLista(row, id_bilancio)}>
                    <i className="fa fa-line-chart margin-r-5" aria-hidden="true"></i> <strong className="">Ultimo Bilancio</strong></span>);
            }else{
                return(<span onClick={() => handleRowConfermaRichiestaBilancioClickLista(row, id_bilancio)}>
                    <i className="fa fa-line-chart margin-r-5" aria-hidden="true"></i> <strong className="">Ultimo Bilancio</strong></span>);
            }
            
            
        }
      }
    
      const [showDelete, setShowDelete] = useState(false);
      const handleServerBilancio = async () => {
        if (rowData) {
            setShowDelete(false);
            handleRowBilancio(rowData["attributi"]["cciaa"],rowData["attributi"]["n-rea"], rowData["denominazione"]);      
        } else {
            dispatch(setElenco_Imprese_EstesoMessage("Some error occured!"));
        }
    }

    const handleServerBilancioLista = async (row) => {
        if (row) {
            let cciaa = row.cciaa_nrea.substring(0,2)
            let nrea = row.cciaa_nrea.substring(2)

            nrea = nrea.replace(/^0+/, '');
            console.log(cciaa)
            console.log(nrea)
            setRichiestaBilancio(false);
            handleRowBilancioLista(cciaa,nrea, row.codice_fiscale, row.ragione_sociale);      
        } else {
            setShowDisponibilitaBilancio(false)
            //dispatch(setElenco_Imprese_EstesoMessage("Some error occured!"));
            setMessaggioErrore("Errore nella richiesta del bilancio. Riprovare o contattare il servizio cliente.")
            setErrore(true)

        }
    }

    const handleRowBilancio = (cciaa, nrea, nomeanagrafica) => {
        const idprodotto = 6;
        getDataBilancio(cciaa, nrea, nomeanagrafica, idprodotto);
      }
    
      const handleRowBilancioLista = (cciaa, nrea, codicefiscale, nomeanagrafica) => {
        const idprodotto = 6;
        getDataBilancioLista(cciaa, nrea, nomeanagrafica, codicefiscale, idprodotto);
      }

      const [showConfermaRichiesta, setConfermaRichiesta] = useState(false);
      const [showErrore, setErrore] = useState(false);
      const [showMessaggio, setShowMessaggio] = useState("")
      const [showMessaggioErrore, setMessaggioErrore] = useState("Errore nella richiesta")
      const getDataBilancio = (cciaa, nrea, nomeanagrafica, idprodotto) => {
        setIsLoading(true);
        var datiricerca = {
          "cciaa": cciaa,
          "nrea": nrea,
          "nomeanagrafica": nomeanagrafica,
          "idprodotto": idprodotto
        }
        getUltimoBilancio(datiricerca).then((response) => {
          if (response != null && response.data.message === "OK") {
            //dispatch(setElenco_Imprese_Esteso_EstrazioneList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
            //dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Bilancio Richiesto"));
            setShowMessaggio("Il Bilancio è stato richiesto con successo! Grazie")
            setConfermaRichiesta(true);
            setIsLoading(false);
          } else {
            //dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Bilancio non richiedibile"));
            setMessaggioErrore("La richiesta del Bilancio non è stata effettuata con successo. Riprovare e se il problema persiste contattare il servizio assistenza. Grazie")
            setErrore(true)
            setIsLoading(false);
          }
        })
      }

      const getDataBilancioLista = (cciaa, nrea, nomeanagrafica, codicefiscale, idprodotto) => {
        setIsLoading(true);
        var datiricerca = {
          "cciaa": cciaa,
          "nrea": nrea,
          "codicefiscale": codicefiscale,
          "nomeanagrafica": nomeanagrafica,
          "idprodotto": idprodotto
        }
        getUltimoBilancio(datiricerca).then((response) => {
          if (response != null && response.data.message === "OK") {
            //dispatch(setElenco_Imprese_Esteso_EstrazioneList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
            //dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Bilancio Richiesto"));
            setShowMessaggio("Il Bilancio è stato richiesto con successo! Grazie")
            setConfermaRichiesta(true);
            setIsLoading(false);
          } else {
            //dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Bilancio non richiedibile"));
            setMessaggioErrore("La richiesta del Bilancio non è stata effettuata con successo. Riprovare e se il problema persiste contattare il servizio assistenza. Grazie")
            setErrore(true)
            setIsLoading(false);
          }
        })
      }

    const MultiSelect = ({valueList, onChange, options, maxLimit = 5}) => {
        return (
          <Select
            value={valueList}
            isMulti
            onChange={onChange}
            options={options}
            isOptionDisabled={(option) => valueList.length >= maxLimit}
          />
        );
      };

    const [dataIscrizioneDa, setDataIscrizioneDa] = useState('');
    const [dataIscrizioneA, setDataIscrizioneA] = useState('');

    const [dataCancellazioneDa, setDataCancellazioneDa] = useState('');
    const [dataCancellazioneA, setDataCancellazioneA] = useState('');
    

    const borderColorInfo = (row) => {
        if(row.stato_attivita !== 'Attiva'){
            return "col-sm-12 riga-trova-impresa border-left-8-red background-row-white shadow";
        }else{
            return "col-sm-12 riga-trova-impresa border-left-8-green background-row-white shadow";
        }
    }

    const valoreDato = (dato) => {
        if(dato == null){
            return "Dato non fornito"
        }else{
            return dato
        }
    }

    const fatturatoInfo = (row, trend) => {
        if(row == null){
            return (
                <div className="col-sm-6 box-ris-etichetta">Fatturato: <p><strong className="box-ris-dato">Dato non fornito</strong></p></div>
            )
        }else{
            let fatturato = row.toLocaleString('it-IT', {
                style: 'currency',
                currency: 'EUR'
            })
            let icon_trend = ""
            if(trend !=null){
                if(trend === "CRESCENTE"){
                    icon_trend = "fa-solid fa-long-arrow-up color-icon-green"
                }
                if(trend === "CALANTE"){
                    icon_trend = "fa-solid fa-long-arrow-down color-icon-red-bil"
                }
                if(trend === "STAZIONARIO"){
                    icon_trend = "fa-solid fa-arrows-h color-icon-orange"
                }
                return (
                    <div className="col-sm-6 box-ris-etichetta">Fatturato: <p><strong className="box-ris-dato">{fatturato} <i className={icon_trend}></i></strong></p></div>
                )
            }else{
                return (
                    <div className="col-sm-6 box-ris-etichetta">Fatturato: <p><strong className="box-ris-dato">{fatturato}</strong></p></div>
                )
            }
        }
    }
    
   
    const handleInputChangeAreaGeografica = (input) => {
        if(input != null && input.length > 2){
            fetchAreaGeografica(input);
        }
        
    };

    const handleInputChangeCap = (input) => {
        if(input != null && input.length > 4){
            fetchCap(input);
        }
        
    };

    const handleInputChangeNaturaGiuridica = (input) => {
        if(input != null){
            //fetchNaturaGiuridica(input);
        }
        
    };

    const handleInputChangeSulMercatoDa = (input) => {
        if(input != null && input.length > 0){
            fetchSulMercatoDa(input);
        }
        
    };

    const handleInputChangeSulMercatoAl = (input) => {
        if(input != null && input.length > 0){
            fetchSulMercatoAl(input);
        }
        
    };

    const handleInputChangeAteco = (input) => {
        if(input != null && input.length > 2){
            fetchAteco(input);
        }
        
    };

    const handleInputChangeAtecoP = (input) => {
        //if(input != null && input.length > 2){
            fetchAtecoP();
        //}
        
    };

    const handleInputChangeRicaviDa = (input) => {
        if(input != null && input.length > 0){
            fetchRicaviDa(input);
        }
        
    };

    const handleInputChangeRicaviA = (input) => {
        if(input != null && input.length > 0){
            fetchRicaviA(input);
        }
        
    };

    const handleInputChangeAddettiDa = (input) => {
        if(input != null && input.length > 0){
            fetchAddettiDa(input);
        }
        
    };

    const handleInputChangeAddettiA = (input) => {
        if(input != null && input.length > 0){
            fetchAddettiA(input);
        }
        
    };

    const multiValueAtecoP =  null
    const classeAtecoP: OptionTypeDropDown [] = []
    const [selectedSingleAtecoP, setSelectedSingleAtecoP] = useState(multiValueAtecoP);
    const [selectedAtecoP, setSelectedAtecoP] = useState(classeAtecoP);

    const fetchAtecoP = () =>{
        let classeAteco : OptionTypeDropDown[] = []

        const classeAtecoP = [
            {
                value: "",
                label: "Tutti"
            },
            {
                value: "SP",
                label: "Solo Primario"

            }
        ]

        classeAteco = classeAtecoP;

        setSelectedAtecoP(classeAteco);

    }

    const multiValuetipoSede =  null
    const listaTipoSede: OptionTypeDropDown [] = []
    const [selectedSingleTipoSede, setSelectedSingleTipoSede] = useState(multiValuetipoSede);
    const [selectedTipoSede, setSelectedTipoSede] = useState(listaTipoSede);

    const fetchTipoSede = () =>{
        let listaTipoSede : OptionTypeDropDown[] = []

        const tipoSede = [
            {
                value: "SL",
                label: "Sede Legale"
            },
            {
                value: "UL",
                label: "Unità Locali"

            }
        ]

        listaTipoSede = tipoSede;

        setSelectedTipoSede(listaTipoSede);

    }


    const [disattivaStatoAttivita, setDisattivaStatoAttivita] = useState(false);
    const handleInputChangeStatoAttivita = (input) =>{

        console.log(selectedStatoAttivita)
        if(selectedStatoAttivita!= null && selectedStatoAttivita.length > 1){
            setDisattivaStatoAttivita(true)
        }else{
            setDisattivaStatoAttivita(false)
            setStatoAttivita(input)
        }
        

    }

    const multiValueAteco =  null
    const atecoLista: OptionTypeDropDown [] = []
    const [selectedSingleAteco, setSelectedSingleAteco] = useState(multiValueAteco);
    const [selectedAteco, setSelectedAteco] = useState(atecoLista);

    const fetchAteco = async (query) => {
        try {
        //setIsLoading(true);
        
        // Effettua la chiamata all'API per ottenere le opzioni basate sulla query
        var datiricerca = {
            "ateco": query
        }
        const response = await getDatiateco(datiricerca);
        const data = await response.data.lista;
        // Imposta le opzioni nel tuo stato
        const atecoLista : OptionTypeDropDown[] = []
        data.map((item, i) => {
            const ateco: OptionTypeDropDown = {
                value: "",
                label: ""
            }
            ateco.label = item.name
            ateco.value = item.id
            atecoLista.push(ateco)
        })
        setSelectedAteco(atecoLista)

        } catch (error) {
        console.error('Errore durante il recupero delle opzioni:', error);
        } finally {
        setIsLoading(false);
        }
    };

    const multiValueNaturaGiuridica =  null
    const naturaGiuridicaLista: OptionTypeDropDown [] = []
    const [selectedSingleNatGiu, setSelectedSingleNatGiu] = useState(multiValueNaturaGiuridica);
    const [selectedNatGiu, setSelectedNatGiu] = useState(naturaGiuridicaLista);

    const fetchNaturaGiuridica = async (query) => {
        try {
        //setIsLoading(true);
        
        // Effettua la chiamata all'API per ottenere le opzioni basate sulla query
        var datiricerca = {
            "natura_giuridica": query
        }
        const response = await getDatiNaturaGiuridica(datiricerca);//fetch(`https://api.example.com/suggestions?query=${query}`);
        const data = await response.data.lista;
        // Imposta le opzioni nel tuo stato
        const natgiuridicaLista : OptionTypeDropDown[] = []
        data.map((item, i) => {
            const natgiuridica: OptionTypeDropDown = {
                value: "",
                label: ""
            }
            natgiuridica.label = item.name
            natgiuridica.value = item.id
            natgiuridicaLista.push(natgiuridica)
        })
        setSelectedNatGiu(natgiuridicaLista)

        } catch (error) {
        console.error('Errore durante il recupero delle opzioni:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const multiValueCap =  null
    const capLista: OptionTypeDropDown [] = []
    const [selectedSingleCop, setSelectedSingleCap] = useState(multiValueCap);
    const [selectedCap, setSelectedCap] = useState(capLista);

    const fetchCap = async (query) => {
        try {
        //setIsLoading(true);
        // Imposta le opzioni nel tuo stato
        const cap : OptionTypeDropDown[] = []
        const capValue: OptionTypeDropDown = {
            value: "",
            label: ""
        }

        capValue.label=query
        capValue.value=query

        cap.push(capValue)
        
        setSelectedCap(cap)

        } catch (error) {
        console.error('Errore durante il recupero delle opzioni:', error);
        } finally {
        setIsLoading(false);
        }
    };

    const multiValueAddettiDa =  null
    const addettiDaLista: OptionTypeDropDown [] = []
    const [selectedSingleAddettiDa, setSelectedSingleAddettiDa] = useState(multiValueAddettiDa);
    const [selectedAddettiDa, setSelectedAddettiDa] = useState(addettiDaLista);

    const fetchAddettiDa = async (query) => {
        try {
        //setIsLoading(true);
        // Imposta le opzioni nel tuo stato
        const listaAddettiDa : OptionTypeDropDown[] = []

        const addettiDaValue: OptionTypeDropDown = {
            value: "",
            label: ""
        }
        addettiDaValue.value=query
        addettiDaValue.label=query

        if(query.length > 0){
            listaAddettiDa.push(addettiDaValue)
        }
        setSelectedAddettiDa(listaAddettiDa)

        } catch (error) {
        console.error('Errore durante il recupero delle opzioni:', error);
        } finally {
        setIsLoading(false);
        }
    };

    const multiValueAddettiA =  null
    const addettiALista: OptionTypeDropDown [] = []
    const [selectedSingleAddettiA, setSelectedSingleAddettiA] = useState(multiValueAddettiA);
    const [selectedAddettiA, setSelectedAddettiA] = useState(addettiALista);

    const fetchAddettiA = async (query) => {
        try {
        //setIsLoading(true);
        // Imposta le opzioni nel tuo stato
        const listaAddettiA : OptionTypeDropDown[] = []

        const addettiAValue: OptionTypeDropDown = {
            value: "",
            label: ""
        }
        addettiAValue.value=query
        addettiAValue.label=query

        if(query.length > 0){
            listaAddettiA.push(addettiAValue)
        }
        setSelectedAddettiA(listaAddettiA)

        } catch (error) {
        console.error('Errore durante il recupero delle opzioni:', error);
        } finally {
        setIsLoading(false);
        }
    };


    const multiValueRicaviDa =  null
    const ricaviDaLista: OptionTypeDropDown [] = []
    const [selectedSingleRicaviDa, setSelectedSingleRicaviDa] = useState(multiValueRicaviDa);
    const [selectedRicaviDa, setSelectedRicaviDa] = useState(ricaviDaLista);

    const fetchRicaviDa = async (query) => {
        try {
        //setIsLoading(true);
        // Imposta le opzioni nel tuo stato
        const listaRicaviDa : OptionTypeDropDown[] = []

        const ricaviDaValue: OptionTypeDropDown = {
            value: "",
            label: "",
            tipo:""
        }
        const ricaviDaMilaValue: OptionTypeDropDown = {
            value: "",
            label: "",
            tipo:""
        }
        const ricaviDaMilioniValue: OptionTypeDropDown = {
            value: "",
            label: "",
            tipo:""
        }

        const ricaviDaMiliardiValue: OptionTypeDropDown = {
            value: "",
            label: "",
            tipo:""
        }

        ricaviDaValue.value=query//+"S"
        ricaviDaValue.label=query
        ricaviDaValue.tipo="UNITA"

        ricaviDaMilaValue.value=query//+"MI"
        ricaviDaMilaValue.label=query+" (Mila)"
        ricaviDaMilaValue.tipo="MIGLIAIA"

        ricaviDaMilioniValue.value=query//+"MIL"
        ricaviDaMilioniValue.label=query+" (Milioni)"
        ricaviDaMilioniValue.tipo="MILIONI"

        ricaviDaMiliardiValue.value=query//+"MID"
        ricaviDaMiliardiValue.label=query+" (Miliardi)"
        ricaviDaMiliardiValue.tipo="MILIARDI"

        if(query.length > 0){
            listaRicaviDa.push(ricaviDaValue)
            listaRicaviDa.push(ricaviDaMilaValue)
            listaRicaviDa.push(ricaviDaMilioniValue)
            listaRicaviDa.push(ricaviDaMiliardiValue)
        }
        setSelectedRicaviDa(listaRicaviDa)

        } catch (error) {
        console.error('Errore durante il recupero delle opzioni:', error);
        } finally {
        setIsLoading(false);
        }
    };

    const multiValueRicaviA =  null
    const ricaviALista: OptionTypeDropDown [] = []
    const [selectedSingleRicaviA, setSelectedSingleRicaviA] = useState(multiValueRicaviA);
    const [selectedRicaviA, setSelectedRicaviA] = useState(ricaviALista);

    const fetchRicaviA = async (query) => {
        try {
        //setIsLoading(true);
        // Imposta le opzioni nel tuo stato
        const listaRicaviA : OptionTypeDropDown[] = []

        const ricaviAValue: OptionTypeDropDown = {
            value: "",
            label: ""
        }
        const ricaviAMilaValue: OptionTypeDropDown = {
            value: "",
            label: ""
        }
        const ricaviAMilioniValue: OptionTypeDropDown = {
            value: "",
            label: ""
        }

        const ricaviAMiliardiValue: OptionTypeDropDown = {
            value: "",
            label: ""
        }

        ricaviAValue.value=query//+"S"
        ricaviAValue.label=query

        ricaviAMilaValue.value=query//+"MI"
        ricaviAMilaValue.label=query+" (Mila)"

        ricaviAMilioniValue.value=query//+"MIL"
        ricaviAMilioniValue.label=query+" (Milioni)"

        ricaviAMiliardiValue.value=query//+"MID"
        ricaviAMiliardiValue.label=query+" (Miliardi)"

        if(query.length > 0){
            listaRicaviA.push(ricaviAValue)
            listaRicaviA.push(ricaviAMilaValue)
            listaRicaviA.push(ricaviAMilioniValue)
            listaRicaviA.push(ricaviAMiliardiValue)
        }
        setSelectedRicaviA(listaRicaviA)

        } catch (error) {
        console.error('Errore durante il recupero delle opzioni:', error);
        } finally {
        setIsLoading(false);
        }
    };

    const multiValueSuMercatoDa =  null
    const sulMercatoDaLista: OptionTypeDropDown [] = []
    const [selectedSingleSulMercatoDa, setSelectedSingleSulMercatoDa] = useState(multiValueSuMercatoDa);
    const [selectedSulMercatoDa, setSelectedSulMercatoDa] = useState(sulMercatoDaLista);

    const fetchSulMercatoDa = async (query) => {
        try {
        //setIsLoading(true);
        // Imposta le opzioni nel tuo stato
        const listaMercatoDa : OptionTypeDropDown[] = []
        const sulMercatoDaValueMesi: OptionTypeDropDown = {
            value: "",
            label: "",
            tipo: ""
        }
        const sulMercatoDaValueAnno: OptionTypeDropDown = {
            value: "",
            label: "",
            tipo: ""
        }

        sulMercatoDaValueMesi.value=query//+"M"
        sulMercatoDaValueMesi.label=query+" (espresso in Mesi)"
        sulMercatoDaValueMesi.tipo="MESI"

        sulMercatoDaValueAnno.value=query//+"A"
        sulMercatoDaValueAnno.label=query+" (espresso in Anni)"
        sulMercatoDaValueAnno.tipo="ANNI"

        if(query.length > 0){
            listaMercatoDa.push(sulMercatoDaValueMesi)
            listaMercatoDa.push(sulMercatoDaValueAnno)
        }
        setSelectedSulMercatoDa(listaMercatoDa)

        } catch (error) {
        console.error('Errore durante il recupero delle opzioni:', error);
        } finally {
        setIsLoading(false);
        }
    };

    const multiValueSuMercatoAl =  null
    const sulMercatoAlLista: OptionTypeDropDown [] = []
    const [selectedSingleSulMercatoAl, setSelectedSingleSulMercatoAl] = useState(multiValueSuMercatoAl);
    const [selectedSulMercatoAl, setSelectedSulMercatoAl] = useState(sulMercatoAlLista);

    const fetchSulMercatoAl = async (query) => {
        try {
        //setIsLoading(true);
        // Imposta le opzioni nel tuo stato
        const listaMercatoAl : OptionTypeDropDown[] = []
        const sulMercatoAlValueMesi: OptionTypeDropDown = {
            value: "",
            label: "",
            tipo: ""
        }
        const sulMercatoAlValueAnno: OptionTypeDropDown = {
            value: "",
            label: "",
            tipo:""
        }

        sulMercatoAlValueMesi.value=query//+"M"
        sulMercatoAlValueMesi.label=query+" (espresso in Mesi)"
        sulMercatoAlValueMesi.tipo="MESI"

        sulMercatoAlValueAnno.value=query//+"A"
        sulMercatoAlValueAnno.label=query+" (espresso in Anni)"
        sulMercatoAlValueAnno.tipo="ANNI"

        if(query.length > 0){
            listaMercatoAl.push(sulMercatoAlValueMesi)
            listaMercatoAl.push(sulMercatoAlValueAnno)
        }
        setSelectedSulMercatoAl(listaMercatoAl)

        } catch (error) {
        console.error('Errore durante il recupero delle opzioni:', error);
        } finally {
        setIsLoading(false);
        }
    };

    const multiValueArea =  null
    const areaLista: OptionTypeDropDown [] = []
    const [selectedArea, setSelectedArea] = useState(multiValueArea);
    const [selectedAree, setSelectedAreee] = useState(areaLista);

    const [selectedRicercaLibera, setSelectedRicercaLibera] = useState(undefined);

    const handleRicercaLibera = async (dati) => {
        setSelectedRicercaLibera(dati);
    }

    const fetchAreaGeografica = async (query) => {
        try {
        //setIsLoading(true);
        
        // Effettua la chiamata all'API per ottenere le opzioni basate sulla query
        var datiricerca = {
            "area_geografica": query
        }
        const response = await getDatiAreaGeografica(datiricerca);//fetch(`https://api.example.com/suggestions?query=${query}`);
        const data = await response.data.lista;
        // Imposta le opzioni nel tuo stato
        const areageo : OptionTypeDropDown[] = []
        data.map((item, i) => {
            const area: OptionTypeDropDown = {
                value: "",
                label: ""
            }
            area.label = item.name
            area.value = item.id
            areageo.push(area)
        })
        setSelectedAreee(areageo)

        } catch (error) {
        console.error('Errore durante il recupero delle opzioni:', error);
        } finally {
        setIsLoading(false);
        }
    };

    const customNoOptionsMessage = ({ inputValue }) => {
        return `Devi digitare un valore`;
      };
    
      const customNoOptionsMessageAreaGeo = ({ inputValue }) => {
        if(inputValue === '') return `Devi digitare un valore`;
        return `Non ci sono dati per questo valore`;
      };

    const handleSalvaRicerca = () =>{
        console.log(rowDataResponsePreventivo)
        if(rowDataResponsePreventivo == null || rowDataResponsePreventivo.callId == null){

            setMessaggioErrore("La ricerca non può essere salvata in quanto è passato troppo tempo dalla richiesta")
            setErrore(true)

        }else{
            let datiricerca = {
                "numero_anagrafiche": posizioniSelezionate,
                "call_id" : rowDataResponsePreventivo.callId,
                "url_ricerca": urlRicerca,
                "dati_ricera": datiRicercaSalva
    
            }
            console.log("------------------")
            console.log(datiricerca)
            console.log("-------------fffff-----")
            console.log(urlRicerca)
            setDatiRicercaListaSalva(datiricerca)
            setShowSalvaRicerca(true)

        }
        

    }

    const richiestaPosizioni = (datiRicerca) => {
        
        getPosizioniSuccessive(datiRicerca).then((response)=> {

            if (response != null && response.data.message === "OK") {
                //dispatch(setElenco_Imprese_Esteso_EstrazioneList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
                //dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Visura Camerale Richiesta"));
                setIsLoading(true)
                if(response.data.status === 300){
                    rowDataPreventivo.esito = "300";
                    rowDataPreventivo.messaggio = response.data.response;
                }else if(response.data.status === 200){
    
                    const posizioni_disponibili = response.data.anagrafiche;//da prendere da abbonamento
                    const posizioni_preventivo = response.data.response.totalElements;
                    const posizioni_residue = Number(posizioni_disponibili) - Number(posizioni_preventivo);
                    let nessuna_posizione = false;
                    if(Number(posizioni_preventivo) === 0) nessuna_posizione = true;
    
                    if(Number(posizioni_preventivo) === 0 && nessuna_posizione){
                        rowDataPreventivo.esito = "201";
                        rowDataPreventivo.messaggio = "Non hai sufficienti crediti per poter visualizzare questo elenco";
                        rowDataPreventivo.numero_posizioni_preventivo = '0';
                        rowDataPreventivo.numero_posizioni_disponibili = '0';
    
                    // }else if(posizioni_residue < 0 && !nessuna_posizione){
                    //     rowDataPreventivo.esito = "301";
                    //     rowDataPreventivo.messaggio = "Non hai sufficienti crediti per poter visualizzare questo elenco";
                    //     rowDataPreventivo.numero_posizioni_preventivo = posizioni_preventivo;
                    //     rowDataPreventivo.numero_posizioni_disponibili = posizioni_disponibili;
    
                    }else{
                        rowDataPreventivo.esito = "200";
                        rowDataPreventivo.messaggio = "La ricerca ha prodotto n: "+ posizioni_preventivo;
                        rowDataPreventivo.numero_posizioni_preventivo = posizioni_preventivo;
                        rowDataPreventivo.numero_posizioni_disponibili = posizioni_disponibili;
                        rowDataPreventivo.numero_posizioni_residue = posizioni_residue.toString();
                        //setrowDataResponsePreventivo(response.data.response)
                        setPrevPage(response.data.response.prevpage)
                        setListaImpresaRicercaUnica(response.data.response.aziende)
                        setPosizioniSelezionate(posizioni_preventivo)
                        dispatch(setRicercaUnicaDataList({ pageNo: 0, pageSize: 100, list: response.data.response.aziende, totalCount: 100, searchKey: "", nextPage: nextPage, prevPage: response.data.response.prevpage, posizioniRicerca: posizioniSelezionate }));
                        setIsLoading(false);
                    }
                }
    
                setIsLoading(false);
                setRowDataPreventivo(rowDataPreventivo);
                //setshowDatiRichiestaPreventivo(true)
                
              } else {
                //dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Visura non richiedibile"));
                if(response.data.status === 500){
                    rowDataPreventivo.esito = "500";
                    rowDataPreventivo.messaggio = "Abbiamo rilevato un errore generico! Riprovare!";
                }
                setIsLoading(false);
                setRowDataPreventivo(rowDataPreventivo);
                setshowDatiRichiestaPreventivo(true)
              }
            

        
        })

    }

    const handlePosizioniPrecedenti= () => {

        setPrimaPagina(false)
        let datiRicerca = {
            "posizioni_successive": prevPage
        }

        richiestaPosizioni(datiRicerca)
    }


    const handlePosizioniSuccessive = () => {
        setPrimaPagina(true)
        let datiRicerca = {
            "posizioni_successive": nextPage
        }

        richiestaPosizioni(datiRicerca)

    }

    const [csstab1, setCssTab1] = useState("tab-bg tab-dim")
    const [csstab2, setCssTab2] = useState("tab-dim")
    const handleClickTab = (tab:number) =>{
        console.log("click")
        if(tab === 1){
            setSelectedRicercaLibera("")
    
        }
    }

    const handleCancelItemSearch = (element: any, index: number) => {
        console.log(index)
        console.log(element)
        // Filtra l'array per rimuovere l'elemento specifico
        const updatedDatiRicerca = datiRicercaLoad.filter((_, i) => i !== index);
        // Aggiorna lo stato con il nuovo array
        setDatiRicercaLoad(updatedDatiRicerca);
        console.log(listaImpreseRicercaUnica)

    }

    const handleDatiRicerca = (datiRicerca: String[]) =>{
        
        if(datiRicerca === null || datiRicerca === undefined){ return (
            <p>nessun dato</p>
        )}else{
            let split = null
            
            return(
                datiRicerca.map((element, index) => {
                    return(
                        <span key={index} className="box-info-ricerca">{element} 
                        <i 
                        onClick={() => handleCancelItemSearch(element, index)}
                        className="fa fa-times box-info-ricerca-close" 
                        aria-hidden="true"></i>
                        </span>
                    )
                })
            )

        }


    }
  

    return (
        <Col md={12} xl={12} xs={12} className=" mb-4 shadow">
        {isLoading ? <LoadingSpinner /> : isLoading}
            <Row>
                <Card.Body>
                        <Row>
                            <Col md={3} xl={3} xs={12} className="padding-bottom20">
                            <Tabs defaultActiveKey="tab1" id="ricerca-tabs" className="mb-3" onSelect={()=>handleClickTab(1)}>
                                <Tab eventKey="tab1" title="Imposta Filtri">
                                    <Row className="shadow box-ricerca-elenco">
                                        <Col md={12} xl={12} xs={12} className="padding-bottom20">
                                            <p className="title-style-elenco">Ricerca Territoriale</p>
                                        </Col>
                                        <Col md={12} xl={12} xs={12} className="ddl-prov padding-bottom20">
                                            <InputGroup>
                                                <label className="title-campo-style-elenco">Area Geografica</label>
                                                <Select
                                                    //defaultValue={selectedProvincia}
                                                    value={selectedArea}
                                                    isMulti
                                                    name="value"
                                                    options={selectedAree}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Digita Area geografica"
                                                    isSearchable
                                                    onChange={(e) => setSelectedArea(e)}
                                                    isOptionDisabled={() => selectedComune != null && selectedComune?.length >= 5}
                                                    onInputChange={handleInputChangeAreaGeografica}
                                                    noOptionsMessage={customNoOptionsMessageAreaGeo}
                                                />
                                            </InputGroup>
                                        </Col>
                                        <Col md={12} xl={12} xs={12} className="padding-bottom20">
                                            <InputGroup>
                                                <label className="title-campo-style-elenco">Cap</label>
                                                <Select
                                                    //defaultValue={selectedProvincia}
                                                    value={selectedSingleCop}
                                                    onChange={(e) => setSelectedSingleCap(e)}
                                                    isMulti
                                                    name="value"
                                                    options={selectedCap}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Seleziona Cap"
                                                    isOptionDisabled={() => selectedSingleCop != null && selectedSingleCop?.length >= 5}
                                                    onInputChange={handleInputChangeCap}
                                                    noOptionsMessage={customNoOptionsMessage}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                <Row className="shadow box-ricerca-elenco">
                                    <Col md={12} xl={12} xs={12} className="padding-bottom20">
                                        <p className="title-style-elenco">Ricerca per Forma Giuridica e Stato Attività</p>
                                    </Col>
                                    <Col md={12} xl={12} xs={12} className="padding-bottom20">
                                        <InputGroup>
                                            <label className="title-campo-style-elenco">Forma Giuridica</label>
                                            <Select
                                                //defaultValue={selectedProvincia}
                                                value={selectedFormaGiuridica}
                                                onChange={(e) => setFormaGiuridica(e)}
                                                name="value"
                                                options={selectedFormeGiuridiche}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Seleziona Forma Giuridica"
                                                isMulti
                                                //isOptionDisabled={() => selectedStatoAttivita != null && sele?.length >= 3}
                                            />
                                        </InputGroup>    
                                    </Col>
                                    <Col md={12} xl={12} xs={12} className="padding-bottom20">
                                        <InputGroup>
                                            <label className="title-campo-style-elenco">Natura Giuridica</label>
                                            <Select
                                                //defaultValue={selectedProvincia}
                                                value={selectedSingleNatGiu}
                                                onChange={(e) => setSelectedSingleNatGiu(e)}
                                                name="value"
                                                isMulti
                                                options={selectedNatGiu}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Seleziona Natura Giuridica"
                                                onInputChange={handleInputChangeNaturaGiuridica}
                                                noOptionsMessage={customNoOptionsMessage}
                                                
                                            />
                                        </InputGroup>    
                                    </Col>
                                    <Col md={12} xl={12} xs={12} className="ddl-prov padding-bottom20">
                                        <InputGroup>
                                            <label className="title-campo-style-elenco">Solo Ateco Primario</label>
                                            <Select
                                                //defaultValue={selectedProvincia}
                                                value={selectedSingleAtecoP}
                                                onChange={(e) => setSelectedSingleAtecoP(e)}
                                                name="value"
                                                options={selectedAtecoP}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Scegli Tipologia Ateco"
                                                isOptionDisabled={() => selectedSingleAtecoP != null && selectedSingleAtecoP?.length >= 1}
                                                onInputChange={handleInputChangeAtecoP}
                                                noOptionsMessage={customNoOptionsMessage}
                                                isMulti
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col md={12} xl={12} xs={12} className="ddl-prov padding-bottom20">
                                        <InputGroup>
                                            <label className="title-campo-style-elenco">Settore Aziendale - ATECO</label>
                                            <Select
                                                //defaultValue={selectedProvincia}
                                                value={selectedSingleAteco}
                                                onChange={(e) => setSelectedSingleAteco(e)}
                                                isMulti
                                                name="value"
                                                options={selectedAteco}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Digita settore"
                                                isOptionDisabled={() => selectedSingleAteco != null && selectedSingleAteco?.length >= 5}
                                                onInputChange={handleInputChangeAteco}
                                                noOptionsMessage={customNoOptionsMessage}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col md={12} xl={12} xs={12} className="padding-bottom20">
                                        <InputGroup>
                                            <label className="title-campo-style-elenco">Stato Attività</label>
                                            <Select
                                                //defaultValue={selectedProvincia}
                                                value={selectedStatoAttivita}
                                                onChange={(e) => setStatoAttivita(e)}
                                                name="value"
                                                options={selectedStatiAttivita}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Seleziona Stato Attività"
                                                isMulti
                                                isOptionDisabled={() => selectedStatoAttivita != null && selectedStatoAttivita?.length >= 1}
                                                //onInputChange={handleInputChangeStatoAttivita}
                                            />
                                        </InputGroup>    
                                    </Col>
                                    <Col md={12} xl={12} xs={12} className="padding-bottom20">
                                        <InputGroup>
                                            <label className="title-campo-style-elenco">Tipo Sede</label>
                                            <Select
                                                //defaultValue={selectedProvincia}
                                                value={selectedSingleTipoSede}
                                                onChange={(e) => setSelectedSingleTipoSede(e)}
                                                name="value"
                                                options={selectedTipoSede}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Seleziona Tipo Sede"
                                                isMulti
                                                isOptionDisabled={() => selectedSingleTipoSede != null && selectedSingleTipoSede?.length >= 1}
                                                //onInputChange={handleInputChangeStatoAttivita}
                                            />
                                        </InputGroup>    
                                    </Col>
                                </Row>
                                <Row className="shadow box-ricerca-elenco">
                                    <Col md={12} xl={12} xs={12} className="padding-bottom20">
                                            <p className="title-style-elenco">Ricerca su dati Bilancio e Addetti</p>
                                    </Col>
                                    <Col md={12} xl={12} xs={12} className="ddl-prov padding-bottom20">
                                        <InputGroup>
                                            <label className="title-campo-style-elenco">Ricavi Da (€)</label>
                                            <Select
                                                //defaultValue={selectedProvincia}
                                                value={selectedSingleRicaviDa}
                                                onChange={(e) => setSelectedSingleRicaviDa(e)}
                                                name="value"
                                                options={selectedRicaviDa}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Digitare ricavo"
                                                onInputChange={handleInputChangeRicaviDa}
                                                noOptionsMessage={customNoOptionsMessage}
                                                isMulti
                                                
                                                isOptionDisabled={() => selectedSingleRicaviDa != null && selectedSingleRicaviDa?.length >=1}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col md={12} xl={12} xs={12} className="ddl-prov padding-bottom20">
                                        <InputGroup>
                                            <label className="title-campo-style-elenco">Ricavi A (€)</label>
                                            <Select
                                                //defaultValue={selectedProvincia}
                                                value={selectedSingleRicaviA}
                                                onChange={(e) => setSelectedSingleRicaviA(e)}
                                                name="value"
                                                options={selectedRicaviA}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Digitare ricavo"
                                                onInputChange={handleInputChangeRicaviA}
                                                noOptionsMessage={customNoOptionsMessage}
                                                isMulti
                                                isOptionDisabled={() => selectedSingleRicaviA != null && selectedSingleRicaviA?.length >=1}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col md={12} xl={12} xs={12} className="ddl-prov padding-bottom20">
                                        <InputGroup>
                                            <label className="title-campo-style-elenco">Numero Addetti Da</label>
                                            <Select
                                                //defaultValue={selectedProvincia}
                                                value={selectedSingleAddettiDa}
                                                onChange={(e) => setSelectedSingleAddettiDa(e)}
                                                name="value"
                                                options={selectedAddettiDa}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Digitare numero addetti"
                                                onInputChange={handleInputChangeAddettiDa}
                                                noOptionsMessage={customNoOptionsMessage}
                                                isMulti
                                                isOptionDisabled={() => selectedSingleAddettiDa != null && selectedSingleAddettiDa?.length >=1}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col md={12} xl={12} xs={12} className="ddl-prov padding-bottom20">
                                        <InputGroup>
                                            <label className="title-campo-style-elenco">Numero Addetti A</label>
                                            <Select
                                                //defaultValue={selectedProvincia}
                                                value={selectedSingleAddettiA}
                                                onChange={(e) => setSelectedSingleAddettiA(e)}
                                                name="value"
                                                options={selectedAddettiA}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Digitare numero addetti"
                                                onInputChange={handleInputChangeAddettiA}
                                                noOptionsMessage={customNoOptionsMessage}
                                                isMulti
                                                isOptionDisabled={() => selectedAddettiA != null && selectedAddettiA?.length >1}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row className="shadow box-ricerca-elenco">
                                    <Col md={12} xl={12} xs={12} className="padding-bottom20">
                                            <p className="title-style-elenco">Ricerca Temporale</p>
                                    </Col>
                                    <Col md={12} xl={12} xs={12} className="ddl-prov padding-bottom20">
                                        <InputGroup>
                                            <label className="title-campo-style-elenco">Sul Mercato Almeno Dal</label>
                                            <Select
                                                //defaultValue={selectedProvincia}
                                                value={selectedSingleSulMercatoDa}
                                                onChange={(e) => setSelectedSingleSulMercatoDa(e)}
                                                name="value"
                                                options={selectedSulMercatoDa}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Seleziona Sul Mercato Dal"
                                                onInputChange={handleInputChangeSulMercatoDa}
                                                noOptionsMessage={customNoOptionsMessage}
                                                isMulti
                                                isOptionDisabled={() => selectedSingleSulMercatoDa != null && selectedSingleSulMercatoDa?.length >=1}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col md={12} xl={12} xs={12} className="ddl-prov padding-bottom20">
                                        <InputGroup>
                                            <label className="title-campo-style-elenco">Sul Mercato Non Più Da</label>
                                            <Select
                                                //defaultValue={selectedProvincia}
                                                value={selectedSingleSulMercatoAl}
                                                onChange={(e) => setSelectedSingleSulMercatoAl(e)}
                                                name="value"
                                                options={selectedSulMercatoAl}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Seleziona Sul Mercato Al"
                                                onInputChange={handleInputChangeSulMercatoAl}
                                                noOptionsMessage={customNoOptionsMessage}
                                                isMulti
                                                isOptionDisabled={() => selectedSingleSulMercatoAl != null && selectedSingleSulMercatoAl?.length >=1}
                                            />
                                        </InputGroup>
                                    </Col>
                                    
                                    <Col md={12} xl={12} xs={12}>
                                        <InputGroup>
                                            <Button  variant="dark" className="component-maxwidth heigth-4" id="button-search" onClick={handleSearchEstesa}>
                                                Cerca
                                            </Button>
                                        </InputGroup>
                                    </Col>
                                    </Row>

                                </Tab>
                                <Tab eventKey="tab2" title="Ricerca Unica">
                                    <Row className="shadow box-ricerca-elenco">
                                        <Col md={12} xl={12} xs={12} className="padding-bottom20">
                                            <p className="title-style-elenco">Ricerca Unica</p>
                                        </Col>
                                        <Col md={12} xl={12} xs={12} className="ddl-prov padding-bottom20">
                                            <InputGroup>
                                                <Form.Control
                                                    placeholder="Inserisci valore"
                                                    aria-label="Search"
                                                    aria-describedby="basic-search"
                                                    onChange={(e) => handleRicercaLibera(e.target.value)}
                                                    className="heigth-4"
                                                    disabled={disableRadioDen}
                                                    value={selectedRicercaLibera}
                                                />
                                                <label className="color-gray-pg">
                                                    Ricerca per codice fiscale, partita iva, denominazione, cciaa e nrea (e.g. LU167765)
                                                </label>
                                            </InputGroup>
                                        </Col>
                                        <Col md={12} xl={12} xs={12}>
                                        <InputGroup>
                                            <Button  variant="success" className="component-maxwidth heigth-4" id="button-search" onClick={handleSearchEstesa}>
                                                Cerca
                                            </Button>
                                        </InputGroup>
                                    </Col>
                                    </Row>
                                </Tab>


                            </Tabs>
                            {/* secondo tab*/}
                           
                            {/* secondo tab*/}
                        


                                    
                            </Col>
                            <Col md={9} xl={9} xs={12} className="padding-bottom20">

                            <div>
                            {listaImpreseRicercaUnica == null || listaImpreseRicercaUnica == undefined || listaImpreseRicercaUnica.length == 0 &&
                                <div className="row shadow box-ricerca-elenco shadow-margin-zero">
                                    <div className="col-sm-12 box-message-padding box-message-dim">
                                        <p className="box-message-general">Imposta i filtri di ricerca per individuare un elenco di aziende che ti interessano, 
                                            oppure usa la ricerca singola per trovarne una specifica.</p>
                                        <p className="box-message-general-info padding-t-50">Crediti ricerche disponibili: <span className="box-message-span">10</span></p>
                                        <p className="box-message-general-info">Ricarica crediti dalla sezione <span className="box-message-span-s">Shop</span></p>
                                    </div>
                                </div>
}
                            </div>    
                        {listaImpreseRicercaUnica != null && listaImpreseRicercaUnica != undefined && listaImpreseRicercaUnica.length > 0 &&
                        <>
                        <div className="row shadow box-ricerca-elenco shadow-margin-zero">
                            <div className="col-sm-2"><p className="color-blue icerca-effettuata"><strong>Ricerca effettuata per:</strong></p></div>
                            <div className="col-sm-8">
                                {handleDatiRicerca(datiRicercaLoad)}
                            </div>
                            <div className="col-sm-2 align-right">
                                <Button 
                                className="button-bg-gray color-blue btn-cancel"
                                disabled={listaImpreseRicercaUnica === null || (listaImpreseRicercaUnica != null && listaImpreseRicercaUnica.length === 0)} 
                                onClick={() => handleSalvaRicerca()}>Cancella</Button>
                            </div>

                            {listaImpreseRicercaUnica == null ?
                            <div className="col-sm-4 center-block-custom color-text-gray">
                            Risultati trovati: <strong>-</strong>
                            </div>
                            :
                            <div className="col-sm-4 center-block-custom color-text-gray">
                                Risultati trovati: <strong>{posizioniSelezionate}</strong>
                            </div>
                            }
                            
                            {listaImpreseRicercaUnica == null ?
                            <div className="col-sm-4 center-block-custom color-text-gray">
                            Consultabili online: <strong>-</strong>
                            </div>
                            :
                            <div className="col-sm-4 center-block-custom color-text-gray">
                                Consultabili online: <strong>{posizioniSelezionate > 100 ? "100" : posizioniSelezionate}</strong>
                            </div>
                            }
                            <div className="col-sm-4 salva-ricerca text-align-right">
                                
                                    <Button variant="success" className="btn-success-my btn-blue" 
                                    disabled={listaImpreseRicercaUnica === null || (listaImpreseRicercaUnica != null && listaImpreseRicercaUnica.length === 0)} 
                                    onClick={() => handleSalvaRicerca()}>Salva Ricerca</Button>
                                
                                    
                            </div>

                        </div>


                        {/* <div className="row shadow box-ricerca-elenco shadow-margin-zero">
                            <div className="col-sm-12">
                                <h5 className="color-icon-green font-size-xlg"><strong>Risultato Ricerca Aziende</strong></h5>
                            </div>
                            {listaImpreseRicercaUnica == null ?
                            <div className="col-sm-4 center-block-custom font-size-xlg">
                            Totale Selezionate: <strong>-</strong>
                            </div>
                            :
                            <div className="col-sm-4 center-block-custom font-size-xlg">
                                Totale Selezionate: <strong>{posizioniSelezionate}</strong>
                            </div>
                            }
                            
                            {listaImpreseRicercaUnica == null ?
                            <div className="col-sm-4 center-block-custom font-size-xlg">
                            Consultabili Online: <strong>-</strong>
                            </div>
                            :
                            <div className="col-sm-4 center-block-custom font-size-xlg">
                                Consultabili Online: <strong>{posizioniSelezionate > 100 ? "100" : posizioniSelezionate}</strong>
                            </div>
                            }
                            
                            <div className="col-sm-4 center-block-custom align-right">
                                    <Button variant="success" className="" disabled={listaImpreseRicercaUnica === null || (listaImpreseRicercaUnica != null && listaImpreseRicercaUnica.length === 0)} onClick={() => handleSalvaRicerca()}><i className="fa-solid fa-cloud-download"></i> Salva</Button>
                            </div>
                        </div> */}
                        <div className="row">
                        {posizioniSelezionate > 0 &&
                                <div className="col-sm-12 ">
                                    {!primaPagina && posizioniSelezionate > 50 && 
                                    <p>Risultati da 1 a 50 <Link className="text-deco-none posizioni-lista" to="" onClick={handlePosizioniSuccessive}>Posizioni Successive <i className="fa fa-long-arrow-right decoration-none"></i></Link></p>}
                                    {!primaPagina && posizioniSelezionate <= 50 &&
                                    <p>Risultati da 1 a {posizioniSelezionate}</p>}
                                    {primaPagina && ricercaUnica.list.length === 50 &&
                                    <p>Risultati da 51 a 100 <Link className="text-deco-none posizioni-lista" to="" onClick={handlePosizioniPrecedenti}> <i className="fa fa-long-arrow-left decoration-none"></i> Posizioni Precedenti</Link></p>}
                                    {primaPagina && ricercaUnica.list.length < 50 &&
                                    <p>Risultati da 51 a {ricercaUnica.list.length + 50} <Link className="text-deco-none posizioni-lista" to="" onClick={handlePosizioniPrecedenti}><i className="fa fa-long-arrow-left decoration-none"></i> Posizioni Precedenti</Link></p>}
                                </div>
                        }
                        </div>
                        </>
                        
                        }    
                        {listaImpreseRicercaUnica != null && listaImpreseRicercaUnica.map((item, i) => {
                        return (<div>
                            <div className="row shadow box-ricerca-elenco shadow-margin-zero">
                                <div className="row component-maxwidth border-title padding-t-15">
                                <div className="col-sm-8 icon-green ">
                                    <a onClick={() => richiestaDettaglioAzienda(item.id_soggetto)}><h5 className="color-blue cursor-pointer"><strong>{item.ragione_sociale}</strong></h5></a>
                                </div>
                                <div className="col-sm-4">
                                    <div className="row padding-t-5">
                                        
                                        <>
                                        <div className="col-sm-6 button-vis-bil padding-zero"
                                            onClick={() => handleRowConfermaRichiestaVisuraClickLista(item, ID_VISURA)}>
                                                <i className="fa fa-file-text margin-r-5"></i> 
                                                <span className="margin-r-5"><strong>Visura Camerale</strong></span>
                                        </div>
                                        <span className="col-sm-6 button-vis-bil padding-zero">
                                            {handleRowEnableBilancio(item, ID_BILANCIO)}
                                        </span>
                                        </>
                                        
                                        
                                        
                                    </div>
                                </div> 
                                </div>
                                <div className="row">  
                                <div className="col-sm-12">
                                    <div className="row">
                                        {/* <div className="col-sm-12">
                                            <h5><strong>{item.ragione_sociale}</strong></h5>
                                        </div> */}
                                        <div className="col-sm-6 box-ris-etichetta">
                                            Sede: <p><strong className="box-ris-dato">{valoreDato(item.indirizzo)}</strong></p>
                                        </div>
                                        {fatturatoInfo(item.fatturato, item.trend_ricavi)}
                                        <div className="col-sm-6 box-ris-etichetta">Codice Fiscale: <p><strong className="box-ris-dato">{valoreDato(item.codice_fiscale)}</strong></p></div>
                                        <div className="col-sm-6 box-ris-etichetta">Partita Iva: <p><strong className="box-ris-dato">{valoreDato(item.partita_iva)}</strong></p></div>
                                        <div className="col-sm-6 box-ris-etichetta">Descrizione Settore: <p><strong className="box-ris-dato">{valoreDato(item.settore)}</strong></p></div>
                                        <div className="col-sm-6 box-ris-etichetta">Numero Dipendenti: <p><strong className="box-ris-dato">{valoreDato(item.numero_dipendenti)}</strong></p></div>
                                        {item.settore_attivita == null &&
                                            <div className="col-sm-6 box-ris-etichetta">Settore Ateco: <p><strong className="box-ris-dato">{valoreDato(item.settore_attivita)}</strong></p></div>
                                        }
                                        { item.settore_attivita != null && item.settore_attivita.map((settore, i) => {
                                            return(<div className="col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-sm-12 box-ris-etichetta">
                                                            Settore Ateco: <p><strong className="box-ris-dato">{settore.codice_ateco} - {settore.descrizione_ateco}</strong></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            )
                                        } 
                                        <div className="col-sm-6 box-ris-etichetta">Sito Web: <p><strong className="box-ris-dato">{valoreDato(item.sito_web)}</strong></p></div>
                                        {item.telefono != null &&
                                            <div className="col-sm-6 box-ris-etichetta">Telefono: <p><strong> className="box-ris-dato"{item.telefono}</strong></p></div>
                                        }
                                        {item.email != null &&
                                            <div className="col-sm-6 box-ris-etichetta">Email: <p><strong className="box-ris-dato">{item.email}</strong></p></div>
                                        }
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>)
                    })}
                    
                            </Col>

                        </Row>
                                    
                            
                        
                        
                        
                       
                        <ConfirmationDatiObbligatoriElenco 
                            buttonNegative="Continua" 
                            buttonPositive="Continua" 
                            title="Ricerca Imprese Estesa" 
                            show={ricercaImpresaEstesa} 
                            body={"Selezionare almeno una Provincia!"} 
                            onNegative={() => setRicercaImpresaEstesa(false)} 
                            />
                        <ConfirmationPreventivoElenco
                            buttonNegative="Annulla" 
                            buttonPositive="Richiedi" 
                            title="Ricerca Imprese Estesa" 
                            show={showDatiRichiestaPreventivo} 
                            body={rowDataPreventivo} 
                            onNegative={() => setshowDatiRichiestaPreventivo(false)}
                            onPositive={handleServerRichestaEleneco}  
                        
                        />
                        <ConfirmationDifferita
                            buttonNegative="Chiudi" 
                            buttonPositive="Chiudi" 
                            title="Richistea Elenco Imprese" 
                            show={showConfermaRichiestaElenco} 
                            body={rowResponseDataRispostaElenco} 
                            onNegative={() => setshowConfermaRichiestaElenco(false)}
                            onPositive={handleServerRedirectDifferita}  
                        />
                              <ModalConfirmGenerico 
                                buttonNegative="Chiudi"
                                buttonPositive="Chiudi"
                                title="Richiesta Documento" 
                                show={showConfermaRichiesta} 
                                body={showMessaggio}
                                onNegative={() => setConfermaRichiesta(false)}
                                onPositive={handleServerRedirectDifferita} 
                        />
                        <ConfirmationBilancio 
                        buttonNegative="Annulla" 
                        buttonPositive="Continua" 
                        title="Richiesta documento" 
                        show={showRichiestaBilancio}
                        body={"Sei sicuro di voler richiedere il bilancio di questa società?"} 
                        onNegative={() => setRichiestaBilancio(false)} 
                        onPositive={handleServerBilancio} />

                        <ConfirmationSalvaRicerca 
                        buttonNegative="Annulla" 
                        buttonPositive="Continua" 
                        title="Nome ricerca da salvare" 
                        show={showSalvaRicerca} 
                        body={datiRicercaListaSalva} 
                        onNegative={() => setShowSalvaRicerca(false)} 
                        />
                        <ModalErrorGenerico buttonNegative="Chiudi" 
                                    buttonPositive="Chiudi" 
                                    title="Ricerca Impresa" 
                                    show={showErrore} 
                                    body={showMessaggioErrore} //"La richiesta del Bilancio non è stata effettuata con successo. Riprovare e se il problema persiste contattare il servizio assistenza. Grazie"
                                    onNegative={() => setErrore(false)} 
                                    onPositive={() => setErrore(false)}
                                />
                        <ConfirmationControlloDisponibilita
                            buttonNegative="Chiudi" 
                            buttonPositive="Conferma" 
                            title="Richiesta Dettaglio" 
                            show={showDisponibilita} 
                            body={responseDisponibilita} 
                            onNegative={() => setShowDisponibilita(false)}
                            onPositive={() => confermaRichiestaDettaglioAzienda(idDettaglioAzienda)}  
                        />
                        <ConfirmationControlloDisponibilita
                            buttonNegative="Chiudi" 
                            buttonPositive="Conferma" 
                            title="Richiesta Visura Camerale" 
                            show={showDisponibilitaVisura} 
                            body={responseDisponibilita} 
                            onNegative={() => setShowDisponibilitaVisura(false)}
                            onPositive={() => handleServerVisuraLista(datiSocietaRichiestaCamerale)}  
                        />
                        <ConfirmationControlloDisponibilita
                            buttonNegative="Chiudi" 
                            buttonPositive="Conferma" 
                            title="Richiesta Ultimo Bilancio" 
                            show={showDisponibilitaBilancio} 
                            body={responseDisponibilita} 
                            onNegative={() => setShowDisponibilitaBilancio(false)}
                            onPositive={() => handleServerBilancioLista(datiSocietaRichiestaBilancio)}  
                        />
                    {/* </Tab>
                    </Tabs>   */}
                </Card.Body>
            </Row>
        </Col>
        
    );
};

