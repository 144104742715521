import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import img_commercialista from "../../asset/testimonial_uno.jpeg"
import img_consulente from "../../asset/testimonial_due.jpeg"
import img_webmarketing from "../../asset/testimonial_tre.jpeg"

const Testimonial_responsive = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const testimonials = [
    {
      name: 'Marco',
      role: 'Commercialista',
      image: '/asset/testimonial_uno.jpeg',
      quote: '"Sto seguendo un nuovo cliente, mi interesserebbe conoscere la media del capitale versato negli ultimi anni da una particolare categoria di aziende."'
    },
    {
      name: 'Piero',
      role: 'Consulente sulla sicurezza aziendale',
      image: '/path/to/img_consulente.jpg',
      quote: '"Sto seguendo un nuovo cliente, mi interesserebbe conoscere la media del capitale versato negli ultimi anni da una particolare categoria di aziende."'
    },
    {
      name: 'Arianna',
      role: 'Proprietaria di un\'agenzia di web marketing',
      image: '/path/to/img_webmarketing.jpg',
      quote: '"Sto seguendo un nuovo cliente, mi interesserebbe conoscere la media del capitale versato negli ultimi anni da una particolare categoria di aziende."'
    }
  ];

  const nextSlide = () => {
    setActiveIndex((prevIndex) => 
      (prevIndex + 1) % testimonials.length
    );
  };

  const prevSlide = () => {
    setActiveIndex((prevIndex) => 
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  return (
    // <div className="relative w-full max-w-md mx-auto">
    //   <div className="overflow-hidden relative">
    //     {testimonials.map((testimonial, index) => (
    //       <div 
    //         key={index}
    //         className={`transition-opacity duration-500 ease-in-out ${
    //           index === activeIndex ? 'opacity-100' : 'opacity-0 absolute top-0 left-0 w-full'
    //         }`}
    //       >
    //         <div className="text-center">
    //           <img
    //             src={testimonial.image}
    //             alt={testimonial.name}
    //             className="mx-auto mb-4 w-32 h-32 rounded-full object-cover"
    //           />
    //           <p className="text-xl font-bold">
    //             {testimonial.name} <br />
    //             <span className="text-sm text-gray-600">{testimonial.role}</span>
    //           </p>
    //           <p className="mt-4 text-gray-700 italic">
    //             {testimonial.quote}
    //           </p>
    //         </div>
    //       </div>
    //     ))}
    //   </div>

    //   {/* Navigation Buttons */}
    //   <button 
    //     onClick={prevSlide} 
    //     className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-gray-200 p-2 rounded-full"
    //   >
    //     Prev
    //   </button>
    //   <button 
    //     onClick={nextSlide} 
    //     className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-gray-200 p-2 rounded-full"
    //   >
    //    Next
    //   </button>

    //   {/* Dots Indicator */}
    //   <div className="flex justify-center mt-4">
    //     {testimonials.map((_, index) => (
    //       <button
    //         key={index}
    //         onClick={() => setActiveIndex(index)}
    //         className={`h-2 w-2 mx-1 rounded-full ${
    //           index === activeIndex ? 'bg-blue-500' : 'bg-gray-300'
    //         }`}
    //       />
    //     ))}
    //   </div>
    // </div>
    <Carousel className='box-testimonial'>
      {/* First Testimonial */}
      <Carousel.Item>
        <div className="text-center">
          <img
            src={img_commercialista}
            alt="Commercialista"
            className="box-testimonial-img-1 box-testimonial-pos-img-text"
          />
          <p className="box-testimonial-title">
            Marco <br />
            <span className="box-testimonial-subtitle">Commercialista</span>
          </p>
          <p className="box-testimonial-text box-color-black">
            "Sto seguendo un nuovo cliente, mi interesserebbe conoscere la media del capitale versato 
            negli ultimi anni da una particolare categoria di aziende."
          </p>
        </div>
      </Carousel.Item>

      {/* Second Testimonial */}
      <Carousel.Item>
        <div className="text-center">
          <img
            src={img_consulente}
            alt="Consulente"
            className="box-testimonial-img-1 box-testimonial-pos-img-text"
          />
          <p className="box-testimonial-title">
            Piero <br />
            <span className="box-testimonial-subtitle">Consulente sulla sicurezza aziendale</span>
          </p>
          <p className="box-testimonial-text box-color-black">
            "Sto seguendo un nuovo cliente, mi interesserebbe conoscere la media del capitale versato 
            negli ultimi anni da una particolare categoria di aziende."
          </p>
        </div>
      </Carousel.Item>

      {/* Third Testimonial */}
      <Carousel.Item>
        <div className="text-center">
          <img
            src={img_webmarketing}
            alt="Web Marketing"
            className="box-testimonial-img-1 box-testimonial-pos-img-text"
          />
          <p className="box-testimonial-title">
            Arianna <br />
            <span className="box-testimonial-subtitle">Proprietaria di un'agenzia di web marketing</span>
          </p>
          <p className="box-testimonial-text box-color-black">
            "Sto seguendo un nuovo cliente, mi interesserebbe conoscere la media del capitale versato 
            negli ultimi anni da una particolare categoria di aziende."
          </p>
        </div>
      </Carousel.Item>
    </Carousel>
  );
};

export default Testimonial_responsive;