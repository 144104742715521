import React, { useEffect, useRef, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { setError } from "redux/slices/auth";
import { addWs_Login, registration } from "services/ws_loginService";
import { Constant } from "template/Constant";
import md5 from 'crypto-js/md5';
import { sendMailRegistrazione } from "services/mailService";
import Layout from "template";
import LoadingSpinner from "components/LoadingSpinner";


const Register: React.FC = () => {

    const emailRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const pwdRef = useRef(null);
    const rpwdRef = useRef(null);
    const nomeRef = useRef(null);
    const cognomeRef = useRef(null);
    const nomeAziendaRef = useRef(null);
    const telRef = useRef(null);
    const pivaRef = useRef(null);

    const[passwordNoUguali, setPasswordNoUguali] = useState(null);
    const[registrato, setRegistrato] = useState(false);
    const[error, setError] = useState(false);
    const[messaggioErrore , setMessaggioErrore] = useState(undefined)


    function isValidEmail(email) {
        // Espressione regolare per la convalida di email
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      
        // Restituisce true se la stringa corrisponde all'espressione regolare
        return regex.test(email);
      }

    //   function isValidItalianID(id) {
    //     // Controllo lunghezza
    //     if (id.length !== 16 && id.length !== 11) {
    //       return false;
    //     }
      
    //     // Controllo se la stringa è composta solo da numeri
    //     const regex = /^[0-9]+$/;
    //     if (!regex.test(id)) {
    //       return false;
    //     }
      
    //     // Controllo partita IVA
    //     if (id.length === 11) {
    //       return isValidVAT(id);
    //     }
      
    //     // Controllo codice fiscale
    //     if (id.length === 16) {
    //       return isValidFiscalCode(id);
    //     }
      
    //     return false;
    //   }
      
    //   function isValidVAT(vatNumber) {
    //     // Algoritmo di controllo per partita IVA
    //     // ... (implementare l'algoritmo)
    //     const regex = /^[0-9]+$/;
    //     if (!regex.test(vatNumber)) {
    //         return false;
    //       }
      
    //     return true; // Sostituire con il risultato dell'algoritmo
    //   }
      
    //   function isValidFiscalCode(fiscalCode) {
    //     // Algoritmo di controllo per codice fiscale
    //     // ... (implementare l'algoritmo)
      
    //     return true; // Sostituire con il risultato dell'algoritmo
    //   }
    function isValidItalianID(id) {
        console.log(id.length)
        // Controllo lunghezza
        if (id.length !== 16 && id.length !== 11) {
            return false;
        }
      
        // // Controllo se la stringa è composta solo da numeri
        // const regex = /^[0-9]+$/;
        // if (!regex.test(id)) {
        //     return false;
        // }
      
        // Controllo partita IVA
        if (id.length === 11) {
            return isValidVAT(id);
        }
      
        // Controllo codice fiscale
        if (id.length === 16) {
            console.log("false___")
            return isValidFiscalCode(id);
        }
      
        return false;
    }
      
    function isValidVAT(vatNumber) {
        // Algoritmo di controllo per partita IVA (algoritmo di Luhn modificato)
        if (vatNumber.length !== 11) {
            return false;
        }
      
        let sum = 0;
        for (let i = 0; i < 10; i++) {
            let digit = parseInt(vatNumber[i]);
            if (i % 2 === 0) {
                // Per le posizioni pari (0-based index), moltiplico per 2
                digit *= 2;
                // Se il risultato è maggiore di 9, sottraggo 9
                digit = digit > 9 ? digit - 9 : digit;
            }
            sum += digit;
        }
      
        // Calcolo del check digit
        let checkDigit = (10 - (sum % 10)) % 10;
        return checkDigit === parseInt(vatNumber[10]);
    }
      
    function isValidFiscalCode(fiscalCode) {
        // Controllo lunghezza e formato base
        if (fiscalCode.length !== 16) {
            console.log("false 1")
            return false;
        }
      
        // Controllo caratteri (primi 6 lettere, poi numeri, poi lettera e numeri)
        const regex = /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/;
        if (!regex.test(fiscalCode)) {
            console.log("false")
            return false;
        }
      
        // Tabelle di conversione per il controllo
        const evenMap = {
            '0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9,
            'A': 0, 'B': 1, 'C': 2, 'D': 3, 'E': 4, 'F': 5, 'G': 6, 'H': 7, 'I': 8, 'J': 9,
            'K': 10, 'L': 11, 'M': 12, 'N': 13, 'O': 14, 'P': 15, 'Q': 16, 'R': 17, 'S': 18,
            'T': 19, 'U': 20, 'V': 21, 'W': 22, 'X': 23, 'Y': 24, 'Z': 25
        };
      
        const oddMap = {
            '0': 1, '1': 0, '2': 5, '3': 7, '4': 9, '5': 13, '6': 15, '7': 17, '8': 19, '9': 21,
            'A': 1, 'B': 0, 'C': 5, 'D': 7, 'E': 9, 'F': 13, 'G': 15, 'H': 17, 'I': 19, 'J': 21,
            'K': 2, 'L': 4, 'M': 18, 'N': 20, 'O': 11, 'P': 3, 'Q': 6, 'R': 8, 'S': 12, 'T': 14,
            'U': 16, 'V': 10, 'W': 22, 'X': 25, 'Y': 24, 'Z': 23
        };
      
        let sum = 0;
        for (let i = 0; i < 15; i++) {
            const char = fiscalCode[i];
            sum += (i % 2 === 0) ? oddMap[char] : evenMap[char];
        }
      
        // Calcolo del carattere di controllo
        const controlChar = String.fromCharCode(65 + (sum % 26));
        console.log(controlChar)
        console.log(fiscalCode[15])
        return controlChar === fiscalCode[15];
    }

      function isValidPhoneNumberWithoutPlus39(phoneNumber) {
        // Espressione regolare per la convalida di numeri di telefono italiani senza +39
        const regex = /^((3[0-9]{2}|0[1-9]{2}|[1-9]{2}))( )?([0-9]{3})( )?([0-9]{4})$/;
      
        // Restituisce true se la stringa corrisponde all'espressione regolare
        return regex.test(phoneNumber);
      }

      function isValidPassword(password) {
        // Espressione regolare per la convalida della password
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
      
        // Restituisce true se la stringa corrisponde all'espressione regolare
        return regex.test(password);
      }

    const handleRegistra = () =>{

        setError(false)
        setIsLoading(true);
        setMessaggioErrore([])
        // console.log(pwdRef.current.value)
        // console.log(rpwdRef.current.value)

        // if(pwdRef.current.value != rpwdRef.current.value){
        //     setPasswordNoUguali(false)
        //     setMessaggioErrore("Attenzione password e ripeti password non coincidono")
        //     setError(true)

        // }else{
            // const hashedPassword = md5(pwdRef.current.value).toString(16);

            // console.log(hashedPassword)

            let dati = {
                "username": emailRef.current.value,
                "nome_referente": nomeRef.current.value,
                "cognome_referente": cognomeRef.current.value,
                "nome_azienda": nomeAziendaRef.current.value,
                "telefono": telRef.current.value,
                "partita_iva": pivaRef.current.value,
                //"password": pwdRef.current.value,
                "email":emailRef.current.value
            }



            if(isValidEmail(emailRef.current.value) 
            && isValidItalianID(pivaRef.current.value) 
        && isValidPhoneNumberWithoutPlus39(telRef.current.value)
        && nomeRef.current.value.length >2 && cognomeRef.current.value.length >2 && nomeAziendaRef.current.value.length >2 ){

            registration(dati).then((response) => {
                console.log(response)
                if(response.status === 200){
                    let dati = {
                        "mail": emailRef.current.value
                    }
                    //sendMailRegistrazione(dati).then((response) =>{
                        
                        setRegistrato(true)
                        setIsLoading(false);
                    //}
                    //)
                }
                if(response.status === 203){
                    let mess = []
                    mess.push("Controlla i dati inseriti perchè non è possibile registrarti al servizio")
                    setIsLoading(false);
                    setMessaggioErrore(mess)
                    setError(true)
                }

            })

        }else{
            let mess = []
            if(!isValidEmail(emailRef.current.value)) mess.push("devi inserire una email valida")
            if(!isValidItalianID(pivaRef.current.value) ) mess.push("devi inserire una partita iva o codice fiscale valido")
            if(!isValidPhoneNumberWithoutPlus39(telRef.current.value)) mess.push("devi inserire un numero telefonico valido")
            if(nomeRef.current.value.length < 3) mess.push("Nome deve essere valido")
            if(cognomeRef.current.value.length < 3) mess.push("Cognome deve essere valido")
            if(nomeAziendaRef.current.value.length < 3) mess.push("Nome Azienda deve essere valido")


            setMessaggioErrore(mess)
            setIsLoading(false);
            setError(true)
        }
    }


    useEffect(() => {
        document.body.classList.toggle('bg-gradient-primary', true);
    }, [])
    function dispatch(arg0: any): void {
        throw new Error("Function not implemented.");
    }

    return (
        <>
            {isLoading ? <LoadingSpinner /> : isLoading}
        <div className="container">

        <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
                <div className="row">
                    <div className="col-lg-5 d-none d-lg-block bg-login-image" style={{ height: 280, margin : 'auto'}}></div>
                    <div className="col-lg-7">
                        <div className="p-5">
                            <div className="text-center">
                                <h1 className="h4 text-gray-900 mb-4">Registrati al Servizio</h1>
                            </div>
                            <form className="user">
                                <div className="form-group">
                                    <input required type="email" className="form-control form-control-user" id="inputEmail"
                                        placeholder="Email" ref={emailRef}/>
                                </div>
                                {/* <div className="form-group row">
                                    <div className="col-sm-6 mb-3 mb-sm-0">
                                        <input type="password" className="form-control form-control-user"
                                            id="inputPassword" placeholder="Password" ref={pwdRef}/>
                                    </div>
                                    <div className="col-sm-6">
                                        <input required type="password" className="form-control form-control-user"
                                            id="repeatPassword" placeholder="Ripeti Password" ref={rpwdRef}/>
                                    </div>
                                </div> */}
                                <div className="form-group row">
                                    <div className="col-sm-6 mb-3 mb-sm-0">
                                        <input  required type="text" className="form-control form-control-user" id="nf"
                                            placeholder="Nome" ref={nomeRef}/>
                                    </div>
                                    <div className="col-sm-6">
                                        <input required type="text" className="form-control form-control-user" id="cf"
                                            placeholder="Cognome" ref={cognomeRef}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input required type="tect" className="form-control form-control-user" id="nomeazienda"
                                        placeholder="Nome Azienda" ref={nomeAziendaRef} />
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-6 mb-3 mb-sm-0">
                                        <input required type="text" className="form-control form-control-user" id="piv"
                                            placeholder="Partita Iva / Codice Fiscale" ref={pivaRef}/>
                                    </div>
                                    <div className="col-sm-6">
                                        <input required type="text" className="form-control form-control-user" id="tel"
                                            placeholder="telefono" ref={telRef}/>
                                    </div>
                                </div>
                                <input type="info" onClick={handleRegistra}  className="btn-user btn-block btn btn-info btn btn-primary" value="Registrati">
                                    
                                </input>
                                <hr />
                                {/* <a href="index.html" className="btn btn-google btn-user btn-block">
                                    <i className="fab fa-google fa-fw"></i> Register with Google
                                </a>
                                <a href="index.html" className="btn btn-facebook btn-user btn-block">
                                    <i className="fab fa-facebook-f fa-fw"></i> Register with Facebook
                                </a> */}
                            </form>
                            <hr />

                            {/* {(!passwordNoUguali && error) &&
                                            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">Controlla i valori inseriti, le due password non coincidono!
                                                <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setError(false)}>
                                                    <span aria-hidden="true">&times;</span>
                                                </Button>
                                            </Alert>
                                        } */}
                            { error &&
                                            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">
                                                {messaggioErrore.map((ms, i) =>{
                                                    return(
                                                        <ul>
                                                            <li>{ms}</li>
                                                        </ul>
                                                    )
                                                })}
                                                <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setError(false)}>
                                                    <span aria-hidden="true">&times;</span>
                                                </Button>
                                            </Alert>
                                        }
                            {registrato &&
                                            <Alert variant={Constant.defaultAlertSuccess} className="alert-dismissible fade">Registrazione avvenuta con successo! Controlla la tua email
                                                <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setError(false)}>
                                                    <span aria-hidden="true">&times;</span>
                                                </Button>
                                            </Alert>
                                        }

                                        
                            <div className="text-center">
                                <a className="small" href="/reset-password">Password dimenticata?</a>
                            </div>
                            <div className="text-center">
                                <a className="small" href="/">Hai già un account? Accedi!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    </>
    );
};

export default Register;

