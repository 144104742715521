import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetPiano_AbbonamentoToInit, setPiano_AbbonamentoMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deletePiano_Abbonamento } from "services/piano_abbonamentoService";
import { DashboardCard } from "components/dashboard/DasboardCard";
import { CardPagina } from "components/AbbonamentoCard/CardPagina";
import moment from "moment";
type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    getData: (page, pageSize, searchKey) => void;
};
export const Piano_AbbonamentoTable: React.FC<Props> = ({ hideShowForm, handleRowEdit, getData }) => {
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [rowData, setRowData] = useState(undefined);
    const rData = useSelector((state: RootState) => state.piano_abbonamento);
    const handleSearch = () => {
        if (search.length > 0) {
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
        }
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        await getData(page, newPerPage, '');
    }
    const handlePageChange = (page) => {
        getData(page, rData.pageSize, '');
    };
    const handleRowDeleteClick = (row) => {
        setRowData(row);
        setShowDelete(true);
    }
    useEffect(() => {
        if (rData && rData.list && rData.list.length === 0) {
            dispatch(resetPiano_AbbonamentoToInit());
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
        }
    },[rData.list.length])
    const handleReset = () => {
        setSearch('');
        dispatch(resetPiano_AbbonamentoToInit());
        getData(Constant.defaultPageNumber, rData.pageSize, '');
    }
    const handleServerDelete = async () => {
        if (rowData) {
            const response = await deletePiano_Abbonamento(rowData.id_utente,rowData.id_abbonamento);
            if (response) {
                dispatch(resetPiano_AbbonamentoToInit());
                dispatch(setPiano_AbbonamentoMessage("Deleted Successfully"));
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                setShowDelete(false);
            } else {
                dispatch(setPiano_AbbonamentoMessage("Some error occured!"));
            }
        }
    }

    const handleRowSelection = (row) => {
        console.log(row); // Row Selection Functionality can be written here
    }
    const handleAddButtonClick = () => {
        dispatch(setPiano_AbbonamentoMessage(''));
        hideShowForm('add');
    }

    const columns = [
       {name: 'id_utente', selector: row => row.id_utente, sortable: true},
{name: 'id_abbonamento', selector: row => row.id_abbonamento, sortable: true},
{name: 'codice_abbonamento', selector: row => row.codice_abbonamento, sortable: true},
{name: 'costo', selector: row => row.costo, sortable: true},
{name: 'attivo', selector: row => row.attivo, sortable: true},
{name: 'numero_ricercaunica_massimo', selector: row => row.numero_ricercaunica_massimo, sortable: true},
{name: 'numero_ricercaunica_residuo', selector: row => row.numero_ricercaunica_residuo, sortable: true},
{name: 'numero_anagrafiche_massimo', selector: row => row.numero_anagrafiche_massimo, sortable: true},
{name: 'numero_anagrafiche_residuo', selector: row => row.numero_anagrafiche_residuo, sortable: true},
{name: 'numero_dettaglio_impresa', selector: row => row.numero_dettaglio_impresa, sortable: true},
{name: 'numero_dettaglio_impresa_residuo', selector: row => row.numero_dettaglio_impresa_residuo, sortable: true},
{name: 'nunero_bilanci_massimo', selector: row => row.nunero_bilanci_massimo, sortable: true},
{name: 'nunero_bilanci_residuo', selector: row => row.nunero_bilanci_residuo, sortable: true},
{name: 'nunero_visure_massimo', selector: row => row.nunero_visure_massimo, sortable: true},
{name: 'nunero_visure_residuo', selector: row => row.nunero_visure_residuo, sortable: true},
{name: 'nunero_protesti_massimo', selector: row => row.nunero_protesti_massimo, sortable: true},
{name: 'nunero_protesti_residuo', selector: row => row.nunero_protesti_residuo, sortable: true},
{name: 'nunero_catastali_massimo', selector: row => row.nunero_catastali_massimo, sortable: true},
{name: 'nunero_catastali_residuo', selector: row => row.nunero_catastali_residuo, sortable: true},
{name: 'numero_reportsmart_massimo', selector: row => row.numero_reportsmart_massimo, sortable: true},
{name: 'numero_reportsmart_residuo', selector: row => row.numero_reportsmart_residuo, sortable: true},
{name: 'numero_deepdossier_massimo', selector: row => row.numero_deepdossier_massimo, sortable: true},
{name: 'numero_deepdossier_residuo', selector: row => row.numero_deepdossier_residuo, sortable: true},
{name: 'numero_executivereport_massimo', selector: row => row.numero_executivereport_massimo, sortable: true},
{name: 'numero_executivereport_residuo', selector: row => row.numero_executivereport_residuo, sortable: true},
{name: 'numero_reportpersona_massimo', selector: row => row.numero_reportpersona_massimo, sortable: true},
{name: 'numero_reportpersona_residuo', selector: row => row.numero_reportpersona_residuo, sortable: true},
{name: 'numero_reportpersonacompleto_massimo', selector: row => row.numero_reportpersonacompleto_massimo, sortable: true},
{name: 'numero_reportpersonacompleto_residuo', selector: row => row.numero_reportpersonacompleto_residuo, sortable: true},
{name: 'data_apertura', selector: row => row.data_apertura, sortable: true},
{name: 'data_scadenza', selector: row => row.data_scadenza, sortable: true},

        ,{
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowEdit(row)}>Edit</Button>,
        },
        {
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowDeleteClick(row)}>Delete</Button>,
        },
    ];


    const handleAbbonamentiDisattivi = (lista) =>{
        let piani_disattivi = [];

        lista.forEach(element => {
            
            if(element.attivo === '0'){
                piani_disattivi.push(element);
            }

        });
        return piani_disattivi;
    }

    const handleStatoAbbonamento = (stato) => {

        if(stato === '0'){
            return <p className="color-icon-notfound">Non Attivo</p>
        }else{
            return <p className="color-icon-green">Attivo</p>
        }

    }

    return (
        <>
            {/* <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
                <div className="col-sm-10">
                    <h4>Abbonamenti terminati</h4>
                </div>
           </Row> */}
                {/* <Col className="ml-auto" md={3} xs={12} xl={3}>
                    <InputGroup>
                        <Form.Control
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-search"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <Button disabled={search.length <= 2} variant="dark" className="rounded-0 rounded-right" id="button-search" onClick={handleSearch}>
                            Search
                        </Button>
                    </InputGroup>
                </Col>
                <div className="table-responsive">
                    <DataTable
                        selectableRows={false}
                        onSelectedRowsChange={handleRowSelection}
                        paginationPerPage={Constant.defaultPageNumber}
                        paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
                        columns={columns} data={handleAbbonamentiDisattivi(rData.list)}
                        onChangeRowsPerPage={handlePerRowsChange}
                        paginationTotalRows={rData.totalCount}
                        className="table table-bordered"
                        pagination
                        paginationServer
                        onChangePage={handlePageChange}></DataTable>
                </div> */}

                {/* {
                    handleAbbonamentiDisattivi(rData.list).map((abbonamento, i) =>{
                        return(
                            <>
                                <Row>
                                    <Col md={12} xl={12} xs={12} className="cursor-pointer mb-4">
                                        <Card className="border-left-primary shadow h-100 py-2">
                                            <Card.Body>
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-md-11 col-xs-10">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                                    Stato Abbonamento
                                                                </div>
                                                                <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                                                                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1 font-size-1-2">
                                                                    {handleStatoAbbonamento(abbonamento.attivo)}
                                                                </div>
                                                                <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                                                            </div>
                                                            <div className="col-md-4">
                                                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                                    Codice Abbonamento
                                                                </div>
                                                                <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                                                                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1 font-size-1-2">
                                                                    {abbonamento.id_abbonamento}
                                                                </div>
                                                                <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                                                            </div>
                                                            <div className="col-md-4">
                                                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                                    Data Scadenza
                                                                </div>
                                                                <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                                                                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1 font-size-1-2">
                                                                    {moment(abbonamento.data_scadenza).format('DD/MM/YYYY HH:mm:ss')}
                                                                </div>
                                                                <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-1  col-xs-2">
                                                        <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </>
                        )
                    })
                }
                <ConfirmationModal buttonNegative="Cancel" buttonPositive="Delete" title="Delete Confirmation" show={showDelete} body={"Are you sure?"} onNegative={() => setShowDelete(false)} onPositive={handleServerDelete} /> */}

                </>

            
    );
}

