import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Card, InputGroup, Button, Row, DropdownButton, Dropdown, Tabs, Tab, TabPane, NavLink } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { activateMenu, setAreaGeograficaList, setAreaGeograficaMessage, setCodici_AtecoList, setCodici_AtecoMessage, setElenco_Imprese_EstesoMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getTrovaImpresa, getTrovaImpresaPec } from "services/ricerca_impresa_service";
import Layout from "template";
import ConfirmationModal from "template/ConfirmationModal";
import DetailModal from "template/DetailModal";
import { DashboardCard } from "../DasboardCard";
import LoadingSpinner from "../../LoadingSpinner";
import Form from 'react-bootstrap/Form';
import { isTemplateExpression } from "typescript";
import ConfirmationModalNoAcquisto from "template/ConfirmationModalNoAcquisto";
import ModalErrorGenerico from "template/ModalErrorGenerico";
import { sassFalse } from "sass";
import DetailImpresaPuntualeModal from "template/DetailImpresaPuntualeModal";
import ConfirmationVisura from "template/ConfirmationVisura";
import Select from 'react-select';
import ConfirmationDatiObbligatoriElenco from "template/ConfirmationDatiObbligatoriElenco";
import { getRicercaElencoImpresaPreventivo, getRichiestaElencoImpresa } from "services/richiesta_elenco_impresa_esteso";
import ConfirmationPreventivoElenco from "template/ConfirmationPreventivoElenco";
import ConfirmationDifferita from "template/ConfirmationDifferita";
import { getCodici_Ateco } from "services/codici_atecoService";
import { getComuni_Catastali } from "services/comuni_catastaliService";
import { getWs_Provincieinfocamere } from "services/ws_provincieinfocamereService";

import { useLocation } from 'react-router-dom';
import { controlloDisponibilita, getDatiAreaGeografica, getDatiNaturaGiuridica, getDatiateco, getDettaglioAzienda, getRicercaEstesa, richiestaProdotto, richiestaReportSmart } from "services/ricercaUnicaService";
import { ReactTags, TagSuggestion  } from "react-tag-autocomplete";
import ConfirmationBilancio from "template/ConfirmationBilancio";
import { getUltimoBilancio } from "services/richiesta_bilancio";
import ModalConfirmGenerico from "template/ModalConfirmGenerico";
import { Doughnut } from "react-chartjs-2";
import DonutChart from "components/grafici/DonutChart";
import DonutChartSemi from "components/grafici/DonutChartSemi";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import L, { LatLngTuple } from 'leaflet'
import 'leaflet/dist/leaflet.css';
import placeholderImage from '../../../asset/placeholder_azienda.png';
import LineChart from "components/LineChart";
import { number, string } from "yup";
import { DettaglioAzienda } from ".";
import ConfirmationDifferitaReport from "template/ConfirmationDifferitaReport";
import { getPiano_Estrazioni_Utente } from "services/piano_estrazioniService";
import { IPiano_Estrazioni } from "redux/slices/piano_estrazioni";
import { getPiano_Abbonamento_Utente } from "services/piano_abbonamentoService";
import ConfirmationControlloDisponibilita from "template/ConfirmationControlloDisponibilita";


interface LocationState {
    id_item: number;
    name: string;
    description: string;
  }

type Props = {

    numero_imprese: Number
    getData: (page, pageSize, searchKey, datiricerca) => void

};
export const Dettaglio: React.FC<Props> = ({getData, numero_imprese}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { id } = useParams();

    const location = useLocation();
    const state = location.state as LocationState; // Usa type assertion

    const { id_item, name, description } = state || {};

    const [rowData, setRowData] = useState(undefined);
    const [rowDataVisura, setRowDataVisura] = useState(undefined);
    const [showRichiestaDocumento, setRichiestaDocumento] = useState(false);
    

    const [isLoading, setIsLoading] = useState(false);






    type OptionTypeRowDataPreventivo = {
        esito: string;
        numero_posizioni_preventivo: string;
        numero_posizioni_disponibili: string;
        numero_posizioni_residue: string;
        messaggio: string;


    };
    


    const rowDataPreventivoInfo: OptionTypeRowDataPreventivo = {
        esito: "",
        numero_posizioni_preventivo: "",
        numero_posizioni_disponibili: "",
        numero_posizioni_residue: "",
        messaggio: ""
    }

    const REPORT_SMART = 11
    const REPORT_DEEP = 12
    const REPORT_EXECUTIVE = 13
    const [showDatiRichiestaPreventivo, setshowDatiRichiestaPreventivo] = useState(false);
    const [rowDataPreventivo, setRowDataPreventivo] = useState(undefined);
    const [rowDataResponsePreventivo, setrowDataResponsePreventivo] = useState(undefined)
    const [listaImpreseRicercaUnica, setListaImpresaRicercaUnica] = useState(undefined)
    const [posizioniSelezionate, setPosizioniSelezionate] = useState(undefined)
    const [anni, setAnni] = useState([])
    const [vociDettaglioBilancio, setVociDettaglioBilancio] = useState([])

    
    const [datiDettaglioAzienda, setDatiDettaglioAzienda] = useState(undefined);
    const [showConfermaRichiestaElenco, setshowConfermaRichiestaElenco] = useState(false);
    const [showConfermaRichiestaDocumento, setshowConfermaRichiestaDocumento] = useState(false);
    const [messaggioRichiestaPositiva, setMessaggioRichiestaPositiva] = useState("")

    const [creditiClienti, setCreditiClienti] = useState(undefined);
    const [debitiBanche, setDebitiBanche] = useState(undefined);
    const [debitiFornitori, setDebitiFornitori] = useState(undefined);
    const [fatturato, setFatturato] = useState(undefined);
    const [patrimonioNetto, setPatrimonioNetto] = useState(undefined);
    const [totaleDebiti, setTotaleDebiti] = useState(undefined);
    const [utilePerdita, setUtilePerdita] = useState(undefined);
    
    const [mappaSedi, setMappaSedi] = useState(false);
    const [tipoReport, setTipoReport] = useState(false);

    const DEEP_DOOSIER = 0
    const EXECUTIVE_REPORT = 1

    const [responseConferma, setResponseConferma] = useState(undefined);
    const [responseDisponibilita, setResponseDisponibilita] = useState(undefined);
    const [showDisponibilita, setShowDisponibilita] = useState(false);

    


    const richiestaDettaglioAzienda = async (id_soggetto) => {
        if(id_soggetto != null){
            
            try {
                setIsLoading(true);
                var datiricerca = {
                    "id_azienda": id_soggetto
                }
                const response = await getDettaglioAzienda(datiricerca);

                if(response.data.status === 200){
                    const data = await response.data.response;
                    // Imposta le opzioni nel tuo stato
                    setDatiDettaglioAzienda(data)
                    mappa(data.sezione_anagrafica_contatti.posizione, data.ragione_sociale)
                    //dettaglioPosizioniSedi(data.sezione_unita_locali.unita_locali.unita_locali)
                    setIsLoading(false);
                    if(data.sezione_bilanci.bilanci.numero_bilanci > 0){
                        let coloreFrafico = []
                        let annibil = annoBilanci(data.sezione_bilanci.bilanci.bilanci)
                        setAnni(annibil)
                        setVociDettaglioBilancio(data.sezione_bilanci.bilanci.voci_dettaglio_per_anno[2].valori_formattati)
                        console.log(data.sezione_bilanci.bilanci.voci_dettaglio_per_anno[2].valori_formattati)
                        console.log(data)


                        data.sezione_bilanci.bilanci.voci_dettaglio_per_anno.map((voce_dettaglio, i) => {
                            let dato = {
                                labels: annibil.map((data, i) => data),
                                datasets: [
                                {
                                    fill: true,
                                    label: voce_dettaglio.descrizione,
                                    data: voce_dettaglio.valori_formattati.map((data, i) => conversione_dato(data)),
                                    borderColor: coloreGrafico(voce_dettaglio.descrizione),
                                    backgroundColor: coloreBackgroundGrafico(voce_dettaglio.descrizione),
                                },
                                ],
                            }

                                if(voce_dettaglio.descrizione === "Crediti Clienti") return setCreditiClienti(dato);
                                if(voce_dettaglio.descrizione === "Fatturato") return setFatturato(dato);
                                if(voce_dettaglio.descrizione === "Patrimonio Netto") return setPatrimonioNetto(dato);
                                if(voce_dettaglio.descrizione === "Utile Perdita") return setUtilePerdita(dato);
                                if(voce_dettaglio.descrizione === "Debiti Verso Banche") return setDebitiBanche(dato);
                                if(voce_dettaglio.descrizione === "Debiti Verso Fornitori") return setDebitiFornitori(dato);
                                if(voce_dettaglio.descrizione === "Totale Debiti") return setTotaleDebiti(dato);
                        })
                    
                    }
                }
                if(response.data.status === 203){
                    setMessaggioErrore(response.data.response)
                    setErrore(true)
                }
                
        
                } catch (error) {
                    console.error('Errore durante il recupero delle opzioni:', error);
                    setIsLoading(false);
                } finally {
                    setIsLoading(false);
                }
        }
    }

    const coloreGrafico = (descrizione) => {
        if(descrizione === "Crediti Clienti") return 'rgb(28, 200, 138)';
        if(descrizione === "Fatturato") return 'rgb(28, 200, 138)';
        if(descrizione === "Patrimonio Netto") return 'rgb(28, 200, 138)';
        if(descrizione === "Utile Perdita") return 'rgb(28, 200, 138)';
        if(descrizione === "Debiti Verso Banche") return 'rgb(219, 39, 35)';
        if(descrizione === "Debiti Verso Fornitori") return 'rgb(219, 39, 35)';
        if(descrizione === "Totale Debiti") return 'rgb(219, 39, 35)';

    }

    const coloreBackgroundGrafico = (descrizione) => {
        if(descrizione === "Crediti Clienti") return 'rgb(28, 200, 138, 0.5)';
        if(descrizione === "Fatturato") return 'rgb(28, 200, 138, 0.5)';
        if(descrizione === "Patrimonio Netto") return 'rgb(28, 200, 138,0.5)';
        if(descrizione === "Utile Perdita") return 'rgb(28, 200, 138, 0.5)';
        if(descrizione === "Debiti Verso Banche") return 'rgb(219, 39, 35, 0.5)';
        if(descrizione === "Debiti Verso Fornitori") return 'rgb(219, 39, 35, 0.5)';
        if(descrizione === "Totale Debiti") return 'rgb(219, 39, 35,0.5)';

    }

    const conversione_dato = (dato_string) => {
        if(dato_string == null) return "0";
        let dato_string_tmp = dato_string.replace(".","")
        dato_string_tmp = dato_string_tmp.replace(".","")
        dato_string_tmp = dato_string_tmp.replace(",00","")
        dato_string_tmp = dato_string_tmp.replace(",00","")
        dato_string = dato_string_tmp;
        console.log(dato_string)
        return dato_string;
    }

    

    const annoBilanci = (bilanci) =>{
        let anni = []
        if(bilanci != null && bilanci.length > 0){
            bilanci.map((item, i) => {
                let data = item.data_deposito
                let anno = data.substr(0,4)
                anni.push(anno)
            })

            var sortedArray: string[] = anni.sort((n1,n2) => {
                if (n1 > n2) {
                    return 1;
                }
            
                if (n1 < n2) {
                    return -1;
                }
            
                return 0;
            });

            return sortedArray;
        }

    }

    const handleServerRedirectDifferita = () => {

        navigate('/ws_ticket', { replace: true });
    }



    useEffect(() => {
        if(datiDettaglioAzienda == null)richiestaDettaglioAzienda(id)
        console.log("-----------")
        console.log(state)
        console.log(datiDettaglioAzienda)
        //if(datiDettaglioAzienda != null)dettaglioPosizioniSedi(datiDettaglioAzienda)
        
      }, []);

 
    const handleRowConfermaRichiestaVisuraClick = (row) => {
        //setRowData(row);
        setRowDataVisura(row)
        setRichiestaDocumento(true);
    }

    const handleServerVisura = async () => {
        if (rowDataVisura) {
            setRichiestaDocumento(false);
            handleRowVisura(rowDataVisura.cciaa[0],rowDataVisura.nrea[0], rowDataVisura.denominazione[0], rowDataVisura.StatoAttivita[0]);      
        } else {
            //dispatch(setElenco_Imprese_EstesoMessage("Some error occured!"));
        }
    }

    const handleRowVisura = (cciaa, nrea, nomeanagrafica, stato_attivita) => {
        const idprodotto = 7;
        // if(stato_attivita.toUpperCase() !== 'A'.toUpperCase()){ 
        //     getDatiVisura(cciaa, nrea, nomeanagrafica, idprodotto, "VISSTO");
        // }else{
        //     getDatiVisura(cciaa, nrea, nomeanagrafica, idprodotto, "VISORDIN");
        // }
        
    }

    const [showRichiestaBilancio, setRichiestaBilancio] = useState(false);
    const handleRowConfermaRichiestaBilancioClick = (row) => {
        setRowData(row);
        setRichiestaBilancio(true);
    }

    const handleRowEnableBilancio = (row) => {

        if(row.fatturato === '' || row.fatturato == null ){
            return (<Button variant="info" className="larghezza-100" disabled >
            <i className="fa fa-times-circle icon-size-small decoration-none" aria-hidden="true"></i> <strong className="font-size-1 decoration-none">Nessun Bilancio</strong></Button>)
        }else{
            if(row.fatturato !== 'Registrata Attiva'){
                return(<Button variant="info" className="larghezza-100" onClick={() => handleRowConfermaRichiestaBilancioClick(row)}>
                    <i className="fa fa-line-chart icon-size-small decoration-none" aria-hidden="true"></i> <strong className="font-size-1 decoration-none">Ultimo Bilancio</strong></Button>);
            }else{
                return(<Button variant="info" className="larghezza-100" onClick={() => handleRowConfermaRichiestaBilancioClick(row)}>
                    <i className="fa fa-line-chart icon-size-small decoration-none" aria-hidden="true"></i> <strong className="font-size-1 decoration-none">Ultimo Bilancio</strong></Button>);
            }
            
            
        }
      }
    
      const [showDelete, setShowDelete] = useState(false);
      const handleServerBilancio = async () => {
        if (rowData) {
            setShowDelete(false);
            handleRowBilancio(rowData["attributi"]["cciaa"],rowData["attributi"]["n-rea"], rowData["denominazione"]);      
        } else {
            dispatch(setElenco_Imprese_EstesoMessage("Some error occured!"));
        }
    }

    const handleRowBilancio = (cciaa, nrea, nomeanagrafica) => {
        const idprodotto = 6;
        getDataBilancio(cciaa, nrea, nomeanagrafica, idprodotto);
      }

      const [showConfermaRichiesta, setConfermaRichiesta] = useState(false);
      const [showErrore, setErrore] = useState(false);
      const [showMessaggio, setShowMessaggio] = useState("")
      const [showMessaggioErrore, setMessaggioErrore] = useState("Errore nella richiesta")
      const getDataBilancio = (cciaa, nrea, nomeanagrafica, idprodotto) => {
        setIsLoading(true);
        var datiricerca = {
          "cciaa": cciaa,
          "nrea": nrea,
          "nomeanagrafica": nomeanagrafica,
          "idprodotto": idprodotto
        }
        getUltimoBilancio(datiricerca).then((response) => {
          if (response != null && response.data.message === "OK") {
            //dispatch(setElenco_Imprese_Esteso_EstrazioneList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
            //dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Bilancio Richiesto"));
            setShowMessaggio("Il Bilancio è stato richiesto con successo! Grazie")
            setConfermaRichiesta(true);
            setIsLoading(false);
          } else {
            //dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Bilancio non richiedibile"));
            setMessaggioErrore("La richiesta del Bilancio non è stata effettuata con successo. Riprovare e se il problema persiste contattare il servizio assistenza. Grazie")
            setErrore(true)
            setIsLoading(false);
          }
        })
      }


    const handleSalvaRicerca = (codice) =>{

    }

    const controlloDisponibilitaAbbonamento = () =>{

    }

    const [reportRichiesta, setReportRichiesta] = useState(undefined);
    const handleReportDisponibilita = (report) =>{
        setIsLoading(true)
        let dati_ricerca = {
            "report": report 
        }

        controlloDisponibilita(dati_ricerca).then((response) =>{
            console.log(response)
            if(response != null && response.data.status === 200){
                let body = {
                    "anagrafiche": response.data.anagrafiche,
                    "response": response.data.response,
                    "prodotto": response.data.prodotto

                }
                setResponseDisponibilita(body)
                setShowDisponibilita(true)
                setReportRichiesta(report)
                setIsLoading(false)

            }

        })


        //window.open(url, '_blank')
        
    }

    const handleConfermaReportSmart = (report) => {
        
        let dati_ricerca = {
            "report": report 
        }

        if(report === REPORT_SMART){
            setIsLoading(true)
            richiestaReportSmart(dati_ricerca).then((response) =>{
                if(response != null && response.data.status === 200){
                    window.open(datiDettaglioAzienda.link_stampa_dettaglio_pdf, '_blank')
                    setShowDisponibilita(false)
                }
                if(response != null && response.data.status === 201){
                    //window.open(datiDettaglioAzienda.link_stampa_dettaglio_pdf, '_blank')
                    setErrore(true)
                }
            })
            setIsLoading(false)
        }
        if(report === REPORT_DEEP){
            handleRichiestaProdotto(DEEP_DOOSIER, datiDettaglioAzienda.sezione_anagrafica_contatti.anagrafica.codice_fiscale, datiDettaglioAzienda.ragione_sociale)
        }
        if(report === REPORT_EXECUTIVE){
            handleRichiestaProdotto(EXECUTIVE_REPORT, datiDettaglioAzienda.sezione_anagrafica_contatti.anagrafica.codice_fiscale, datiDettaglioAzienda.ragione_sociale)
        }
        

               
    }


    const handleListaIndietro = () =>{

        navigate('/ricerca_unica', { replace: false, state: state });
    }

    const valoreDato = (dato) => {
        if(dato == null){
            return "Dato non fornito"
        }else{
            return dato
        }
    }

    const valoreDatoBilancio = (dato) => {
        if(dato == null){
            return "Non disponibile"
        }else{
            return dato
        }
    }


    const colors = ['#57e188', '#f8f32b','#f95d54' ];
    const colors_v = ['#008000', '#f8f32b30', '#db272330'];
    const colors_a = ['#00800030', '#f8f32b', '#db272330'];
    const colors_r = ['#00800030', '#f8f32b30', '#db2723'];

    const sceltaColore = (dato) =>{
        if(dato === "VERDE") return colors_v
        if(dato === "GIALLO" || dato === "ARANCIONE") return colors_a
        if(dato === "ROSSO") return colors_r
        return color_empty

    }

    const classeTestoIndice = (dato) =>{
       

        if(dato === "VERDE") return "col-sm-12 align-center color-icon-green boldtesto"
        if(dato === "GIALLO" || dato === "ARANCIONE") return "col-sm-12 align-center color-icon-yellow boldtesto"
        if(dato === "ROSSO") return "col-sm-12 align-center color-icon-red boldtesto"
        return "col-sm-12 align-center boldtesto"


    }
    const color_empty = [ '#efefefef' ]

    var dati_evidenze = [
        {name: "A", value: 1},
        {name: "B", value: 1},
        {name: "C", value: 1},
      ];

      const mappa = (coordinate, denominazione) =>{
       

        var lat = 42.5;
        var lon = 12.5;

        // Imposta il livello di zoom desiderato
        var zoomLevel = 17;

        // Aggiungi il layer di tile OpenStreetMap_Mapnik alla mappa
        var OpenStreetMap_Mapnik = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { //https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png
            maxZoom: 19,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            //zoomOffset: -1,
            //tileSize: 512

        });

        // Crea la mappa centrata sull'Italia
        var map = L.map('map').setView([lat, lon], zoomLevel);

        // Aggiungi il layer di tile alla mappa
        map.addLayer(OpenStreetMap_Mapnik);

        // URL dell'immagine
        var imageUrl = placeholderImage;

        // Dimensioni dell'immagine
        var iconSize = [32, 32]; // Sostituisci con le dimensioni desiderate

        // Crea un'icona personalizzata
        var customIcon = L.icon({
        iconUrl: imageUrl,
        //iconSize: iconSize,
        });

        var placeholderCoordinates: LatLngTuple = [coordinate.lat, coordinate.lon]; // Ad esempio, Roma

        // Aggiungi un marker al segnaposto
        var placeholderMarker = L.marker(placeholderCoordinates, {icon : customIcon}).addTo(map);

        // Aggiungi un popup al marker (opzionale)
        placeholderMarker.bindPopup(denominazione).openPopup();

        // Centra la mappa sul segnaposto
        map.setView(placeholderCoordinates, zoomLevel);

        
        

        
      }

      const numeroBilanci = (bilanci) => {

        if(bilanci == null || bilanci === 0 ) return "Nessun Bilancio";
        if(bilanci === 1) return "Ultimo Bilancio";
        if(bilanci > 1) return "Ultimi "+ bilanci +" Bilanci";

      }

      const ricaviInfo = (trend) => {
        if(trend == null){
            return (
                <div className="col-sm-4 color-gray-pg">Trend Ricavi: <p><strong className="color-blue">Dato non fornito</strong></p></div>
            )
        }else{
            let icon_trend = ""
            if(trend !=null){
                if(trend === "CRESCENTE"){
                    icon_trend = "fa-solid fa-long-arrow-up color-icon-green"
                }
                if(trend === "CALANTE"){
                    icon_trend = "fa-solid fa-long-arrow-down color-icon-red-bil"
                }
                if(trend === "STAZIONARIO"){
                    icon_trend = "fa-solid fa-arrows-h color-icon-orange"
                }
                return (
                    <div className="col-sm-4 color-gray-pg">Trend Ricavi: <p><strong className="color-blue">{trend} <i className={icon_trend}></i></strong></p></div>
                )
            }else{
                return (
                    <div className="col-sm-4 color-gray-pg">Trend Ricavi: <p><strong className="color-blue">{trend}</strong></p></div>
                )
            }
        }
    }

    const debitiInfo = (trend) => {
        if(trend == null){
            return (
                <div className="col-sm-4 color-gray-pg">Trend Debiti: <p><strong className="color-blue">Dato non fornito</strong></p></div>
            )
        }else{
            let icon_trend = ""
            if(trend !=null){
                if(trend === "CALANTE"){
                    icon_trend = "fa-solid fa-long-arrow-down color-icon-green"
                }
                if(trend === "CRESCENTE"){
                    icon_trend = "fa-solid fa-long-arrow-up color-icon-red-bil"
                }
                if(trend === "STAZIONARIO"){
                    icon_trend = "fa-solid fa-arrows-h color-icon-orange"
                }
                return (
                    <div className="col-sm-4 color-gray-pg">Trend Debiti: <p><strong className="color-blue">{trend} <i className={icon_trend}></i></strong></p></div>
                )
            }else{
                return (
                    <div className="col-sm-4 color-gray-pg">Trend Debiti: <p><strong className="color-blue">{trend}</strong></p></div>
                )
            }
        }
    }

    const boxBilanncio = (numeroBilanci, listaBilanci) =>{

            
            if(numeroBilanci === 0){
                return(
                    <Col  md={12} xl={12} xs={12}>
                    </Col>
                )
            }else{
                listaBilanci.map((bilancio, i) =>{
                    if(numeroBilanci === 1) {
                    return (
                        <Col  md={12} xl={12} xs={12}>
                            {dettaglioBilanncio(bilancio)}
                        </Col>
                        )
                    }
                    if(numeroBilanci === 2) {
                        return (
                            <Col  md={6} xl={6} xs={12}>
                                {dettaglioBilanncio(bilancio)}
                            </Col>
                            )
                    }
                    if(numeroBilanci === 3) {
            //             return(<p>sssss</p>)
            // //             return (
            //                 <Col  md={4} xl={4} xs={12}>
            //                     <Row>
            //     <div className="col-sm-12">Data Deposito: <p><strong>{valoreDato(bilancio.data_deposito_formattata)}</strong></p></div>
            //     <div className="col-sm-12">Fatturato: <p><strong>{valoreDato(bilancio.fatturato_formattato)}</strong></p></div>
            //     <div className="col-sm-12">Utile: <p><strong>{valoreDato(bilancio.utile_formattato)}</strong></p></div>
            //     <div className="col-sm-12">Patrimonio Netto: <p><strong>{valoreDato(bilancio.patrimonio_netto_formattato)}</strong></p></div>
            //     <div className="col-sm-12">Totale Debiti: <p><strong>{valoreDato(bilancio.debiti_totali_formattato)}</strong></p></div>
            // </Row>
            //                 </Col>
            // //                 )
                    }
                })
            }
        }
       
    const dettaglioBilanncio = (bilancio) =>{

        return (
            <Row>
                <div className="col-sm-12 marginb-15 border-title color-gray-pg padding-bottom10"><i className="fa-solid fa-calendar-days"></i> Data Deposito: <span><strong className="color-blue">{valoreDato(bilancio.data_deposito_formattata)}</strong></span></div>
                <div className="col-sm-12 color-gray-pg">Fatturato: <p><strong className="color-blue">{valoreDato(bilancio.fatturato_formattato)}</strong></p></div>
                <div className="col-sm-12 color-gray-pg">Utile: <p><strong className="color-blue">{valoreDato(bilancio.utile_formattato)}</strong></p></div>
                <div className="col-sm-12 color-gray-pg">Patrimonio Netto: <p><strong className="color-blue">{valoreDato(bilancio.patrimonio_netto_formattato)}</strong></p></div>
                <div className="col-sm-12 color-gray-pg">Totale Debiti: <p><strong className="color-blue">{valoreDato(bilancio.debiti_totali_formattato)}</strong></p></div>
            </Row>
           
            )

    }


    const dettaglioVoce = (dettaglio, anni) => {
        if (dettaglio != null && anni != null) {
          return dettaglio.map((value, index) => (
            <React.Fragment key={index}>
              <Col md={6} xl={6} xs={6}>
                <div className="col-sm-12 color-gray-pg">Anno: <p><strong className="color-blue">{anni[index]}</strong></p></div>
              </Col>
              <Col md={6} xl={6} xs={6}>
                <div className="col-sm-12 color-gray-pg">Valore(€): <p><strong className="color-blue">{valoreDatoBilancio(value)}</strong></p></div>
              </Col>
            </React.Fragment>
          ));
        } else {
          return (<div className="col-sm-12">Dato non disponibile</div>);
        }
      }
      
      const dettagliVoce = (dettaglio, anni, idgrafico) => {
        var valoriOrdinati = dettaglio.valori_formattati.sort((n1, n2) => {
          if (n1 > n2) {
            return 1;
          }
          if (n1 < n2) {
            return -1;
          }
          return 0;
        });
      
        return (
            <>
            <Row >
            <Col md={12} xl={12} xs={12} className="padding-bottom20">
              <Row className="shadow box-ricerca-elenco row heigth-max shadow-margin-zero">
                <div className="col-sm-12 border-title marginb-15"><h5 className="margin-b-0">{dettaglio.descrizione}</h5></div>
                <div className="col-sm-6">
                  <Row>
                    {dettaglioVoce(valoriOrdinati, anni)}
                  </Row>
                </div>
                <div className="col-sm-6">
                    {dettaglio.descrizione === "Crediti Clienti"  && creditiClienti != null && <LineChart id={idgrafico} chartData={creditiClienti} /> }
                    {dettaglio.descrizione === "Debiti Verso Banche"  && debitiBanche != null && <LineChart id={idgrafico} chartData={debitiBanche} /> }
                    {dettaglio.descrizione === "Debiti Verso Fornitori"  && debitiFornitori != null && <LineChart id={idgrafico} chartData={debitiFornitori} /> }
                    {dettaglio.descrizione === "Fatturato"  && fatturato != null && <LineChart id={idgrafico} chartData={fatturato} /> }
                    {dettaglio.descrizione === "Patrimonio Netto"  && patrimonioNetto != null && <LineChart id={idgrafico} chartData={patrimonioNetto} /> }
                    {dettaglio.descrizione === "Utile Perdita"  && utilePerdita != null && <LineChart id={idgrafico} chartData={utilePerdita} /> }
                    {dettaglio.descrizione === "Totale Debiti"  && totaleDebiti != null && <LineChart id={idgrafico} chartData={totaleDebiti} /> }

                    
                </div> 
              </Row>
            </Col>
          </Row>
            </>
          
        );
        
      }

      const dettagliSoci = (dettaglio) => {
        
        return (
            <>
            <Row className="shadow box-ricerca-elenco heigth-max shadow-margin-zero">
                <div className="col-sm-1 center-block-custom padding-zero">
                    <i className="fa fa-user decoration-none font-icon-35"></i>
                </div>
                <div className="col-sm-11">
                    <div className="row">
                        <div className="col-sm-12 color-gray-pg">Denominazione: <p><strong className="color-blue">{valoreDato(dettaglio.denominazione)}</strong></p></div>
                        <div className="col-sm-6 color-gray-pg">Codice Fiscale: <p><strong className="color-blue">{valoreDato(dettaglio.codice_fiscale)}</strong></p></div>
                        <div className="col-sm-6 color-gray-pg">Diritto: <p><strong className="color-blue">{valoreDato(dettaglio.quote[0].diritto)}</strong></p></div>
                        <div className="col-sm-6 color-gray-pg">Quota: <p><strong className="color-blue">{valoreDato(dettaglio.quote[0].quota_formattata)}</strong></p></div>
                        {!dettaglio.persona_giuridica &&
                            <>
                                <div className="col-sm-6 color-gray-pg">Data Nascita: <p><strong className="color-blue">{valoreDato(dettaglio.data_nascita_formattata)}</strong></p></div>
                                <div className="col-sm-6 color-gray-pg">Anni: <p><strong className="color-blue">{valoreDato(dettaglio.anni)}</strong></p></div>
                                <div className="col-sm-6 color-gray-pg">Comune Nascita: <p><strong className="color-blue">{valoreDato(dettaglio.luogo_nascita)}</strong></p></div>
                                <div className="col-sm-6 color-gray-pg">Provincia Nascita: <p><strong className="color-blue">{valoreDato(dettaglio.provincia_nascita)}</strong></p></div>
                            </>
                        }
                        
                    </div>
                </div>
                
            </Row>
            </>
          
        );
        
      }

      const dettagliEsponenti = (dettaglio) => {
        
        return (
            <>
            <Row className="shadow box-ricerca-elenco heigth-max shadow-margin-zero">
                <div className="col-sm-1 center-block-custom padding-zero">
                    <i className="fa fa-address-card decoration-none font-icon-35"></i>
                </div>
                <div className="col-sm-11">
                    <div className="row">
                        <div className="col-sm-12 color-gray-pg">Denominazione: <p><strong className="color-blue">{valoreDato(dettaglio.denominazione)}</strong></p></div>
                        <div className="col-sm-6 color-gray-pg">Codice Fiscale: <p><strong className="color-blue">{valoreDato(dettaglio.codice_fiscale)}</strong></p></div>
                        {!dettaglio.persona_giuridica &&
                            <>
                            <div className="col-sm-6 color-gray-pg">Data Nascita: <p><strong className="color-blue">{valoreDato(dettaglio.data_nascita_formattata)}</strong></p></div>
                            <div className="col-sm-6 color-gray-pg">Anni: <p><strong className="color-blue">{valoreDato(dettaglio.anni)}</strong></p></div>
                            <div className="col-sm-6 color-gray-pg">Comune Nascita: <p><strong className="color-blue">{valoreDato(dettaglio.luogo_nascita)}</strong></p></div>
                            <div className="col-sm-6 color-gray-pg">Provincia Nascita: <p><strong className="color-blue">{valoreDato(dettaglio.provincia_nascita)}</strong></p></div>
                            </>    
                        }
                        <div className="col-sm-6 color-gray-pg">Cariche: <p><strong className="color-blue">{dettaglio.cariche.map((carica, i) => {
                            return(
                                <p>{carica}</p>
                            )
                            })}</strong></p>
                        </div>
                    </div>
                </div>

            </Row>
            </>
          
        );
        
      }

      const dettagliUnitaLocali = (dettaglio) => {
        
        return (
            <>
            <Row className="shadow box-ricerca-elenco heigth-max shadow-margin-zero">
                <div className="col-sm-1 center-block-custom padding-zero">
                    <i className="fa fa-building decoration-none font-icon-35"></i>
                </div>
                <div className="col-sm-11">
                    <div className="row">
                        <div className="col-sm-12 color-gray-pg">Tipo Sede: <p><strong className="color-blue">{valoreDato(dettaglio.tipologia_unita)}</strong></p></div>
                        <div className="col-sm-12 color-gray-pg">Denominazione: <p><strong className="color-blue">{valoreDato(dettaglio.denominazione)}</strong></p></div>
                        <div className="col-sm-6 color-gray-pg">Cap: <p><strong className="color-blue">{valoreDato(dettaglio.cap)}</strong></p></div>
                        <div className="col-sm-6 color-gray-pg">Comune: <p><strong className="color-blue">{valoreDato(dettaglio.comune) +" (" + valoreDato(dettaglio.provincia) + ")"}</strong></p></div>
                        <div className="col-sm-12 color-gray-pg">Indirizzo: <p><strong className="color-blue">{valoreDato(dettaglio.toponimo) + " " + valoreDato(dettaglio.indirizzo)}</strong></p></div>
                    </div>
                </div>
            </Row>
            </>
          
        );
        
      }



    const dettaglioPosizioniSedi = (dettaglio) => {



    let posizioni = [];

    if(dettaglio != null && dettaglio.length > 0){
        dettaglio.map((unita, i) => {
            let coordinate = {
                "lat": null, // Valore di default, può essere impostato a null inizialmente
                "lon": null, // Valore di default, può essere impostato a null inizialmente
                "den": null, // Valore di default, può essere impostato a null inizialmente
                "tipo": null, // Valore di default, può essere impostato a null inizialmente
                "indirizzo": null
            };
    
            if (unita.lat != null && unita.lon != null) {
                coordinate.lat = unita.lat;
                coordinate.lon = unita.lon;
                coordinate.den = unita.denominazione;
                coordinate.tipo = unita.tipologia_unita;
                coordinate.indirizzo = unita.toponimo + " " + unita.indirizzo + " " + unita.comune + " (" + unita.provincia + ")"
    
                posizioni.push(coordinate);
            }
        });
    
        var lat = 42.5;
        var lon = 12.5;
    
        // Imposta il livello di zoom desiderato
        var zoomLevel = 4;
    
        // Aggiungi il layer di tile OpenStreetMap_Mapnik alla mappa
        var OpenStreetMap_Mapnik = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        });
    
        if (!mappaSedi) {
       
            // Crea la mappa centrata sull'Italia
            var map = L.map('map_ul').setView([lat, lon], zoomLevel);
    
            // Aggiungi il layer di tile alla mappa
            map.addLayer(OpenStreetMap_Mapnik);
    
            // Loop attraverso l'array posizioni e aggiungi un marker verde per ciascuna coordinata
            posizioni.forEach(coordinate => {
                if (coordinate.lat && coordinate.lon) {
                    var marker = L.marker([coordinate.lat, coordinate.lon], { icon: L.divIcon({ className: 'green-marker' }) })//.addTo(map);
                    // if(coordinate.tipo === "UFFICIO COMMERCIALE") marker = L.marker([coordinate.lat, coordinate.lon], { icon: L.divIcon({ className: 'red-marker' }) })//.addTo(map);
                    // if(coordinate.tipo === "UFFICIO AMMINISTRATIVO") marker = L.marker([coordinate.lat, coordinate.lon], { icon: L.divIcon({ className: 'green-marker' }) })//.addTo(map);
                    // if(coordinate.tipo === "UFFICIO") marker = L.marker([coordinate.lat, coordinate.lon], { icon: L.divIcon({ className: 'orange-marker' }) })//.addTo(map);
                    // if(coordinate.tipo === "DEPOSITO") marker = L.marker([coordinate.lat, coordinate.lon], { icon: L.divIcon({ className: 'yellow-marker' }) })//.addTo(map);
                    
                    
                    marker.addTo(map)
                    var popupContent = "<strong>"+coordinate.den+"</strong><br>" + " Tipo: " + coordinate.tipo + "<br>"+
                    "Indirizzo:" + coordinate.indirizzo;
    
                    marker.bindPopup(popupContent).openPopup();
                    //marker.bindPopup(coordinate.den + " tipo: " + coordinate.tipo).openPopup();
                }
            });
    
            // Aggiungi uno stile CSS per il marker verde
            var style = document.createElement('style');
            style.innerHTML = '.green-marker { background-color: green; border-radius: 50%; width: 2px; height: 2px; } .red-marker { background-color: red; border-radius: 50%; width: 3px; height: 3px; }' +
            '.yellow-marker { background-color: yellow; border-radius: 50%; width: 3px; height: 3px; } .orange-marker { background-color: orange; border-radius: 50%; width: 3px; height: 3px; }';
            
            //document.head.appendChild(style);
            //style.innerHTML = '.red-marker { background-color: red; border-radius: 50%; width: 5px; height: 5px; }';
            document.head.appendChild(style);
    
            // Imposta la vista della mappa in modo da visualizzare tutte le coordinate
            var bounds = L.latLngBounds(posizioni.map(coordinate => [coordinate.lat, coordinate.lon]));
    
            posizioni.forEach(coordinate => {
                if (coordinate.lat && coordinate.lon) {
                    bounds.extend([coordinate.lat, coordinate.lon]);
                }
            });
    
            map.fitBounds(bounds);
    
            // Calcola il centro dei limiti delle coordinate
            var center = bounds.getCenter();
    
            // Imposta la vista della mappa per includere tutti i marker e il centro calcolato
            map.setView(center, zoomLevel);
    }
    

    }


    setMappaSedi(true)
        
    }

      const dettagliPartecipazioni= (dettaglio) => {
        
        return (
            <>
            <Row className="shadow box-ricerca-elenco heigth-max shadow-margin-zero">
                <div className="col-sm-1 center-block-custom padding-zero">
                    <i className="fa fa-pie-chart decoration-none font-icon-35"></i>
                </div>
                <div className="col-sm-11">
                    <div className="row">
                        <div className="col-sm-12 color-gray-pg">Denominazione: <p><strong className="color-blue">{valoreDato(dettaglio.denominazione)}</strong></p></div>
                        <div className="col-sm-6 color-gray-pg">Codice Fiscale: <p><strong className="color-blue">{valoreDato(dettaglio.codice_fiscale)}</strong></p></div>
                        <div className="col-sm-6 color-gray-pg">Diritto: <p><strong className="color-blue">{valoreDato(dettaglio.quote[0].diritto)}</strong></p></div>
                        <div className="col-sm-6 color-gray-pg">Quota: <p><strong className="color-blue">{valoreDato(dettaglio.quote[0].quota_formattata)}</strong></p></div>
                        {!dettaglio.persona_giuridica &&
                            <>
                                <div className="col-sm-6 color-gray-pg">Data Nascita: <p><strong className="color-blue">{valoreDato(dettaglio.data_nascita_formattata)}</strong></p></div>
                                <div className="col-sm-6 color-gray-pg">Anni: <p><strong className="color-blue">{valoreDato(dettaglio.anni)}</strong></p></div>
                                <div className="col-sm-6 color-gray-pg">Comune Nascita: <p><strong className="color-blue">{valoreDato(dettaglio.luogo_nascita)}</strong></p></div>
                                <div className="col-sm-6 color-gray-pg">Provincia Nascita: <p><strong className="color-blue">{valoreDato(dettaglio.provincia_nascita)}</strong></p></div>
                            </>
                        }
                        
                    </div>
                </div>
                
            </Row>
            </>
          
        );
        
      }


      const applicaMargin =(indice) => {

        if(indice === 1){
            return "shadow box-ricerca-elenco heigth-max margin-left-rigth-15"
        }else{
            return "shadow box-ricerca-elenco heigth-max"
        }


      }

      const applicaMarginDueColonne =(indice) => {

        if(indice === 1){
            return "shadow box-ricerca-elenco heigth-max margin-rigth-7"
        }else{
            return "shadow box-ricerca-elenco heigth-max margin-left-7"
        }


      }

      const handleTabSelect = () => {
        // La tua logica qui
        console.log('Tab "sedi" selezionato');
        console.log(datiDettaglioAzienda.sezione_unita_locali.unita_locali.unita_locali)
        dettaglioPosizioniSedi(datiDettaglioAzienda.sezione_unita_locali.unita_locali.unita_locali)
      };

      const handleRichiestaProdotto = (report, codicefiscale, denominazione) =>{
        setIsLoading(true)
        console.log("report " + report + " codice fiscale " + codicefiscale)

        let datiricerca = {
            "id_report": report,
            "codice_fiscale": codicefiscale,
            "nome_anagrafica": denominazione
        }

        richiestaProdotto(datiricerca).then((response) => {
            console.log(response)
            if (response != null && response.data.message === "OK" && response.data.status === 200) {
                //risposta positiva
                setRichiestaDocumento(false)
                let messaggio = "La richiesta è andata a buon fine. Il servizio sarà disponibile tra pochi minuti nella sezione archivio"
                setMessaggioRichiestaPositiva(messaggio)
                setshowConfermaRichiestaElenco(true)
                setIsLoading(false)

            }else if(response != null && response.data.message === "OK" && response.data.status === 203){
                // setRichiestaDocumento(false)
                // setMessaggioErrore(response.data.response)
                // setErrore(true)
                
                setRichiestaDocumento(false)
                setshowConfermaRichiestaDocumento(true)
                let messaggio = "La richiesta è andata a buon fine. Il servizio sarà disponibile tra pochi minuti nella sezione archivio"
                setResponseConferma(response.data)
                setshowConfermaRichiestaDocumento(true)
                setIsLoading(false)
                
                //risposta non sufficienti crediti
            }else{
                //risposta errore
                setShowDisponibilita(false)
                let messaggio_errore = response.data.response
                if(response.data.response == null || response.data.response === '') messaggio_errore = "Si è verificato un errore generico!"
                setRichiestaDocumento(false)
                setMessaggioErrore(messaggio_errore)
                setErrore(true)
                setIsLoading(false)

            }

        })


      }

      const handleConfermaRichiesta = (report) =>{
        setTipoReport(report)
        setRichiestaDocumento(true)
      }

    
    return (
        <Col md={12} xl={12} xs={12} className=" mb-4 shadow">
        {isLoading ? <LoadingSpinner /> : isLoading}
        {datiDettaglioAzienda != null && datiDettaglioAzienda.ragione_sociale != null &&
        <>
            <Row>
                <Card.Body>
                    <Row>
                        <Col md={8} xl={8} xs={12}>
                            <h4>{datiDettaglioAzienda.ragione_sociale}</h4>
                        </Col>
                        <Col md={4} xl={4} xs={12} className="text-align-right">
                        <Button variant="success" className="" disabled={datiDettaglioAzienda.link_stampa_dettaglio_pdf == null} onClick={() => handleReportDisponibilita(REPORT_SMART)}><i className="fa-solid fa-print"></i> Report Smart</Button>
                                        <span>{" "}</span>
                        <Button variant="info" className=""  onClick={() => handleListaIndietro()}><i className="fa-solid fa-long-arrow-left"></i> Lista</Button>
                        
                        </Col>
                    </Row>
                </Card.Body>
            </Row>
            <Row className="dettaglio">
                <Card.Body>
                    <Tabs
                            defaultActiveKey="anagrafica"
                            //activeKey={showTipoRicerca}
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            fill
                        >
                        <Tab eventKey="anagrafica" className="dettaglio" title={<span className="color-gray-pg-2"><i className="fa fa-list decoration-none"></i> Anagrafica</span>}>
                            <Row>
                            <Col md={8} xl={8} xs={12} className="padding-bottom20">
                                <Row className="shadow box-ricerca-elenco row heigth-max">
                                    <div id="map" className="dimensione_mappa"></div>
                                </Row>
                            </Col>
                            <Col md={4} xl={4} xs={12} className="padding-bottom20">
                                    <Row className="shadow box-ricerca-elenco row shadow-margin-zero heigth-max">
                                        <div className="col-sm-12"><h5>Contatti</h5></div>
                                        <div className="col-sm-12 color-gray-pg">Telefono: <p><strong className="color-blue">{valoreDato(datiDettaglioAzienda.sezione_anagrafica_contatti.contatti.telefono)}</strong></p></div>
                                        <div className="col-sm-12 color-gray-pg">Email: <p><strong className="color-blue">{valoreDato(datiDettaglioAzienda.sezione_anagrafica_contatti.contatti.email)}</strong></p></div>
                                        <div className="col-sm-12 color-gray-pg">Pec: <p><strong className="color-blue">{valoreDato(datiDettaglioAzienda.sezione_anagrafica_contatti.contatti.pec)}</strong></p></div>
                                        <div className="col-sm-12 color-gray-pg">Sito Web: <p><strong className="color-blue">{valoreDato(datiDettaglioAzienda.sezione_anagrafica_contatti.contatti.sito_web)}</strong></p></div>
                                        
                                    </Row>
                                </Col>

                            


                                <Col md={8} xl={8} xs={12} className="padding-bottom20">
                                    <Row className="shadow box-ricerca-elenco row heigth-max">
                                        <div className="col-sm-12"><h5>Dati Azienda</h5></div>
                                        <div className="col-sm-6 color-gray-pg">Codice Fiscale: <p><strong className="color-blue">{valoreDato(datiDettaglioAzienda.sezione_anagrafica_contatti.anagrafica.codice_fiscale)}</strong></p></div>
                                        <div className="col-sm-6 color-gray-pg">Partita Iva: <p><strong className="color-blue">{valoreDato(datiDettaglioAzienda.sezione_anagrafica_contatti.anagrafica.partita_iva)}</strong></p></div>
                                        <div className="col-sm-6 color-gray-pg">Cciaa NRea: <p><strong className="color-blue">{valoreDato(datiDettaglioAzienda.sezione_anagrafica_contatti.anagrafica.cciaa_nrea)}</strong></p></div>
                                        <div className="col-sm-6 color-gray-pg">Natura Giuridica: <p><strong className="color-blue">{valoreDato(datiDettaglioAzienda.sezione_anagrafica_contatti.anagrafica.descrizione_natura_giuridica)}</strong></p></div>
                                        <div className="col-sm-6 color-gray-pg">Stato: <p><strong className="color-blue">{valoreDato(datiDettaglioAzienda.sezione_anagrafica_contatti.anagrafica.descrizione_stato_attivita)}</strong></p></div>
                                        <div className="col-sm-6 color-gray-pg">Data Inizio Attività: <p><strong className="color-blue">{valoreDato(datiDettaglioAzienda.sezione_anagrafica_contatti.anagrafica.data_inizio_attivita_formattata)}</strong></p></div>
                                        <div className="col-sm-6 color-gray-pg">Numero Addetti: <p><strong className="color-blue">{valoreDato(datiDettaglioAzienda.sezione_anagrafica_contatti.anagrafica.addetti)}</strong></p></div>
                                        <div className="col-sm-6 color-gray-pg">Descrizione Settore: <p><strong className="color-blue">{valoreDato(datiDettaglioAzienda.sezione_anagrafica_contatti.anagrafica.settore_genio)}</strong></p></div>
                                        <div className="col-sm-6 color-gray-pg">Codice Ateco (Primario): <p><strong className="color-blue">{valoreDato(datiDettaglioAzienda.sezione_anagrafica_contatti.anagrafica.codice_ateco_primario)}</strong></p></div>
                                        <div className="col-sm-6 color-gray-pg">Codice Ateco (Secondari): <p><strong className="color-blue">{valoreDato(datiDettaglioAzienda.sezione_anagrafica_contatti.anagrafica.codici_ateco_secondari)}</strong></p></div>
                                        <div className="col-sm-6 color-gray-pg">Sae Rae: <p><strong className="color-blue">{valoreDato(datiDettaglioAzienda.sezione_anagrafica_contatti.anagrafica.sae_rae)}</strong></p></div>
                                    </Row>
                                </Col>
                                <Col md={4} xl={4} xs={12} className="padding-bottom20">
                                    
                                    <Row className="shadow box-ricerca-elenco row shadow-margin-zero heigth-max">
                                        <div className="col-sm-12"><h5>Indice Attenzione</h5></div>
                                        {datiDettaglioAzienda.sezione_anagrafica_contatti.anagrafica.score_credit_worthiness != null ?
                                        <>
                                            <DonutChartSemi  idgrafico="ch1" dati={dati_evidenze} colori={sceltaColore(datiDettaglioAzienda.sezione_anagrafica_contatti.anagrafica.score_credit_worthiness)} vuoto={false} />
                                            
                                        </>
                                            
                                        :
                                            <DonutChartSemi  idgrafico="ch1" dati={dati_evidenze} colori={color_empty} vuoto={true} score={null} />
                                        }
                                            <div className={classeTestoIndice(datiDettaglioAzienda.sezione_anagrafica_contatti.anagrafica.score_credit_worthiness)}>
                                                <h4>{valoreDato(datiDettaglioAzienda.sezione_anagrafica_contatti.anagrafica.score_credit_worthiness)}</h4>
                                            </div>
                                        
                                        <div className="col-sm-12"><h6 className="align-center boldtesto">{valoreDato(datiDettaglioAzienda.sezione_anagrafica_contatti.anagrafica.score_credit_worthiness_conclusioni)}</h6></div>
                                        
                                    </Row> 
                                </Col>
                                
                            </Row>
                        </Tab>
                        <Tab eventKey="bilanci" disabled={datiDettaglioAzienda.sezione_bilanci.bilanci.numero_bilanci === 0} title={<span className="color-gray-pg-2"><i className="fa fa-line-chart decoration-none"></i> Bilanci</span>}>
                            <Row className="shadow box-ricerca-elenco row heigth-max shadow-margin-zero">
                                <Col md={12} xl={12} xs={12} className="padding-bottom20-">
                                    <Row>
                                        <div className="col-sm-12"><h5>Sintesi Area Bilancio</h5></div>
                                        {/* <div className="col-sm-4"><h6>{numeroBilanci(datiDettaglioAzienda.sezione_bilanci.bilanci.numero_bilanci)}</h6></div> */}
                                        <div className="col-sm-4 color-gray-pg">Bilanci: <p><strong className="color-blue">{numeroBilanci(datiDettaglioAzienda.sezione_bilanci.bilanci.numero_bilanci)}</strong></p></div>
                                        {ricaviInfo(datiDettaglioAzienda.sezione_bilanci.bilanci.trend_ricavi)}
                                        {debitiInfo(datiDettaglioAzienda.sezione_bilanci.bilanci.trend_debiti)}
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                        {datiDettaglioAzienda.sezione_bilanci.bilanci.numero_bilanci > 2 && 
                                            datiDettaglioAzienda.sezione_bilanci.bilanci.bilanci.map((bilancio, i) =>{
                                                
                                                return(<Col  md={4} xl={4} xs={12} className="padding-bottom20">
                                                            {/* <Row className={applicaMargin(i)}> */}
                                                            <Row className="shadow box-ricerca-elenco heigth-max shadow-margin-zero">
                                                                {dettaglioBilanncio(bilancio)}
                                                            </Row>
                                                        </Col>
                                                )
                                            })
                                        }
                                        {datiDettaglioAzienda.sezione_bilanci.bilanci.numero_bilanci === 2 && 
                                            <>
                                            <Col  md={6} xl={6} xs={12} className="padding-bottom20">
                                                            <Row className={applicaMarginDueColonne(1)}>
                                                            {dettaglioBilanncio(datiDettaglioAzienda.sezione_bilanci.bilanci.bilanci[0])}
                                                            </Row>
                                                        </Col>
                                                        <Col  md={6} xl={6} xs={12} className="padding-bottom20">
                                                        <Row className={applicaMarginDueColonne(2)}>
                                                        {dettaglioBilanncio(datiDettaglioAzienda.sezione_bilanci.bilanci.bilanci[1])}
                                                        </Row>
                                                    </Col>
                                            </>
                                        }
                                        
                                    </Row>
                            {datiDettaglioAzienda.sezione_bilanci.bilanci.voci_dettaglio_per_anno != null && 
                                datiDettaglioAzienda.sezione_bilanci.bilanci.voci_dettaglio_per_anno.map((dettaglio, i) =>{
                                return(
                                    <div key={i}>
                                        {dettagliVoce(dettaglio, anni, "chart" + i)}
                                    </div>
                                )
                                
                            })  
                            }
                        </Tab>
                        <Tab eventKey="esponenti" disabled={datiDettaglioAzienda.sezione_bilanci.bilanci.numero_bilanci === 0} title={<span className="color-gray-pg-2"><i className="fa fa-user decoration-none"></i> Esponenti</span>}>
                            <Row className="shadow box-ricerca-elenco row heigth-max shadow-margin-zero">
                                <Col md={12} xl={12} xs={12} className="padding-bottom20-">
                                    <Row>
                                        <div className="col-sm-12"><h5>Sintesi Area Esponenti</h5></div>
                                        <div className="col-sm-4 color-gray-pg">
                                            Numero Soci: <p><strong className="color-blue"><i className="fa fa-user decoration-none"></i> {datiDettaglioAzienda.sezione_soci.soci.numero_soci}</strong></p></div>
                                        <div className="col-sm-4 color-gray-pg">Numero Esponenti: <p><strong className="color-blue"><i className="fa fa-address-card decoration-none"></i> {datiDettaglioAzienda.sezione_esponenti.esponenti.numero_esponenti}</strong></p></div>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="">
                                <Col md={12} xl={12} xs={12} className="padding-bottom20">
                                    <Row>
                                        {/* <div className="col-sm-12"><h5>Soci</h5></div> */}
                                        {datiDettaglioAzienda.sezione_soci.soci.numero_soci > 0 && datiDettaglioAzienda.sezione_soci.soci.soci.map((socio, i) =>{
                                            return(
                                                <div key={i} className="col-sm-6 padding-bottom20">
                                                    {dettagliSoci(socio)}
                                                </div>
                                            )

                                        })}
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="">
                                <Col md={12} xl={12} xs={12} className="padding-bottom20">
                                    <Row>
                                        {/* <div className="col-sm-12"><h5>Soci</h5></div> */}
                                        {datiDettaglioAzienda.sezione_esponenti.esponenti.numero_esponenti > 0 && datiDettaglioAzienda.sezione_esponenti.esponenti.esponenti.map((socio, i) =>{
                                            return(
                                                <div key={i} className="col-sm-6 padding-bottom20">
                                                    {dettagliEsponenti(socio)}
                                                </div>
                                            )

                                        })}
                                    </Row>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab onEnter={() => handleTabSelect()} eventKey="sedi" title={<span className="color-gray-pg-2"><i className="fa fa-building decoration-none"></i> Sedi</span>}>
                            {datiDettaglioAzienda.sezione_unita_locali.unita_locali.numero_unita_locali > 0 &&  
                                <Row className="shadow box-ricerca-elenco row heigth-max">
                                    <div id="map_ul" className="dimensione_mappa_ul"></div>
                                </Row>
                            }
                            
                            <Row className="shadow box-ricerca-elenco heigth-max shadow-margin-zero larghezza-100">
                                <Col md={12} xl={12} xs={12} className="">
                                    <Row>
                                        <div className="col-sm-12"><h5> Sintesi Unità Locali:</h5></div>
                                        <div className="col-sm-2 color-gray-pg">
                                            Numero: <p><strong className="color-blue"><i className="fa fa-building decoration-none"></i> {datiDettaglioAzienda.sezione_unita_locali.unita_locali.numero_unita_locali}</strong></p></div>
                                        {/* {<div className="col-sm-2">
                                        Ufficio Amministrativo: <p className="green-icon"></p></div>
                                        <div className="col-sm-2">
                                        Ufficio Commerciale: <p className="red-icon"></p></div>
                                        <div className="col-sm-2">
                                        Ufficio: <p className="orange-icon"></p></div>
                                        <div className="col-sm-2">
                                        Magazzino: <p className="yellow-icon"></p></div>} */}
                                    </Row>
                                </Col>
                            </Row> 
                            {/* <Row className="shadow box-ricerca-elenco heigth-max shadow-margin-zero"></Row> */}
                            <Row className="shadow-margin-zero">
                                
                                    <Row>
                                        {/* <div className="col-sm-12"><h5>Soci</h5></div> */}
                                        {datiDettaglioAzienda.sezione_unita_locali.unita_locali.numero_unita_locali > 0 && datiDettaglioAzienda.sezione_unita_locali.unita_locali.unita_locali.map((unita, i) =>{
                                            return(
                                                <div key={i} className="col-sm-6 padding-bottom20">
                                                    {dettagliUnitaLocali(unita)}
                                                </div>
                                            )

                                        })}
                                        {datiDettaglioAzienda.sezione_unita_locali.unita_locali.numero_unita_locali === 0 &&    
                                            <Row className="shadow box-ricerca-elenco heigth-max shadow-margin-zero larghezza-100">
                                                <div className="col-sm-12"><h5 className="larghezza-100">Non presenti unità locali</h5></div>
                                            </Row>
                                        }
                                    </Row>
                                
                            </Row>

                        </Tab>
                        {/* <Tab eventKey="soci" title={<span><i className="fa fa-line-chart decoration-none"></i> Soci</span>}></Tab> */}
                        <Tab eventKey="partecipazioni" title={<span className="color-gray-pg-2"><i className="fa fa-pie-chart decoration-none"></i> Partecipazioni</span>}>
                            <Row className="shadow box-ricerca-elenco row heigth-max shadow-margin-zero">
                                <Col md={12} xl={12} xs={12} className="padding-bottom20-">
                                    <Row>
                                        <div className="col-sm-12"><h5>Sintesi Partecipazioni</h5></div>
                                        <div className="col-sm-4 color-gray-pg">
                                            Numero Partecipazioni: <p><strong className="color-blue"><i className="fa fa-user decoration-none"></i> {datiDettaglioAzienda.sezione_partecipazioni.partecipazioni.numero_partecipazioni}</strong></p></div>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="">
                                <Col md={12} xl={12} xs={12} className="padding-bottom20">
                                    <Row>
                                        {/* <div className="col-sm-12"><h5>Soci</h5></div> */}
                                        {datiDettaglioAzienda.sezione_partecipazioni.partecipazioni.numero_partecipazioni > 0 && datiDettaglioAzienda.sezione_partecipazioni.partecipazioni.partecipazioni.map((partecipazioni, i) =>{
                                            return(
                                                <div key={i} className="col-sm-6 padding-bottom20">
                                                    {dettagliPartecipazioni(partecipazioni)}
                                                </div>
                                            )

                                        })}
                                    </Row>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="prodotti" title={<span className="color-gray-pg-2"><i className="fa-regular fa-file-pdf decoration-none padding-bottom20"></i> Documenti</span>}>
                            <Row className="">
                                <Col md={12} xl={12} xs={12} className="padding-bottom20">
                                    <Row className="shadow box-ricerca-elenco row heigth-max shadow-margin-zero">
                                        <Col md={12} xl={12} xs={12} className="padding-bottom20-">
                                            <Row>
                                                <div className="col-sm-12"><h5>Documenti Richiedibili</h5></div>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="">
                            <Col md={4} xl={4} xs={12} className="padding-bottom20">
                                    <Row className="shadow box-ricerca-elenco row heigth-max shadow-margin-zero">
                                        <div className="col-sm-12">
                                            <h5 onClick={() => handleReportDisponibilita(REPORT_SMART)} className="posizioni-lista cursor-pointer">Report Smart</h5>
                                        </div>
                                        <div className="col-sm-6"></div>
                                        <div className="col-sm-12">
                                            <p><strong>Cosa contiene:</strong></p>
                                                <ul>
                                                    <li>Anagrafica azienda</li>
                                                    <li>Dettaglio sedi</li>
                                                    <li>Dati riepilogo bilancio</li>
                                                    <li>Dati Soci ed Esponenti</li>
                                                </ul>
                                            <p></p>
                                        </div>
                                        <div className="col-md-12 align-right">
                                            <Button variant="success" className="" 
                                            disabled={false} 
                                            onClick={() => handleReportDisponibilita(REPORT_SMART)}>
                                                <i className="fa-solid fa-shopping-cart"></i> Richiedi Report</Button>
                                            {"     "}
                                            {/* <Button variant="danger" className="" disabled={listaSalvate === null || (listaSalvate != null && listaSalvate.length === 0)} onClick={() => handleEliminaRicerca(lista.id_lista)}><i className="fa-solid fa-trash"></i> Elimina</Button> */}
                                        </div>
                                        
                                    </Row>
                                </Col>
                                <Col md={4} xl={4} xs={12} className="padding-bottom20">
                                    <Row className="shadow box-ricerca-elenco row heigth-max shadow-margin-zero">
                                        <div className="col-sm-12">
                                            <h5 onClick={() => handleReportDisponibilita(REPORT_DEEP)} className="posizioni-lista cursor-pointer">Deep Dossier</h5>
                                        </div>
                                        <div className="col-sm-6"></div>
                                        <div className="col-sm-12">
                                            <p><strong>Cosa contiene:</strong></p>
                                                <ul>
                                                    <li>Scheda Azienda</li>
                                                    <li>Negatività</li>
                                                    <li>Ultimi 3 bilinci con commenti</li>
                                                    <li>Scheda Soci ed Esponenti</li>
                                                </ul>
                                            <p></p>
                                        </div>
                                        <div className="col-md-12 align-right">
                                            <Button variant="success" className="" 
                                            disabled={false} 
                                            onClick={() => handleReportDisponibilita(REPORT_DEEP)}>
                                                <i className="fa-solid fa-shopping-cart"></i> Richiedi Report</Button>
                                            {"     "}
                                            {/* <Button variant="danger" className="" disabled={listaSalvate === null || (listaSalvate != null && listaSalvate.length === 0)} onClick={() => handleEliminaRicerca(lista.id_lista)}><i className="fa-solid fa-trash"></i> Elimina</Button> */}
                                        </div>
                                        
                                    </Row>
                                </Col>
                                <Col md={4} xl={4} xs={12} className="padding-bottom20">
                                    <Row className="shadow box-ricerca-elenco row heigth-max shadow-margin-zero">
                                        <div className="col-sm-12">
                                            <h5 onClick={() => handleReportDisponibilita(REPORT_EXECUTIVE)} className="posizioni-lista cursor-pointer">Executive Report</h5>
                                        </div>
                                        <div className="col-sm-6"></div>
                                        <div className="col-sm-12">
                                            <p><strong>Cosa contiene:</strong></p>
                                                <ul>
                                                    <li>Scheda Azienda e Negatività</li>
                                                    <li>Ultimi 3 bilanci con commenti</li>
                                                    <li>Scheda Soci ed Esponenti</li>
                                                    <li>Conclusioni e Fido</li>
                                                </ul>
                                            <p></p>
                                        </div>
                                        <div className="col-md-12 align-right">
                                            <Button variant="success" className="" 
                                            disabled={false} 
                                            onClick={() => handleReportDisponibilita(REPORT_EXECUTIVE)}>
                                                <i className="fa-solid fa-shopping-cart"></i> Richiedi Report</Button>
                                            {"     "}
                                            {/* <Button variant="danger" className="" disabled={listaSalvate === null || (listaSalvate != null && listaSalvate.length === 0)} onClick={() => handleEliminaRicerca(lista.id_lista)}><i className="fa-solid fa-trash"></i> Elimina</Button> */}
                                        </div>
                                        
                                    </Row>
                                </Col>
                            </Row>
                            <ConfirmationVisura 
                                buttonNegative="Annulla" 
                                buttonPositive="Continua" 
                                title="Richiesta documento" 
                                show={showRichiestaDocumento} 
                                body={"Sei sicuro di voler richiedere questo documento?"} 
                                onNegative={() => setRichiestaDocumento(false)} 
                                onPositive={() => handleRichiestaProdotto(tipoReport, datiDettaglioAzienda.sezione_anagrafica_contatti.anagrafica.codice_fiscale, datiDettaglioAzienda.ragione_sociale)} />

                            
                                <ConfirmationDifferita
                                    buttonNegative="Chiudi" 
                                    buttonPositive="Vedi Ticket" 
                                    title="Richiesta Documento" 
                                    show={showConfermaRichiestaElenco} 
                                    body={messaggioRichiestaPositiva} 
                                    onNegative={() => setshowConfermaRichiestaElenco(false)}
                                    onPositive={handleServerRedirectDifferita}  
                                />
                                <ConfirmationDifferitaReport
                                    buttonNegative="Chiudi" 
                                    buttonPositive="Vedi Ticket" 
                                    title="Richiesta Documento" 
                                    show={showConfermaRichiestaDocumento} 
                                    body={responseConferma} 
                                    onNegative={() => setshowConfermaRichiestaDocumento(false)}
                                    onPositive={handleServerRedirectDifferita}  
                                />
                                <ConfirmationControlloDisponibilita
                                    buttonNegative="Chiudi" 
                                    buttonPositive="Conferma" 
                                    title="Richiesta Documento" 
                                    show={showDisponibilita} 
                                    body={responseDisponibilita} 
                                    onNegative={() => setShowDisponibilita(false)}
                                    onPositive={() => handleConfermaReportSmart(reportRichiesta)}  
                                />
                        </Tab>
                    </Tabs>
                </Card.Body>
            </Row>
        </>
        }
        { datiDettaglioAzienda == null &&

        
            <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
                    <div className="col-sm-9">
                        <h4 className="position-messaggio-errore">Dettaglio non disponibile</h4>
                    </div>
                    <div className="col-sm-3 text-align-right">
                        
                        <>
                        <Button variant="info" className=""  onClick={() => handleListaIndietro()}><i className="fa-solid fa-long-arrow-left position-messaggio-errore"></i> Lista</Button>
                        </>
                                        
                    </div>
            </Row>
        
        }
        
        {datiDettaglioAzienda != null && datiDettaglioAzienda.ragione_sociale == null &&

            <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
            <div className="col-sm-9">
                <h4 className="position-messaggio-errore">Si è verficato un errore nella richiesta del dettaglio, oppure non è al momento disponibile</h4>
            </div>
            <div className="col-sm-3 text-align-right">
                
                <>
                <Button variant="info" className=""  onClick={() => handleListaIndietro()}><i className="fa-solid fa-long-arrow-left position-messaggio-errore"></i> Lista</Button>
                </>
                                
            </div>
            </Row>

        }
        <ModalErrorGenerico buttonNegative="Chiudi" 
                                    buttonPositive="Chiudi" 
                                    title="Ricerca Vuota" 
                                    show={showErrore} 
                                    body={showMessaggioErrore} //"La richiesta del Bilancio non è stata effettuata con successo. Riprovare e se il problema persiste contattare il servizio assistenza. Grazie"
                                    onNegative={() => setErrore(false)} 
                                    onPositive={() => setErrore(false)}
                                />
            
        </Col>
        
    );
};

