import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setElenco_Imprese_EstesoList, setElenco_Imprese_EstesoMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import { setElenco_Imprese_Esteso_GraficiList, setElenco_Imprese_Esteso_GraficiMessage } from "redux/slices/elenco_imprese_esteso_grafici";
import { useAppDispatch } from "redux/store";
import { getElenco_Imprese_Esteso } from "services/elenco_imprese_estesoService";
import { getDatiEstrazioneBilancioPerProvincia } from "services/elenco_imprese_esteso_estrazioneService";
import Layout from "template";
import { Constant } from "template/Constant";
import { Elenco_Imprese_GraficiForm } from "./form";
import { Elenco_Imprese_GraficiTable } from "./table";

export const Elenco_Imprese_Esteso_Grafici: React.FC = () => {
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.elenco_imprese_esteso_grafici);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState('');
  const getData = (page, pageSize, searchKey) => {
    getElenco_Imprese_Esteso(page, pageSize, searchKey).then((response) => {
      if (response && response.records) {
        dispatch(setElenco_Imprese_EstesoList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
      } else {
        dispatch(setElenco_Imprese_EstesoMessage("Nessun dato presente in questa sezione"));
      }
    })
  }

  const getData_bilancio = (page, pageSize, searchKey,data) => {
    getDatiEstrazioneBilancioPerProvincia(page, pageSize, searchKey, "31/12/2021").then((response) => {
      if (response && response.records) {
        dispatch(setElenco_Imprese_Esteso_GraficiList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
      } else {
        dispatch(setElenco_Imprese_Esteso_GraficiMessage("Nessun dato presente in questa sezione"));
      }
    })
  }


  const handleRowEdit = (rowData) => {
    setRow(rowData);
    dispatch(setElenco_Imprese_EstesoMessage(''));
    setAction('edit');
  }
  return (
    <Layout>
      <div className="container-fluid  paddin-left-0">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Elenco_Imprese_Esteso test</h1>
        </div>
        <div className="d-flex flex-column min-vh-100">
          {rData.message ?
            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{rData.message}
              <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setElenco_Imprese_EstesoMessage(''))}>
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert> : null}
          {action ? <Elenco_Imprese_GraficiForm hideShowForm={setAction} action={action} row={row} getData={getData} /> :
            <Elenco_Imprese_GraficiTable handleRowEdit={handleRowEdit} hideShowForm={setAction} getData={getData} getData_bilancio={getData_bilancio}/>}
        </div>

      </div>
    </Layout >
  );
};

