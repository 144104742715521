import LineChart from "components/LineChart";
import { UserData } from "Data";
import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setAreaGeograficaList, setAreaGeograficaMessage, setCodici_AtecoList, setCodici_AtecoMessage, setComuni_CatastaliList, setComuni_CatastaliMessage, setTrovaImpresaList, setTrovaImpresaMessage, setWs_ProvincieinfocamereList, setWs_ProvincieinfocamereMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getRicercaImpresaCodiceFiscale, getRicercaImpresaDenominazione, getRicercaImpresaPartitaIva, getTrovaImpresa } from "services/ricerca_impresa_service";
import Layout from "template";
import { MenuItems } from "template/MenuItems";
import { DashboardCard } from "./DasboardCard";
import { RicercaUnica} from "./RicercaUnica";
import Spinner from 'react-bootstrap/Spinner';
import LoadingSpinner from "../LoadingSpinner";
import { getAllWs_Provincieinfocamere, getWs_Provincieinfocamere } from "services/ws_provincieinfocamereService";
import { getVisuraCamerale } from "services/richiesta_visura";
import { getComuni_Catastali } from "services/comuni_catastaliService";
import { getCodici_Ateco } from "services/codici_atecoService";
import ModalErrorGenerico from "template/ModalErrorGenerico";
import ConfirmationDatiObbligatoriElenco from "template/ConfirmationDatiObbligatoriElenco";
import { getDatiAreaGeografica } from "services/ricercaUnicaService";


export const RicercaUnicaCompleta: React.FC = () => {
  const dispatch = useAppDispatch();
  const token = localStorage.getItem('token');
  let nome_azienda = "";
  let partita_iva = "";
  const [action, setAction] = useState('');
  const rData = useSelector((state: RootState) => state.trova_impresa);
  const rDataProvincia = useSelector((state: RootState) => state.ws_provincieinfocamere);
  const rDataComune = useSelector((state: RootState) => state.comuni_catastali);
  const rDataAteco = useSelector((state: RootState) => state.codici_ateco);
  const rDataAreaGeografica = useSelector((state: RootState) => state.area_geografica);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorGenerico, setErroreGenerico] = useState(false);
  const [showRicercaVuota, setRicercaVuota] = useState(false);
  const [showMessaggioGenerico, setMessaggioGenerico] = useState(false);
  const handleServerDelete = async () => {}


  let numero_imprese = 0;
  const getData = (page, pageSize, searchKey, datiricerca) => {
    setIsLoading(true);
    if(datiricerca.tiporicerca === 1){
      getRicercaImpresaDenominazione(datiricerca).then((response)=> {
        if (response && response.data.response != null && response.data.status === 200) {
          numero_imprese = response.data.NumeroPosizioniTotali
          console.log(response.data.response.imprese)
          dispatch(setTrovaImpresaList({ pageNo: page, pageSize: pageSize, list: response.data.response.imprese, totalCount: response.data.NumeroPosizioniTotali, searchKey: searchKey }));
          setIsLoading(false);
        }else if(response && response.data.response != null && response.data.status === 201){
          setIsLoading(false);
          setRicercaVuota(true);
        } else {
          setMessaggioGenerico(response.data.response)
          setErroreGenerico(true)
          dispatch(setTrovaImpresaMessage("Nessun dato presente in questa sezione"));
          setIsLoading(false);
        }
      })
    }
  }
  const getDataComuni = (page, pageSize) => {
    //setIsLoading(true);
    getComuni_Catastali(1, 9000, '').then((response)=> {
      if (response && response.records) {
        dispatch(setComuni_CatastaliList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: ''}));
        //setIsLoading(false);
      } else {
        dispatch(setComuni_CatastaliMessage("Nessun dato presente in questa sezione"));
        setIsLoading(false);
      }
    })
  }

  const getDataCodiceAteco = async (page, pageSize) => {
    //setIsLoading(true);
    getCodici_Ateco(1, 1000, '').then((response)=> {
      if (response && response.records) {
        dispatch(setCodici_AtecoList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: ''}));
        //setIsLoading(false);
      } else {
        dispatch(setCodici_AtecoMessage("Nessun dato presente in questa sezione"));
        setIsLoading(false);
      }
    })
  }

  const getDatiVisura = (cciaa, nrea, nomeanagrafica, idprodotto, tipovisura) => {
    setIsLoading(true);
    var datiricerca = {
      "cciaa": cciaa,
      "nrea": nrea,
      "nomeanagrafica": nomeanagrafica,
      "idprodotto": idprodotto,
      "tipovisura": tipovisura
    }
    getVisuraCamerale(datiricerca).then((response) => {
      if (response != null && response.data.message === "OK") {
        //dispatch(setElenco_Imprese_Esteso_EstrazioneList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
        //dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Visura Camerale Richiesta"));
        setIsLoading(false);
      } else {
        //dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Visura non richiedibile"));
        setMessaggioGenerico(response.data.response)
        setIsLoading(false);
        setErroreGenerico(true)
        
      }
    })
  }


  const [userData, setUserData] = useState({
    labels: UserData.map((data) => data.year),
    datasets: [
      {
        fill: true,
        label: "Users Gained",
        data: UserData.map((data) => data.userGain),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  });


  function parseJwt(token) {
    try {
      // Get Token Header
      const base64HeaderUrl = token.split('.')[0];
      const base64Header = base64HeaderUrl.replace('-', '+').replace('_', '/');
      const headerData = JSON.parse(window.atob(base64Header));
  
      // Get Token payload and date's
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const dataJWT = JSON.parse(window.atob(base64));
      dataJWT.header = headerData;
  
      // TODO: add expiration at check ...
      return dataJWT;
    } catch (err) {
      return false;
    }
  }
  
  const jwtDecoded = parseJwt(token) ;
  if(jwtDecoded)
  {
      console.log(jwtDecoded.data.id)
      nome_azienda = jwtDecoded.data.nome_azienda;
      partita_iva = jwtDecoded.data.partita_iva;
      ;

  }

  useEffect(() => {
    //getDataAreaGeografica();
    //getDataCodiceAteco(1, 10000);
    //getDataComuni(1, 10000);
  }, []);
  
  
  return (
    <Layout>
      {isLoading ? <LoadingSpinner /> : isLoading}
      <div className="container-fluid  paddin-left-0" >
        {/* <div className="d-sm-flex align-items-center justify-content-between mb-4" >
          <h1 className="h3 mb-0 text-gray-800" > Dashboard </h1>
        </div> */}
        <div className="d-flex flex-column min-vh-100" >
          {/* <Row>
            <div className="col-sm-12">
              <p>Benvenuto: {nome_azienda}</p>
            </div>
            
          </Row> */}
          <Row>
            <RicercaUnica getData={getData} numero_imprese={numero_imprese} getDatiVisura={getDatiVisura} getDataComuni={getDataComuni} /> 
          </Row>

          <ModalErrorGenerico buttonNegative="Chiudi" 
                                buttonPositive="Chiudi" 
                                title="Errore Generico Nella Richiesta" 
                                show={showErrorGenerico} 
                                body="Abbiamo rilevato un errore generico nella richiesta. Riprovare, se il problema persiste contattare il servio vliente. Grazie"
                                onNegative={() => setErroreGenerico(false)} 
                                onPositive={handleServerDelete} />
          <ModalErrorGenerico buttonNegative="Chiudi" 
                                buttonPositive="Chiudi" 
                                title="Ricerca Vuota" 
                                show={showRicercaVuota} 
                                body="La ricerca non ha prodotto risultati. Riprovare con altri parametri. Grazie"
                                onNegative={() => setRicercaVuota(false)} 
                                onPositive={handleServerDelete} />
          <ConfirmationDatiObbligatoriElenco 
                            buttonNegative="Continua" 
                            buttonPositive="Continua" 
                            title="Ricerca Impresa Puntuale" 
                            show={showErrorGenerico} 
                            body={showMessaggioGenerico} 
                            onNegative={() => setErroreGenerico(false)} 
                            />
          {/* <Row>
            {MenuItems.map((item, i) => {
              return <DashboardCard key={`Card-${i}`} name={item.title} path={item.path} />
            })}
          </Row> */}
        </div>
      </div>
      </Layout >
  );
};

