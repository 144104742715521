import React, { useState } from 'react';
import menu_icon from "../../asset/icon_menu.png"

const Navbar = ({ logo }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const MenuItems = () => (
    <>
      <p className="font-menu cursor-pointer hover:text-blue-500 transition-colors">Servizi</p>
      <p className="font-menu cursor-pointer hover:text-blue-500 transition-colors">Prezzi</p>
      <p className="font-menu cursor-pointer hover:text-blue-500 transition-colors">Chi Siamo</p>
    </>
  );

  return (
    <>
      {/* Desktop Navigation */}
      <div className="nav-desktop row menu-basic align-items-center px-custom">
      
                <div className="col-sm-6">
                    <img src={logo} alt="" className="logo-public" />
                </div>
                <div className="col-sm-6">
                    <div className="d-flex justify-content-end gap-3">
                        <p className="font-menu">Servizi</p>
                        <p className="font-menu">Prezzi</p>
                        <p className="font-menu">Chi Siamo</p>
                    </div>
                </div>
             
      </div>

      {/* Mobile Navigation */}
      <div className="nav-mobile row menu-basic align-items-center px-custom relative paddind-lr-m-15 min-height-auto">
       <div className='row full-width'>
       <div className="col-sm-6 dim-custom-80">
          <img src={logo} alt="Logo" className="logo-public" />
        </div>
        <div className="col-sm-6 dim-custom-20 flex text-center">
          {/* <button onClick={toggleMobileMenu} className="z-50 relative">
            {isMobileMenuOpen ? <img src={menu_icon} /> : <img src={menu_icon} />}
          </button> */}
          <i className="fa fa-bars icon-bar-sm" onClick={toggleMobileMenu}></i>
        </div>
       </div>
        

        {/* Mobile Menu Overlay */}
        {isMobileMenuOpen && (
          <div className="fixed inset-0 bg-white z-40 pt-20">
            <div className="flex flex-col items-center space-y-6">
              <MenuItems />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
