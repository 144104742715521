import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setWs_AddebitiList, setWs_AddebitiMessage, setWs_ProdottiList, setWs_ProdottiMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getWs_Addebiti, getWs_Addebiti_User } from "services/ws_addebitiService";
import Layout from "template";
import { Constant } from "template/Constant";
import { Ws_AddebitiForm } from "./form";
import { Ws_AddebitiTable } from "./table";
import { isUserAuthenticate, parseJwtToken } from "services/tokenService";
import { getWs_Prodotti } from "services/ws_prodottiService";

export const Ws_Addebiti: React.FC = () => {
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.ws_addebiti);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState('');
  const rDataProdotto = useSelector((state: RootState) => state.ws_prodotti);

  const token = localStorage.getItem('token');
  const jwtToken = parseJwtToken(token);

  const getData = (page, pageSize, searchKey) => {
    if(isUserAuthenticate()){
      getWs_Addebiti_User(page, pageSize, searchKey, jwtToken.data.id).then((response) => {
        getDataProdotti(1, 1000, '');
        if (response && response.records) {
          dispatch(setWs_AddebitiList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
        } else {
          //dispatch(setWs_AddebitiMessage("Nessun dato presente in questa sezione"));
        }
      })
    }else{
      getWs_Addebiti(page, pageSize, searchKey).then((response) => {
        getDataProdotti(1, 1000, '');
        if (response && response.records) {
          dispatch(setWs_AddebitiList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
        } else {
          //dispatch(setWs_AddebitiMessage("Nessun dato presente in questa sezione"));
        }
      })
    } 
  }

  const getDataProdotti = async (page, pageSize, searchKey) =>{
    getWs_Prodotti(page, pageSize, searchKey).then((response) => {
      if (response && response.records) {
        dispatch(setWs_ProdottiList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
      } else {
        dispatch(setWs_ProdottiMessage("Nessun dato presente in questa sezione"));
      }
    })
  }

  const handleRowEdit = (rowData) => {
    setRow(rowData);
    dispatch(setWs_AddebitiMessage(''));
    setAction('edit');
  }
  return (
    <Layout>
      <div className="container-fluid  paddin-left-0">
        {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Addebiti</h1>
        </div> */}
        <div className="d-flex flex-column min-vh-100">
          {/* {rData.message ?
            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{rData.message}
              <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setWs_AddebitiMessage(''))}>
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert> : null} */}
          {action ? <Ws_AddebitiForm hideShowForm={setAction} action={action} row={row} getData={getData} /> :
            <Ws_AddebitiTable handleRowEdit={handleRowEdit} hideShowForm={setAction} getData={getData} getDataProdotti={getDataProdotti}/>}
        </div>

      </div>
    </Layout >
  );
};

