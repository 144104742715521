import { CardPagina } from "components/AbbonamentoCard/CardPagina";
import React, { useState } from "react";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setPiano_AbbonamentoList, setPiano_AbbonamentoMessage, resetPiano_AbbonamentoToInit } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getPiano_Abbonamento, getPiano_Abbonamento_Utente, getPiano_Abbonamento_Utente_Attivo, getPiano_Abbonamento_Utente_Disattivo } from "services/piano_abbonamentoService";
import { isUserAuthenticate } from "services/tokenService";
import Layout from "template";
import { Constant } from "template/Constant";
import { Piano_AbbonamentoForm } from "./form";
import { Piano_AbbonamentoTable } from "./table";

export const Piano_Abbonamento: React.FC = () => {
  const rData = useSelector((state: RootState) => state.piano_abbonamento);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState('');
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState('');
  
  const getData = (page, pageSize, searchKey) => {
//console.log("getdata")
    if(isUserAuthenticate()){
      //getPiano_Abbonamento_Utente_Attivo(page, pageSize, searchKey, 0).then((response) => {
      getPiano_Abbonamento_Utente(page, pageSize, searchKey, 0).then((response) => {
        if (response && response.records) {
          dispatch(setPiano_AbbonamentoList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
        } else {
          dispatch(setPiano_AbbonamentoMessage("Nessun dato presente in questa sezione"));
        }
      })
    }else{
      getPiano_Abbonamento(page, pageSize, searchKey).then((response) => {
        if (response && response.records) {
          dispatch(setPiano_AbbonamentoList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
        } else {
          dispatch(setPiano_AbbonamentoMessage("Nessun dato presente in questa sezione"));
        }
      })
    }
  }

  const getDataAbbonamentiDisattivi = (page, pageSize, searchKey) => {
    console.log("getdata")
    if(isUserAuthenticate()){
      getPiano_Abbonamento_Utente_Disattivo(page, pageSize, searchKey, 0).then((response) => {
        if (response && response.records) {
          dispatch(setPiano_AbbonamentoList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
        } else {
          dispatch(setPiano_AbbonamentoMessage("Nessun dato presente in questa sezione"));
        }
      })
    }else{
    getPiano_Abbonamento(page, pageSize, searchKey).then((response) => {
      if (response && response.records) {
        dispatch(setPiano_AbbonamentoList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
      } else {
        dispatch(setPiano_AbbonamentoMessage("Nessun dato presente in questa sezione"));
      }
    })
  }
}

  const handleReset = () => {
    setSearch('');
    dispatch(resetPiano_AbbonamentoToInit());
    getData(Constant.defaultPageNumber, rData.pageSize, '');
  }

  const columns_abbonamento = ["Numero Anagrafiche", "Anagrafiche Residue", "Numero Bilancio", "Bilanci Residui", "Numero Visure", "Visure Residue"];
  

  const handleRowEdit = (rowData) => {
    setRow(rowData);
    dispatch(setPiano_AbbonamentoMessage(''));
    setAction('edit');
  }

  const handleAbbonamentiAttivi = (lista) =>{
    let piani_attivi = [];

    lista.forEach(element => {
        
        if(element.attivo === 1){
            piani_attivi.push(element);
        }

    });
    return piani_attivi;
}

  return (
    <Layout>
      <div className="container-fluid  paddin-left-0">
      <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
                <div className="col-sm-10">
                    <h4>Crediti Disponibili</h4>
                </div>
                <div className="col-sm-2">
                    <Button variant="info" className="float-right" onClick={() => handleReset()}><i className="fa fa-refresh"></i> Aggiorna</Button>                 
                </div>
           </Row>
        {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Abbonamento</h1>
</div>
        <div>
        <h6 className="m-0 font-weight-bold text-primary">Aggiorna ({rData.totalCount})
          <Button variant="light" className="btn-circle btn-sm ml-2" onClick={handleReset}><i className="fa fa-refresh"></i></Button>
        </h6>
        </div> */}
{handleAbbonamentiAttivi(rData.list).map((item, i) => {
          return <CardPagina listaAbbonamento={item} />
        })}

        <div className="d-flex flex-column min-vh-100">
          {/* {rData.message ?
            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{rData.message}
              <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setPiano_AbbonamentoMessage(''))}>
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert> : null} */}
          {action ? <Piano_AbbonamentoForm hideShowForm={setAction} action={action} row={row} getData={getData} /> :
            <Piano_AbbonamentoTable handleRowEdit={handleRowEdit} hideShowForm={setAction} getData={getData}/>}
        </div>

      </div>
    </Layout >
  );
};

