
import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setElenco_Imprese_EstesoList, setElenco_Imprese_EstesoMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getElenco_Imprese_Esteso } from "services/elenco_imprese_estesoService";
import { getUltimoBilancio } from "services/richiesta_bilancio";
import Layout from "template";
import { Constant } from "template/Constant";
import { Elenco_Imprese_EstesoForm } from "./form";
import { Elenco_Imprese_EstesoTable } from "./table";
import LoadingSpinner from "../LoadingSpinner";
import { getVisuraCamerale } from "services/richiesta_visura";

export const Elenco_Imprese_Esteso: React.FC = () => {
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.elenco_imprese_esteso);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const getData = (page, pageSize, searchKey) => {
    getElenco_Imprese_Esteso(page, pageSize, searchKey).then((response) => {
      if (response && response.records) {
        dispatch(setElenco_Imprese_EstesoList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
      } else {
        dispatch(setElenco_Imprese_EstesoMessage("Nessun dato presente in questa sezione"));
      }
    })
  }

  const getDataBilancio = (cciaa, nrea, nomeanagrafica, idprodotto) => {
    setIsLoading(true);
    var datiricerca = {
      "cciaa": cciaa,
      "nrea": nrea,
      "nomeanagrafica": nomeanagrafica,
      "idprodotto": idprodotto
    }
    getUltimoBilancio(datiricerca).then((response) => {
      if (response != null && response.data.message === "OK") {
        //dispatch(setElenco_Imprese_EstesoList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
        dispatch(setElenco_Imprese_EstesoMessage("Bilancio Richiesto"));
        setIsLoading(false);
      } else {
        dispatch(setElenco_Imprese_EstesoMessage("Bilancio non richiedibile"));
        setIsLoading(false);
      }
    })
  }

  const getDatiVisura = (cciaa, nrea, nomeanagrafica, idprodotto, tipovisura) => {
    setIsLoading(true);
    var datiricerca = {
      "cciaa": cciaa,
      "nrea": nrea,
      "nomeanagrafica": nomeanagrafica,
      "idprodotto": idprodotto,
      "tipovisura": tipovisura
    }
    getVisuraCamerale(datiricerca).then((response) => {
      if (response != null && response.data.message === "OK") {
        //dispatch(setElenco_Imprese_EstesoList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
        dispatch(setElenco_Imprese_EstesoMessage("Visura Camerale Richiesta"));
        setIsLoading(false);
      } else {
        dispatch(setElenco_Imprese_EstesoMessage("Visura non richiedibile"));
        setIsLoading(false);
      }
    })
  }

  const handleRowEdit = (rowData) => {
    setRow(rowData);
    dispatch(setElenco_Imprese_EstesoMessage(''));
    setAction('edit');
  }

  return (
    <Layout>
      {isLoading ? <LoadingSpinner /> : isLoading}
      <div className="container-fluid  paddin-left-0">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Elenco Imprese</h1>
        </div>
        <div className="d-flex flex-column min-vh-100">
          {rData.message ?
            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{rData.message}
              <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setElenco_Imprese_EstesoMessage(''))}>
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert> : null}
          {action ? <Elenco_Imprese_EstesoForm hideShowForm={setAction} action={action} row={row} getData={getData} /> :
            <Elenco_Imprese_EstesoTable handleRowEdit={handleRowEdit} hideShowForm={setAction} getData={getData} getDataBilancio={getDataBilancio} getDatiVisura={getDatiVisura}/>}
        </div>

      </div>
    </Layout >
  );
};

